import React, { useState, useRef, useEffect } from 'react'
import './forgot.css';
import Header from "../Header/Header";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from "react-bootstrap/esm/Container";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom'
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import ClipLoader from "react-spinners/ClipLoader";


import consts from '../../Constant';
import Axios from '../../Axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import sound from '../../images/soundlogo.png'
import { NavItem } from 'react-bootstrap';

const Login = () => {

    const navigate = useNavigate()
    const location = useLocation();
    const email = useRef(null)
    const password = useRef(null)
    const confirmpassword = useRef(null)
    const params = useParams()
    const [emailerr, setemailerr] = useState(null)
    const [passerr, setpasserr] = useState(null)
    const [confirmpasserr, setconfirmpasserr] = useState(null)
    const [loader, setLoader] = useState(false)


    const [showPassword, setshowPassword] = useState(false)
    const [showconfirmPassword, setshowconfirmPassword] = useState(false)

    const urlParams = new URLSearchParams(window.location.search);
    // console.log(urlParams, "locaa")
    const lang = urlParams.get('token');
    // console.log(lang, "lanhssss")

    const handleClickShowPassword = () => setshowPassword((show) => !show);
    const handleClickShowconfirmPassword = () => setshowconfirmPassword((show) => !show);

    const [verifystatus, setverifystatus] = useState("empty")
    const validatePassword = (Password) => {
        // console.log(Password.length, 'pass length');
        if (Password.length < 8) {
            return false;
        }

        if (Password.length > 16) {
            return false;
        }

        if (!/[A-Z]/.test(Password)) {
            return false;
        }

        if (!/[a-z]/.test(Password)) {
            return false;
        }

        if (!/\d/.test(Password)) {
            return false;
        }

        if (!/[$@$!%*?&]/.test(Password)) {
            return false;
        }

        return true;
    };
    const handlePasswordChange = (event) => {

        const newPassword = event.target.value;
        password.current.value = newPassword;
        const isValidPassword = validatePassword(newPassword);
        // console.log(isValidPassword, "isValidPassword")
        if (newPassword === '') {
            setpasserr('');
        } else if (isValidPassword === false) {
            setpasserr('Password Must Contain At Least One Lowercase, Uppercase , Number, Special Character And Be At Least 8 Characters Long,Maximum 16 characters.');
        } else {
            setpasserr('');
        }

    }

    const checkVerify = () => {
        const data = location?.state?.status
        // console.log(data, "locaa")
        if (location?.state?.page === "email") {
            setverifystatus(data)
            setTimeout(() => {
                setverifystatus("empty")
            }, 4000);
        }
    }

    useEffect(() => {
        checkVerify()
        // console.log(params, "params")
    }, [])
    const onLogin = async () => {

        try {
            // console.log("hi");
            if (password.current.value === "") {
                setpasserr("Please Enter Password")
            }
            else if (passerr != '') {
                setpasserr(passerr)
            }
            else if (confirmpassword.current.value === "") {
                setconfirmpasserr("Please Enter Confirm Password")
            } else if (confirmpassword.current.value != password.current.value) {
                setconfirmpasserr("Confirm password and password must match")

            } else {
                setLoader(true)
                const { data } = await Axios.post(`/auth/reset`, {
                    id: lang,
                    password: password.current.value,

                }).then((response) => {
                    // console.log(response, "resq")
                    if (response?.data?.result?.success === true) {
                        setLoader(false)
                        toast.success(response?.data?.result?.message, {
                            position: "top-right",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });

                        setTimeout(() => {
                            navigate(`${consts.route}/`)
                        }, 2000)
                    }
                    else {
                        setLoader(false)
                        toast.error(response?.data?.result?.message, {
                            position: "top-right",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });

                        setTimeout(() => {
                            navigate(`${consts.route}/forgot`)
                        }, 2000)
                    }

                }).catch((error) => {
                    setLoader(false)
                    toast.error(error.response.data.message, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                });


                // if (data.success === true && data.result.user.f2A_Status === "false") {
                //     console.log(data, "yes")
                //     window.localStorage.setItem('Brouhaha', data.result.token)
                //     toast.success(data.message, {
                //         position: "top-right",
                //         autoClose: 3000,
                //         hideProgressBar: false,
                //         closeOnClick: true,
                //         pauseOnHover: false,
                //         draggable: true,
                //         progress: undefined,
                //         theme: "light",
                //     });

                //     setTimeout(() => {
                //         navigate(`${consts.route}/login`)
                //     }, 2000)
                // } else {
                //     window.localStorage.setItem('Brouhaha', data.result.token)
                //     toast.success(data.message, {
                //         position: "top-right",
                //         autoClose: 3000,
                //         hideProgressBar: false,
                //         closeOnClick: true,
                //         pauseOnHover: false,
                //         draggable: true,
                //         progress: undefined,
                //         theme: "light",
                //     });

                //     setTimeout(() => {
                //         navigate(`${consts.route}/two-factor-auth`)
                //     }, 2000)
                // }
            }
        } catch ({ response }) {
            // console.log(response, "err")
            if (response?.data?.message === 'Please Verify Email') {
                // console.log("if1")
                setLoader(false)
                setemailerr(response?.data?.message)
            } else if (response?.data?.message === "Invalid Email") {
                // console.log("if2")
                setLoader(false)
                setemailerr(response?.data?.message)
            } else if (response?.data?.message === 'Wrong Password') {
                // console.log("if3")
                setLoader(false)
                setpasserr(response?.data?.message)
            } else if (response?.data?.message === "Please Enter Valid Email") {
                setLoader(false)
                setemailerr(response?.data?.message)
            }
        }

    }

    return (
        <div className="login-page-main">
            {/* <Header/> */}
            <ToastContainer />
            <div className='login-section'>
                <Container>
                    <div className='login-bg reset-bg'>

                        <div className='login-inside reset-inner'>
                            <img src={sound} style={{ width: "200px", Height: '200px', margin: '15px 0px' }} />
                            <h1 className="login-heading-style">RESET PASSWORD</h1>

                            {/* <div className='emailsuccess' >
                <p className='emailtext' >Email verified successfully</p>
              </div> */}
                            {
                                verifystatus === "empty" ? <></> :
                                    <>
                                        {verifystatus === "Verified" ?
                                            <>
                                                <div className='emailsuccess' >
                                                    <p className='emailtext' >Email verified successfully</p>
                                                </div>
                                            </> :
                                            <>
                                                <div className='emailerror' >
                                                    <p className='emailtext' >link Expired</p>
                                                </div>
                                            </>}
                                    </>
                            }

                            <div>
                                {/* <TextField sx={{ m: 1, width: '56ch' }} id="outlined-basic" label="OTP" variant="outlined" inputRef={email} type="number" onChange={() => { setemailerr(null) }} /> */}
                                {/* {emailerr !== null ? <div style={{ textAlign: "center", color: "red" }} >{emailerr}</div> : <></>} */}
                                <FormControl sx={{ m: 1, width: '56ch' }} className='field-box'>
                                    {/* <InputLabel htmlFor="outlined-adornment-password">New Password</InputLabel> */}
                                    <OutlinedInput
                                        inputRef={password}
                                        // onChange={() => { setpasserr(null) 
                                        //     setconfirmpasserr(null) }}
                                        onChange={handlePasswordChange}
                                        placeholder='New Password'
                                        id="outlined-adornment-password"
                                        type={showPassword ? 'text' : 'password'}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton

                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    // onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        label="New Password"
                                    />

                                </FormControl>
                                {passerr !== null ? <div className='error-div'>{passerr}</div> : <></>}


                                <br />
                                <FormControl sx={{ m: 1, width: '56ch' }} className='field-box'>


                                    {/* <InputLabel htmlFor="outlined-adornment-password"> Confirm Password</InputLabel> */}
                                    <OutlinedInput
                                        inputRef={confirmpassword}
                                        placeholder='Confirm Password'
                                        onChange={() => { setconfirmpasserr(null) }}
                                        id="outlined-adornment-password"
                                        type={showconfirmPassword ? 'text' : 'password'}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton

                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowconfirmPassword}
                                                    // onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {showconfirmPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        label="Confirm Password"
                                    />

                                </FormControl>
                                {confirmpasserr !== null ? <div className='error-div'>{confirmpasserr}</div> : <></>}

                                {/* <Form.Group className="mb-3" controlId="formGroupEmail">
                  <Form.Control type="email" placeholder="Email" ref={email} onChange={() => { setemailerr(null) }} />
                </Form.Group>
                {emailerr !== null ? <div style={{ textAlign: "center", color: "red" }} >{emailerr}</div> : <></>} */}
                                {/* <Form.Group className="mb-3" controlId="formGroupPassword">
                  <Form.Control type="password" placeholder="Password" ref={password} onChange={() => { setpasserr(null) }} />
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </Form.Group> */}
                                {/* {passerr !== null ? <div style={{ textAlign: "center", color: "red" }}>{passerr}</div> : <></>} */}

                                {
                                    loader ? <Button type="submit"><ClipLoader color={'#00008B'} /></Button> :
                                        <Button type="submit" onClick={onLogin}>SUBMIT</Button>

                                }

                            </div>

                            {/* <div className='login-lose'>
                <Link className='green-text'>Lost login</Link>
                <Link className='green-text' to={`${consts.route}/forgot`}  >Forgot Password</Link>
                <Link className='green-text' to={`${consts.route}/register`}  >Join Now</Link>
              </div> */}
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
}

export default Login
