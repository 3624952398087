import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Header from "../Header/Header";
import TopHeader from "../Header/TopHeader";
import LeftSideBar from "../SideBar/LeftSideBar";
import RightSideBar from "../SideBar/RightSideBar";
import nosweat from "../../images/nosweat.png";
import { Link } from "react-router-dom";
import "../Lobby/lobby.css";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import sgp from "../../images/sgp.svg";
import rht from "../../images/rht-arrow.svg";
import empty from "../../images/empty.png";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import profile1 from "../../images/surname-profile1.png";
import profile2 from "../../images/surname-profile2.png";
import vs from "../../images/vs.png";
import { useLocation } from 'react-router-dom';
import Slider from '@mui/material/Slider'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import FormControl from '@mui/joy/FormControl';
import TextField from '@mui/material/TextField';
import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useNavigate } from "react-router-dom";
import Axios from "../../Axios";
import { ToastContainer, toast } from 'react-toastify';
import ClipLoader from "react-spinners/ClipLoader";
import FormLabel from "@mui/material/FormLabel";


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const names = [
    'Oliver Hansen',
    'Van Henry',
    'April Tucker',
    'Ralph Hubbard',
    'Omar Alexander',
    'Carlos Abbott',
    'Miriam Wagner',
    'Bradley Wilkerson',
    'Virginia Andrews',
    'Kelly Snyder',
];

function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    borderRadius: "10px",
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};




function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    const theme = useTheme();



    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const Lineup = ({ updateData }) => {
    const [value, setValue] = useState(0);
    const [isloader, setLoader] = useState(false)
    const [days, setDays] = useState(0);
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);


    const handleChange2 = (event, newValue) => {
        setValue(newValue);
    };
    const token = window.localStorage.getItem("iphephile");
    const navigate = useNavigate()
    // lobby table

    const [lobTable1, setLobTable1] = useState([
        {
            img1: `${profile1}`,
            midimg: `${vs}`,
            img2: `${profile2}`,
            name: "Guardians lander",
            sunname: "C.b Quantrill",
            num1: "+1.5",
            dnum1: "+1.5",
            num2: "5.00",
        },

        // { img1: `${profile1}`,midimg: `${vs}`, img2: `${profile2}`, name: 'Guardians lander', sunname: 'C.b Quantrill', num1: 'A', dnum1: 'B', num2: 'DJ' }
    ]);

    let loaction = useLocation()

    let dystate = loaction.state
    let values = dystate.value

    // popup
    const [open, setOpen] = React.useState(false)
    const [playerDetails, setPlayerDetails] = useState({})
    const [amount, setAmount] = useState()
    const [amountErr, setAmountErr] = useState(null)
    const [teamlength, setteamlength] = useState()

    const theme = useTheme();
    const [personName, setPersonName] = useState([]);

    const handleChange = (event) => {
        let obj = {
            name: ''
        }
        const {
            target: { value },
        } = event;
        setPersonName(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
        obj.name = value.toString();
        setteamlength(event.target.value.length)
        setPlayerDetails(obj)
    }

    const handleOpen = () => {
        setAmount(dystate.row.minimum_betting_amount)
        setOpen(true)
    }


    const handleClose = () => setOpen(false);


    const createBet = async () => {

        if (amount == '') {
            setAmountErr('please enter amount')
        }
        else if (amount < dystate.row.minimum_betting_amount) {
            setAmountErr(`mininum bet amount is ${dystate.row.minimum_betting_amount} `)
        }
        else if (teamlength != dystate.row.teamA.length) {
            toast.error('Select All Players')
        }
        else {
            // const fee = amount / 100
            // const adminFee = fee * 2
            // const adminFee = amount + 2
            const data = {
                eventid: dystate.row._id,
                title: dystate.row.title,
                betting_type: dystate.row.betting_type,
                amount: amount,
                betamount: amount,
                player_details: playerDetails
            }
            try {
                setLoader(true)
                const response = await Axios.post('/users/createbet', data, {
                    headers: {
                        "Authorization": token
                    }
                })
                if (response.data.success == true) {
                    setLoader(false)
                    setOpen(false)
                    updateData({ message: response.data.message, tabvalue: values })
                    toast.success(response.data.message)
                }
                else if (response.data.success == false) {
                    setLoader(false)
                    toast.error(response.data.message)
                }
            } catch (error) {
                setLoader(false)
                console.log(error, 'err');
            }
        }
    }

    const TimeDuration = async () => {
        const eventDate1 = dystate.row.event_duration
        const eventDate = eventDate1 * 1000

        const time = Date.parse(new Date(eventDate)) - Date.now();
        setDays(Math.floor(time / (1000 * 60 * 60 * 24)));
        setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
        setMinutes(Math.floor((time / 1000 / 60) % 60));
        setSeconds(Math.floor((time / 1000) % 60));
    }

    useEffect(() => {
        const interval = setInterval(() => TimeDuration(), 1000);

        return () => clearInterval(interval);
    });



    return (
        <>
            <ToastContainer />
            <div className="lobby-page bet1v1">
                {/* <Box className="lobby-page-innner" sx={{ flexGrow: 1 }}>
                <Grid container spacing={0}>
                    <Grid className='lobby-content' item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <TopHeader />
                        <Header />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={2} xl={2}>
                        <LeftSideBar />
                    </Grid>
                   
                </Grid>
            </Box> */}

                <Box sx={{ flexGrow: 1 }}>
                    {/* <Grid container spacing={0}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <TopHeader />
                        <Header />
                    </Grid>
                </Grid> */}

                    {/* <Grid item xs={12} sm={12} md={12} lg={2} xl={2} className='sidebarcontanier'>
                        <LeftSideBar />
                    </Grid> */}

                    {/* "overflow-x": "auto", */}
                    <Grid
                        container
                        spacing={0}
                        className="homebody-container"
                        style={{ "flex-warp": "nowrap", "scroll-snap-align": "x manatory" }}
                    >
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            style={{ "scroll-snap-align": "start" }}
                        >
                            <div className="no-sweat-first-bet bet1v1" >

                                <div className='back-btn' onClick={() => { navigate('/lobby', { state: { values } }) }}
                                // onClick={(() => { navigate(-1) })}
                                >
                                    <ArrowBackIcon />
                                </div>
                                <div className="table-lob">
                                    <TableContainer component={Paper}>
                                        <Table aria-label="simple table">
                                            <TableBody>

                                                <TableRow
                                                    sx={{
                                                        "&:last-child td, &:last-child th": { border: 0 },
                                                    }}
                                                    className="tb-body-row"
                                                >


                                                    <TableCell component="th" scope="row">
                                                        <div className="lineup-title">
                                                            Lineup
                                                        </div>
                                                    </TableCell>

                                                </TableRow>

                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>
                            </div>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            style={{ "scroll-snap-align": "start" }}
                        >
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table">
                                    <TableBody>

                                        <TableRow
                                            sx={{
                                                "&:last-child td, &:last-child th": { border: 0 },
                                            }}
                                        // className="tb-body-row"
                                        >
                                            <TableCell component="th" scope="row" colSpan={8}>
                                                <div className="tab-ava-main flex-img form-struct">
                                                    <FormControl className="formtxt">
                                                        <FormLabel><div className='preffered-title'>Title: <span>{dystate?.row?.title}</span></div></FormLabel>
                                                        {/* <TextField placeholder='Title' className='text-field' /> */}
                                                    </FormControl>
                                                    <FormControl>
                                                        {hours < 0 || minutes < 0 || seconds < 0 ? <div className="timer">Event Ended</div> : <FormLabel>Event End In: </FormLabel>}
                                                        <div className="timer">
                                                            {hours < 0 || minutes < 0 || seconds < 0 ? <></> : <>
                                                                {hours < 10 ? "0" + hours : hours}:{minutes < 10 ? "0" + minutes : minutes}:{seconds < 10 ? "0" + seconds : seconds}
                                                            </>}
                                                        </div>
                                                    </FormControl>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <div className="lineup-whole">
                                {/* <div className="lineup-div-main ">
                                <div>
                                    <Avatar alt="Remy Sharp" src={profile1} />
                                </div>

                                <div className='ava-name'>
                                        {row.name}
                                        <div className='ava-innername'>
                                            {row.nickname}
                                        </div>
                                    </div>

                            </div> */}
                                {dystate?.row?.teamA.map((row, ind) => (
                                    <div className="lineup-div-main ">
                                        <div>
                                            <Avatar alt="Remy Sharp" src={row?.img} />
                                        </div>
                                        <div className='ava-name'>
                                            {row?.name}
                                            <div className='ava-innername'>
                                                {row?.nickname}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                {/* <div className="lineup-div-main ">
                                <div>
                                    <Avatar alt="Remy Sharp" src={profile1} />
                                </div>
                                <div className='ava-name'>
                                    Guardians lander
                                    <div className='ava-innername'>
                                        C.b Quantrill
                                    </div>
                                </div>
                            </div>
                            <div className="lineup-div-main ">
                                <div>
                                    <Avatar alt="Remy Sharp" src={profile1} />
                                </div>
                                <div className='ava-name'>
                                    Guardians lander
                                    <div className='ava-innername'>
                                        C.b Quantrill
                                    </div>
                                </div>
                            </div> */}
                            </div>



                            <div className="lineup-btns-div">
                                <div className='btn-1-table lp'>
                                    <Button onClick={handleOpen}>Bet:$ {dystate.row.minimum_betting_amount}</Button>
                                </div>
                                <Modal open={open}
                                    onClose={handleClose}
                                    aria-labelledby="modal-modal-title"
                                    aria-describedby="modal-modal-description" className="bet1v1-popup">
                                    <Box sx={style}>
                                        <Button onClick={() => { handleClose() }} class="popup"><CloseIcon /></Button>
                                        <Typography id="modal-modal-title" variant="h6" component="h2">

                                            <div className='preffered-title'><span> {dystate?.row?.betting_type}</span></div>
                                            <div className='preffered-title'><span>Title: {dystate?.row?.title}</span></div>

                                        </Typography>
                                        <div className="tab-ava-main-body">
                                            <div className="tab-ava-main flex-img bet1v1" sx={{ mt: 2 }}>
                                                <div className="ava-name">
                                                    <div className="profile-pic">
                                                        {/* <Avatar
                                              className="profile1"
                                              alt="Remy Sharp"
                                              src={dystate.teamA[0].img}
                                            /> */}
                                                    </div>
                                                    {/* <div className="profile-info">
                                                        Sankar
                                                        <div className="ava-innername username1">
                                              {dystate.teamA[0].nickname}
                                            </div>
                                                    </div> */}
                                                </div>
                                                {/* <div className='vs'>
                                                    vs
                                                    <img alt="Remy Sharp" src={vs} />
                                                </div> */}
                                                <div className="ava-name">
                                                    <div className="profile-pic">
                                                        {/* <Avatar
                                              className="profile2"
                                              alt="Remy Sharp"
                                              src={dystate.teamA[1].img}
                                            /> */}
                                                    </div>
                                                    {/* <div className="profile-info">
                                                        kishore
                                                        <div className="ava-innername username2">
                                              {dystate.teamA[1].nickname}
                                            </div>
                                                    </div> */}
                                                </div>
                                            </div>
                                            {/* <FormControl className="formtxt">
                                            <TextField placeholder='Name' className='text-field'
                                                value={personName}
                                            // onChange={(e) => { setName(e.target.value); setNameErr(null) }}
                                            />
                                        </FormControl> */}
                                            <div>
                                                <FormControl className="formselect" sx={{ m: 1, width: 'auto', mt: 3 }}>
                                                    <Select
                                                        multiple
                                                        displayEmpty
                                                        value={personName}
                                                        onChange={handleChange}
                                                        input={<OutlinedInput />}
                                                        renderValue={(selected) => {
                                                            if (selected.length === 0) {
                                                                return <em>Select Player</em>;
                                                            }

                                                            return selected.join(', ');
                                                        }}
                                                        MenuProps={MenuProps}
                                                        inputProps={{ 'aria-label': 'Without label' }}
                                                    >

                                                        {dystate?.row?.teamA.map((name) => (
                                                            <MenuItem
                                                                key={name.name}
                                                                value={name.name}
                                                                style={getStyles(name, personName, theme)}
                                                            >
                                                                {name.name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                            <div className="error-code">
                                                {/* {nameErr && <div className="error-message" style={{ color: 'red', fontSize: "12px" }}>{nameErr}</div>} */}
                                            </div>
                                            <FormControl className="formtxt">
                                                <TextField placeholder='Amount' className='text-field'
                                                    value={amount}
                                                // onKeyPress={(event) => {
                                                //     if (!/[0-9]/.test(event.key)) {
                                                //         event.preventDefault();
                                                //         setAmountErr("Enter Valid Number")
                                                //     }
                                                // }}
                                                // onChange={(e) => {
                                                //     setAmount(e.target.value);
                                                //     setAmountErr(null)
                                                // }}
                                                />
                                            </FormControl>
                                            {/* <div className="error-code">
                                                {amountErr && <div className="error-message" style={{ color: 'red', fontSize: "12px" }}>{amountErr}</div>}
                                            </div> */}
                                            {/* {dystate?.row?.teamA.length > 0 && <>
                                            <FormControl className="formtxt">
                                                <div>Selected Players</div>
                                                <ol>
                                                    {dystate?.row?.teamA.map((row) => {
                                                        return (
                                                            <>
                                                                <li>{row.name}</li>
                                                            </>
                                                        )
                                                    })}
                                                </ol>
                                            </FormControl>
                                            {dystate?.row?.teamA.length > 0 && <div className="reset" onClick={(() => { setPersonName([]) })}>
                                                Reset
                                            </div>}
                                        </>} */}


                                            {/* <FormControl className="formtxt">
                                          <TextField placeholder='Admin Fee' className='text-field' label='Admin Fee' value={'2'} />
                                        </FormControl> */}

                                            <div className='display-flex'>Admin Fee: {dystate?.row?.admin_fee}%</div>
                                            <div className="btn-1-table">
                                                {
                                                    isloader ? <ClipLoader color={'#00008B'} /> :
                                                        <Button
                                                            // onClick={() => { navigate('<RightSideBar />', { state: lobTable1 }) }}
                                                            onClick={() => { createBet(); }}

                                                        >Submit</Button>
                                                }
                                            </div>
                                        </div>



                                    </Box>
                                </Modal>
                            </div>

                        </Grid>
                    </Grid>
                </Box>
            </div >
        </>
    );
};

export default Lineup;
