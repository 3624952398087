import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import winbet from '../../images/win-bet.png'
import Axios from "../../Axios";
import './SideBar.css'
import consts from '../../Constant';

const useStyles = makeStyles({
    root: {
        background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
        border: 0,
        borderRadius: 3,
        boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
        color: 'white',
        height: 48,
        padding: '0 30px',
    },
    flexcls: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center'
    }
});



const LeftSideBar = () => {
    const [images, setImages] = useState([]);


    const getImages = async () => {

        let payload = { type: '' }
        try {
            const images = await Axios.post('/auth/getImages', payload)

            if (images?.data?.success == true) {
                setImages(images?.data?.result)
            }
            else {
                setImages(images?.data?.result)
            }
        } catch (error) {
            console.log(error, 'image err');
        }
    }

    useEffect(() => {
        getImages()
    }, [])

    // console.log(images, 'images');

    return (
        <div className='sidebar left-sidebar'>

            <Box sx={{ flexGrow: 1 }}>
                <Grid spacing={0}>
                    {
                        images.length > 0 ?
                            <>
                                {images && images.map((data, ind) => (
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className='full-list'>
                                        {/* <ul>
                                            <li><img src={winbet} alt="logo" /></li>
                                            <li><img src={winbet} alt="logo" /></li>
                                            <li><img src={winbet} alt="logo" /></li>
                                        </ul> */}

                                        <ul className='layoutleft-ul'>
                                            <li><img src={data?.image ? data?.image : winbet} alt="logo" /></li>
                                        </ul>
                                    </Grid>

                                ))}
                            </> :
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className='full-list'>
                                <ul className='layoutleft-ul'>
                                    <li><img src={winbet} alt="logo" /></li>
                                </ul>
                            </Grid>
                    }
                </Grid>

            </Box>

        </div>
    )
}

export default LeftSideBar
