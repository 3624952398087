import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Landing from './Components/Landing/Landing';
import { useEffect, useState } from 'react';
import React from 'react';
import Header from './Components/Header/Header';
import TopHeader from './Components/Header/TopHeader';
import './Restyle.css'
import './media.css'
import Lobby from './Components/Lobby/lobby';
import Mybets from './Components/Mybets/Mybets';
import Layout from './Components/Layout';
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom/dist';
import Research from './Components/Research/Research';
import News from './Components/News/News';
import Event from './Components/Event/Event';
import Deposit from './Components/Deposit/Deposit';
import Withdraw from './Components/Deposit/Withdraw';
import TwoFactor from './Components/2FA/TwoFactor'
import TwoFac from './Components/Deposit/TwoFac';
import EmailVerify from './Components/Header/EmailVerify'
import PrivateRoute from './PrivateRoute';
import PrivateAuth from './PrivateAuth';
import Coming from "./Components/ComingSoon/Coming"

import EditProfile from './Components/EditProfile/EditProfile'
import Loading from './Components/Deposit/Loading'
import ResetPassword from './Components/Header/ResetPassword'
import Otpresend from './Components/Otpresend/Otpresend'
import TwoFactorAuth from './Components/2FA/TwoFactorAuth'
import Bet1v1 from './Components/Lobby/Bet1v1';
import TV from './Components/Tv/Tv';
import Vote from './Components/Lobby/Vote';
import Lineup from './Components/Lobby/Lineup';
import RightSideBar from './Components/SideBar/RightSideBar';
import History from './Components/MyOffer/History';
import Ticket from './Components/Ticket/Ticket';
import Survey from './Components/Survey/Survey';
import MySurvey from './Components/Survey/userSurvey';


function App() {


  // const location = useLocation();

  const [add, setAdd] = useState('')
  const [data, setData] = useState([]);

  const updateData = (newData) => {
    setData(newData);
  };



  function LocationDisplay() {
    const location = useLocation();

    return setAdd(location.pathname);
  }

  const routes = (
    <Routes>
      <Route path='/' element={<Landing />} />
      <Route path='/emailverify/:token' element={<EmailVerify />} />
      <Route path='/head' element={<Header />} />
      <Route path='/tophead' element={<TopHeader />} />
      <Route path='/lobby' element={<Lobby updateData={updateData} />} />
      <Route path='/bet1v1' element={<Bet1v1 updateData={updateData} />} />

      <Route element={<PrivateRoute />}>
        <Route path='/mybets' element={<Mybets />} />
        <Route path='/deposit' element={<Deposit />} />
        <Route path='/withdraw' element={<Withdraw />} />
        {/* <Route path='/history' element={<Withdraw />} /> */}
      </Route>

      {/* <Route path='/twofactor' element={<TwoFactor />} /> */}
      <Route path='/research' element={<Coming />} />
      <Route path='/news' element={<Coming />} />
      <Route path='/event' element={<Event />} />
      {/* <Route path='/deposit' element={<Deposit />} /> */}
      {/* <Route path='/withdraw' element={<Withdraw />} /> */}
      {/* <Route path='/twofactor' element={<TwoFactor />} /> */}
      <Route path='/twofac' element={<TwoFac />} />
      <Route path='/editprofile' element={<EditProfile />} />
      <Route path='/payment' element={<Loading />} />
      <Route path='/reset' element={<ResetPassword />} />
      <Route path='/emailotp' element={<Otpresend />} />
      <Route path='/two-factor-auth' element={<TwoFactorAuth />} />
      <Route path='/tv' element={<TV />} />
      <Route path='/vote' element={<Vote updateData={updateData} />} />
      <Route path='/lineup' element={<Lineup updateData={updateData} />} />
      <Route path='/sidebar' element={<RightSideBar />} />
      <Route path='/history' element={<History />} />
      <Route path='/coming' element={<Coming />} />
      <Route path='/ticket' element={<Ticket />} />
      <Route path='/survey' element={<Survey />} />
      <Route path='/mySurvey' element={<MySurvey />} />


    </Routes>
  )

  const result = (['/', '/research', '/news', '/event', '/deposit', '/withdraw', '/twofac', '/editprofile', '/payment', '/reset', '/emailotp', '/two-factor-auth', '/tv', '/coming', '/ticket'])

  return (
    <div className="App">
      {/* <Router> */}
      <LocationDisplay />
      {result.includes(add) ? routes : <Layout data={data}>
        {routes}
      </Layout>}

      {/* </Router> */}
    </div>
  );
}



export default App;
