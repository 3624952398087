import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Header from '../Header/Header'
import TopHeader from '../Header/TopHeader'
import LeftSideBar from '../SideBar/LeftSideBar';
import RightSideBar from '../SideBar/RightSideBar';
import nosweat from '../../images/nosweat.png'
import { Link } from 'react-router-dom'
import '../Lobby/lobby.css'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import sgp from '../../images/sgp.svg';
import rht from '../../images/rht-arrow.svg';
import empty from '../../images/empty.png'
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import './Mybets.css'
import nobets from "../../images/nobets.png"
import { useLocation } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import Axios from '../../Axios';
import consts from '../../Constant';
import CircularProgress from '@mui/material/CircularProgress';
import { Token } from '@mui/icons-material';

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}



function Mybets(props) {

    const [value, setValue] = useState(0);
    const [data, setData] = useState([])
    const token = window.localStorage.getItem('iphephile')

    const getUserBets = async () => {
        try {
            const response = await Axios.post('/users/getuserbet', { type: "all" }, {
                headers: {
                    Authorization: window.localStorage.getItem('iphephile')
                }
            })
            // console.log(response, 'getUserBetsall');
            if (response.data.success == true) {
                setLoader(false)
                setData(response.data.result)
            }
            else {
                setLoader(true)
            }
        } catch (error) {

        }
    }

    const [loader, setLoader] = useState(true)


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const offer = useLocation()

    const navigate = useNavigate()

    // console.log(offer.state, 'offer');

    useEffect(() => {
        if (token != null) {
            getUserBets()
        }
    }, [token])


    // hanldecolor

    const handleColor = (val) => {
        if (val === 'Loss') {
            return 'Loss'
        } else if (val === 'Win') {
            return 'Win'
        } else if (val === 'Revoked') {
            return 'Revoked'
        } else {
            return 'Pending'
        }
    }
    return (
        <div className='my-bets'>
            <Box sx={{ flexGrow: 1 }}>

                <Grid container spacing={0} className='homebody-container' style={{ "overflow-x": "auto", "flex-warp": "nowrap", "scroll-snap-align": "x manatory" }}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ "scroll-snap-align": "start" }}>
                        <div className='no-sweat-first-bet'>

                            <Grid container spacing={0}>
                                <div className='my-bets-title'>
                                    <ArrowBackIcon className='cursor' onClick={() => { navigate(-1) }} />   My Bets
                                </div>
                                <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Box sx={{ width: '100%' }}>
                                        <Box sx={{ borderColor: 'divider' }}>
                                            {/* <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" className='mybet-tab'>
                                                <Tab label="Active" {...a11yProps(0)} />
                                                <Tab label="Settled" {...a11yProps(1)} />

                                            </Tabs> */}
                                        </Box>
                                        <CustomTabPanel value={value} index={0} className='custum-tab'>

                                            <TableContainer >
                                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>Sno</TableCell>
                                                            <TableCell align="center">Title</TableCell>
                                                            <TableCell align="center">Name</TableCell>
                                                            <TableCell align="center">Bet Amount</TableCell>
                                                            <TableCell align="center">Result</TableCell>
                                                            {/* <TableCell align="right">Protein&nbsp;(g)</TableCell> */}
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {data.length > 0 ? loader ?
                                                            <TableRow>
                                                                <TableCell colSpan={4}>
                                                                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                                                        <CircularProgress />
                                                                    </Box>
                                                                </TableCell>
                                                            </TableRow> :
                                                            <>
                                                                {data.map((row, ind) => {
                                                                    return (
                                                                        <>
                                                                            <TableRow
                                                                                key={row.name}
                                                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                            >
                                                                                <TableCell component="th" scope="row">
                                                                                    {ind + 1
                                                                                    }
                                                                                </TableCell>
                                                                                <TableCell align="center">{row.title}</TableCell>
                                                                                <TableCell align="center">{row?.player_details?.name}</TableCell>
                                                                                <TableCell align="center">$ {row?.betamount}</TableCell>
                                                                                <TableCell align="center"><div className={handleColor(row?.result)}>{row?.result}</div></TableCell>
                                                                            </TableRow>

                                                                        </>
                                                                    )
                                                                })}
                                                            </> :
                                                            <TableRow>
                                                                <TableCell colSpan={5}>
                                                                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                                                        <div className='my-bets-join'>
                                                                            <div> <img alt="Remy Sharp" src={nobets} /></div>
                                                                            <h6>No  bets found</h6>


                                                                        </div>
                                                                    </Box>
                                                                </TableCell>
                                                            </TableRow>
                                                        }

                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                            {/* <div> <img alt="Remy Sharp" src={nobets} /></div>
                                                <h6>No active bets</h6>
                                                <p>You must be logged in to see bets</p>
                                                <Button>
                                                    Log in or join now
                                                </Button> */}

                                        </CustomTabPanel>
                                        <CustomTabPanel value={value} index={1}>
                                            <div className='my-bets-join'>
                                                <div> <img alt="Remy Sharp" src={nobets} /></div>
                                                <h6>No settled bets</h6>
                                                <p>You must be logged in to see bets</p>
                                                <Button>
                                                    Log in or join now
                                                </Button>
                                            </div>
                                        </CustomTabPanel>

                                    </Box>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>


                </Grid>

            </Box>
        </div>
    )
}

export default Mybets
