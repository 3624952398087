import React, { useState, useRef, useEffect } from "react";
import './EditProfile.css'
import { Grid } from "@mui/material";
import TopHeader from "../Header/TopHeader";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import banner from "../../images/editprofile-bg.png"
import avat from "../../images/Image Placeholder.png"
import Avatar from '@mui/material/Avatar';
import { Button } from "@mui/material";
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import FilledInput from '@mui/material/FilledInput';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Axios from "../../Axios";
import consts from '../../Constant';
import { ToastContainer, toast } from "react-toastify";
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom'
import ClipLoader from "react-spinners/ClipLoader";

// shalomy

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});




function EditProfile() {

  const [imageUrl, setImageUrl] = useState(null);
  const [profilepic, setProfilePic] = useState({})
  const [user, setUser] = useState({})
  const [firstNameError, setFirstNameError] = useState('');
  const [phoneer, setPhoneer] = useState('')
  const [countryError, setCountryError] = useState('');
  const [confirmpasswordError, setConfirmpasswordError] = useState()
  const [addressError, setAddressError] = useState('');
  const [cityError, setCityError] = useState('');

  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [isload, setLoad] = useState()

  const navigate = useNavigate();

  const token = window.localStorage.getItem('iphephile')

  const handlePhoneChange = (e) => {
    const reg = /^\d{6,17}$/;

    const phoneNumber = e.target.value;
    // console.log(phoneNumber, "number")
    setUser({ ...user, phone: phoneNumber });
    if (phoneNumber.trim() === '') {
      setPhoneer('');
      //setUser({...user, phone: ''})
    } else if (!reg.test(phoneNumber)) {
      setPhoneer('*Phone Number should be 6 to 17 digits');
      //setUser({...user, phone: ''})
    } else {
      setPhoneer('');
      setUser({ ...user, phone: phoneNumber })


    }
    // console.log(user.phone, "number")
  };






  const handleImageUpload = async (event) => {
    const file = event.target.files[0];

    try {
      const usertoken = window.localStorage.getItem("iphephile");


      let formData = await new FormData();
      formData.append("image", file)
      const fileName = file.name
      var idxDot = fileName.lastIndexOf(".") + 1;
      var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
      if (extFile == "jpg" || extFile == "jpeg" || extFile == "png" || extFile == "webp") {
        const { data } = await Axios.post('/auth/updateDp', formData)
        if (data?.result) {
          // setLogo(data.result)
          // console.log(data, "sdsdas")

          const updateData = {
            // name: profiledetail?.result?.name,
            // phone: profiledetail?.result?.phone,
            profilePicture: data.result
          }
          // console.log(updateData, "cehcks")
          await Axios.post('/users/UpdateUser', updateData, {
            headers: {
              Authorization: `Bearer ${usertoken}`,
            }
          }).then((res) => {
            // console.log(res, "res")
            if (res.data.success) {
              toast.success('Profilepicture uploaded Successfully')
              // setisChange(true)
              getUser()
            }
          })
        }

      } else {
        toast.error("Upload Image in jpg,jpeg,png,webp Format")

      }


    } catch (error) {
      console.log(error, "err")
    }
  }

  const [useredit, setUserEdit] = useState({
    user_name: '',
    country: '',
    phone: '',
    address: '',
    city: '',
    country: '',
    oldpassword: '',
    newpassword: '',
    confirmpassword: '',
  })


  // const reader = new FileReader();

  // console.log(reader, "result of file");

  // reader.onloadend = () => {
  //   setImageUrl(reader.result);
  // };

  // if (file) {
  //   reader.readAsDataURL(file);
  // }


  const [imagebanner, setImageBanner] = useState(null);

  const handleImageUploadBanner = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    // console.log(reader, "result of file");

    reader.onloadend = () => {
      setImageBanner(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const style = {
    width: '100%',
    height: '400px',
    marginTop: '50px',
    background: `url(${imagebanner ? imagebanner : banner})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    // backgroundRepeat: 'no-repeat !important'

  }


  const handleUpdateProfile = async () => {
    // console.log("check")
    const reg = /^\d{6,17}$/;

    try {
      if (!user.user_name.trim()) {
        setFirstNameError('*User Name is required');
        return;
      } else {
        setFirstNameError('');
      }



      if (user.phone.trim() === '') {
        setPhoneer('*Phone Number is required');
        return;
      } else if (!reg.test(user.phone)) {
        setPhoneer('*Phone Number should be 6 to 17 digits');
        return;
      }
      else {
        setPhoneer('');
      }
      if (!user.address.trim()) {
        setAddressError('*address is required');
        element.scrollIntoView({ behavior: "smooth", block: "start" });

        return;
      } else {
        setAddressError('');
      }
      if (!user.city.trim()) {
        setCityError('*city is required');
        element.scrollIntoView({ behavior: "smooth", block: "start" });
        return;
      } else {
        setCityError('');
      }

      if (!user.country.trim()) {
        setCountryError('*Country is required');
        element.scrollIntoView({ behavior: "smooth", block: "start" });
        return;
      } else {
        setCountryError('');
      }

      if (useredit.oldpassword && passworderr != '') {
        setpassworderr("*Password must be a minimum 8 characters & Maximum 16 characters.Eg: Abc@1234")

        return;
      }

      if (useredit.oldpassword && useredit.newpassword.trim() === '') {
        setNewPassErr('Enter Change password')
        element.scrollIntoView({ behavior: "smooth", block: "start" });
        return;
      }
      if (useredit.newpassword && newPassErr != '') {
        setNewPassErr("*Password must be a minimum 8 characters & Maximum 16 characters.Eg: Abc@1234")
        return;
      }
      else {
        setNewPassErr('')
      }

      if (useredit.newpassword && useredit.confirmpassword.trim() === '') {
        setConfirmpasswordError('Enter Confirm password')
        element.scrollIntoView({ behavior: "smooth", block: "start" });
        return;
      }
      // if (useredit.confirmpassword && confirmpasswordError == '') {
      //   setConfirmpasswordError("*Password must be a minimum 8 characters & Maximum 16 characters.Eg: Abc@1234")
      //   return
      // }
      // else {
      //   setConfirmpasswordError('')
      // }

      if (useredit.newpassword && useredit.confirmpassword && useredit.newpassword != useredit.confirmpassword) {
        // console.log('conifrmpasword');
        setConfirmpasswordError('Confirm password and Change password must match')
        return;
      }
      else {
        setConfirmpasswordError('')
      }


      const usertoken = window.localStorage.getItem("iphephile");
      setLoad(true)
      let payload = {
        user_name: user.user_name,
        // lastname: user.lastname,
        // dob: user.dob,
        phone: user.phone,
        address: user.address,
        city: user.city,
        country: user.country,
        oldpassword: useredit.oldpassword,
        newpassword: useredit.newpassword,
        // profilePicture:profilepic.profilePicture
      }
      // console.log(payload, "payload")
      const response = await Axios.post('/users/UpdateUser', payload, {
        headers: {
          Authorization: `Bearer ${usertoken}`,
        }
      });
      // console.log(response, "response")
      if (response?.data?.success == false && response?.data?.message == 'Admin Blocked You') {
        setLoad(false)
        toast.error(response?.data?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "light",
        })
        window.localStorage.removeItem("iphephile")
        window.localStorage.removeItem("iphephileid")
        setTimeout(() => {
          navigate(`${consts.route}/`)
        }, 2000);
      }
      else if (response?.data?.message === 'Incorrect old password') {
        setLoad(false)
        // console.log("hai");
        toast.error('Incorrect old password')
        //     // setTimeout(() => {
        //     //     setSubadminResetPwd({
        //     //         oldpassword: '',
        //     //         newpassword: '',
        //     //         confirmpassword: ''
        //     //     })
        //     //   }, 3000); 

      } else {
        // console.log("bye")
        setLoad(false)
        toast.success('Profile Updated Successfully');
        setTimeout(() => {
          setUserEdit({
            oldpassword: '',
            newpassword: '',
            confirmpassword: ''
          })
        }, 3000);
      }
    } catch (error) {
      console.error('Error updating password:', error);
    }
  };
  var element = document.getElementById("boxing");



  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const [showPassword2, setShowPassword2] = React.useState(false);

  const handleClickShowPassword2 = () => setShowPassword2((show) => !show);

  const [showPassword3, setShowPassword3] = React.useState(false);

  const handleClickShowPassword3 = () => setShowPassword3((show) => !show);

  const [passworderr, setpassworderr] = useState(null)
  const [newPassErr, setNewPassErr] = useState(null)


  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const getUser = () => {
    // console.log("User")
    Axios.get(`${consts.BackendUrl}/users/getUserbyId`, {
      headers: {
        Authorization: window.localStorage.getItem('iphephile')
      },
    })
      .then((res) => {
        // console.log(res, "resposnepro")
        setUser(res?.data?.result)
        setProfilePic(res?.data?.result?.profilePicture)


        // console.log(res, "oo")

      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    getUser()

  }, [token])

  function getPassword(e) {
    const passwordregex = new RegExp(
      /(^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,16}$)+/,
      "gm"
    );
    const inputValue = e.target.value;

    if (inputValue.trim() === "") {
      setpassworderr("")
    } else if (!(passwordregex.test(inputValue))) {
      setpassworderr("*Password must be a minimum 8 characters & Maximum 16 characters.Eg: Abc@1234")
    } else {
      setpassworderr("")
    }
  }

  function getChangePassword(e) {
    const passwordregex = new RegExp(
      /(^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,16}$)+/,
      "gm"
    );
    const inputValue = e.target.value;

    if (inputValue.trim() === "") {
      setNewPassErr('')
    } else if (!(passwordregex.test(inputValue))) {
      setNewPassErr("*Password must be a minimum 8 characters & Maximum 16 characters.Eg: Abc@1234")
    } else {
      setNewPassErr('')

    }
  }



  return (


    <div className="edit-profile">
      <ToastContainer />

      <Grid container spacing={0} className="depo-cont">
        <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
          <TopHeader />
        </Grid>

        <Grid xs={12} sm={12} md={12} lg={12} xl={12} style={style} className="banner-grid">
          <div className="update-banner-txt">
            <h3>Update Profile</h3>
            <div className="upload-avat banner">

            </div>
          </div>
        </Grid>
        <Grid container spacing={0} className="relati-container">
          <Grid xs={11} sm={10} md={10} lg={10} xl={9}>
            <Grid container spacing={0} className="form-container-profile" sx={{ justifyContent: 'center' }}>
              <Grid xs={12} sm={12} md={5} lg={4} xl={4} className="avatar-uploader">
                <div className="prf-txt">
                  profile
                </div>
                <div className="prf-avat-div">
                  <Avatar
                    alt="Remy Sharp"
                    src={profilepic ? profilepic : avat}
                    sx={{ width: 180, height: 180 }}
                    className="prf-avatar"
                  />
                </div>

                <div className="upload-avat">
                  <Button
                    component="label"
                    role={undefined}
                    tabIndex={-1}
                  >
                    Upload new Image
                    <VisuallyHiddenInput type="file" onChange={handleImageUpload} />
                  </Button>
                </div>
              </Grid>
              <Grid xs={12} sm={12} md={7} lg={8} xl={8}>
                <div className="right-grid-main">
                  <div className="basic-inf">
                    BASIC INFO
                  </div>
                  <div className="grid-right-btns">
                    <Button className="btn1" onClick={(() => { navigate('/lobby') })}>
                      CANCEL
                    </Button>
                    {
                      isload ?
                        <Button className="btn2">
                          <ClipLoader color={'#00008B'} />
                        </Button> :
                        <Button className="btn2" onClick={() => handleUpdateProfile()}>
                          Update
                        </Button>
                    }

                  </div>
                </div>
                <div>
                  <hr className="gradientHr" />
                </div>
                <div className="right-form-main" id="boxing">
                  <Grid xs={12} sm={12} md={12} lg={12} xl={10} style={{ margin: 'auto' }}>
                    <Grid container spacing={0} sx={{ justifyContent: 'var(--justi-l)' }}>
                      <Grid xs={12} sm={12} md={12} lg={4} xl={6}>
                        <div className="prf-right-inp">
                          <div className="prf-right-name">
                            Name
                          </div>
                          <TextField
                            id="outlined-basic"
                            variant="outlined"
                            value={user ? user?.user_name : ''}
                            onChange={(e) => { setUser({ ...user, user_name: e.target.value }); setFirstNameError('') }} />

                        </div>
                        {firstNameError !== "" ?
                          <div className='error-div' >
                            {firstNameError}
                          </div> :
                          <></>
                        }
                      </Grid>


                      <Grid xs={12} sm={12} md={12} lg={4} xl={6}>
                        <div className="prf-right-inp">
                          <div className="prf-right-name">
                            Phone
                          </div>
                          <TextField
                            id="outlined-basic"
                            variant="outlined"
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            type="number"
                            value={user ? user?.phone : ''}
                            onChange={handlePhoneChange} />
                        </div>
                        {phoneer !== "" ?
                          <div className='error-div' >
                            {phoneer}
                          </div> :
                          <></>
                        }
                      </Grid>


                      <Grid xs={12} sm={12} md={12} lg={10} xl={12}>
                        <div className="prf-right-inp">
                          <div className="prf-right-name">
                            Email
                          </div>
                          <TextField
                            id="outlined-basic"
                            variant="outlined"
                            value={user ? user?.email : ''} />
                        </div>
                      </Grid>

                      <Grid xs={12} sm={12} md={12} lg={10} xl={12}>
                        <div className="prf-right-inp">
                          <div className="prf-right-name">
                            Address
                          </div>
                          <TextField
                            id="outlined-basic"
                            variant="outlined"
                            value={user ? user?.address : ''}
                            onChange={(e) => { setUser({ ...user, address: e.target.value }); setAddressError(''); }} />
                        </div>
                        {addressError !== "" ?
                          <div className='error-div' >
                            {addressError}
                          </div> :
                          <></>
                        }
                      </Grid>


                      <Grid xs={12} sm={12} md={12} lg={4} xl={8}>
                        <div className="prf-right-inp">
                          <div className="prf-right-name">
                            City
                          </div>
                          <TextField
                            id="outlined-basic"
                            variant="outlined"
                            value={user ? user?.city : ''}
                            onChange={(e) => { setUser({ ...user, city: e.target.value }); setCityError(''); }} />
                        </div>
                        {cityError !== "" ?
                          <div className='error-div' >
                            {cityError}
                          </div> :
                          <></>
                        }
                      </Grid>


                      <Grid xs={12} sm={12} md={12} lg={4} xl={4}>
                        <div className="prf-right-inp">
                          <div className="prf-right-name">
                            Country
                          </div>
                          <TextField
                            id="outlined-basic"
                            variant="outlined"
                            value={user ? user?.country : ''}
                            onChange={(e) => { setUser({ ...user, country: e.target.value }); setCountryError(''); }} />
                        </div>
                        {countryError !== "" ?
                          <div className='error-div'>
                            {countryError}
                          </div> :
                          <></>
                        }
                      </Grid>


                      <Grid xs={12} sm={12} md={12} lg={12} xl={4} >
                        <div className="prf-right-inp">
                          <div className="prf-right-name">
                            Current Password
                          </div>
                          <FormControl sx={{ width: 'auto' }} variant="outlined">

                            <OutlinedInput
                              id="outlined-adornment-password"
                              type={showPassword ? 'text' : 'password'}
                              name={password}
                              // autoComplete='off'
                              autoComplete='new-password'
                              value={useredit.oldpassword}
                              onChange={(e) => { setUserEdit({ ...useredit, oldpassword: e.target.value }); getPassword(e) }}
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                  >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                  </IconButton>
                                </InputAdornment>
                              }

                            />
                          </FormControl>
                        </div>
                        {passworderr !== null ? <div className='error-div'>{passworderr}</div> : <></>}
                      </Grid>

                      <Grid xs={12} sm={12} md={12} lg={12} xl={4} >
                        <div className="prf-right-inp">
                          <div className="prf-right-name">
                            Change Password
                          </div>
                          <FormControl sx={{ width: 'auto' }} variant="outlined">

                            <OutlinedInput
                              id="outlined-adornment-password"
                              type={showPassword2 ? 'text' : 'password'}
                              name={password}
                              // autoComplete='off'
                              autoComplete='new-password'
                              value={useredit.newpassword}
                              onChange={(e) => { setUserEdit({ ...useredit, newpassword: e.target.value }); getChangePassword(e) }}
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword2}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                  >
                                    {showPassword2 ? <VisibilityOff /> : <Visibility />}
                                  </IconButton>
                                </InputAdornment>
                              }

                            />
                          </FormControl>
                        </div>
                        {newPassErr !== null ? <div className='error-div'>{newPassErr}</div> : <></>}
                      </Grid>

                      <Grid xs={12} sm={12} md={12} lg={12} xl={4} >
                        <div className="prf-right-inp">
                          <div className="prf-right-name">
                            Confirm Password
                          </div>
                          <FormControl sx={{ width: 'auto' }} variant="outlined">

                            <OutlinedInput
                              id="outlined-adornment-password"
                              type={showPassword3 ? 'text' : 'password'}
                              name={confirmPassword}
                              // autoComplete='off'
                              autoComplete='new-password'
                              value={useredit.confirmpassword}
                              onChange={(e) => { setUserEdit({ ...useredit, confirmpassword: e.target.value }); setConfirmpasswordError('') }}
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword3}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                  >
                                    {showPassword3 ? <VisibilityOff /> : <Visibility />}
                                  </IconButton>
                                </InputAdornment>
                              }
                            />
                          </FormControl>
                        </div>
                        {confirmpasswordError !== null ? <div className='error-div'>{confirmpasswordError}</div> : <></>}

                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default EditProfile;
