import React, { useState, useEffect } from 'react'
import './TwoFactor.css'
import { Grid } from '@mui/material'
import TopHeader from '../Header/TopHeader'
import Box from '@mui/material/Box';
import { makeStyles, withStyles } from '@mui/styles';
import { Stepper, Step, StepLabel, Button, StepConnector, Typography, Container } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import FilledInput from '@mui/material/FilledInput';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Radio from '@mui/material/Radio';
import key from '../../images/key.png'
import alert from '../../images/alert.png'




const steps = ['', '', ''];



const ColorStepConnector = withStyles({
    alternativeLabel: {
        top: 22,
    },
    active: {
        '& $line': {
            borderColor: '#3f51b5', // Change this to your desired color
        },
    },
    completed: {
        '& $line': {
            borderColor: '#3f51b5', // Change this to your desired color
        },
    },
    line: {
        borderColor: '#eaeaf0', // Change this to your desired color for inactive steps
        borderTopWidth: 3,
        borderRadius: 1,
    },
})(StepConnector);


const terms = ['For added security, Two-Factor Authentication (2FA) introduces an additional layer of protection to your account. In addition to your primary email and password, you will be required to input a code sent via email or SMS', '2FA is mandatory, and you must complete the 2FA setup to continue using Continuity Coach', 'You can configure SMS-based authentication at any time in the future by navigating to Account > Settings']



function Withdraw() {

    const [activeStep, setActiveStep] = useState(0);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    // const handleBack = () => {
    //     setActiveStep((prevActiveStep) => prevActiveStep - 1);
    // };

    const [paswdinput, setPaswdinpt] = useState('')

    const handlePaswd = (e) => {
        return (
            setPaswdinpt(e.target.value)
        )
    }

    const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const [selectedValue, setSelectedValue] = React.useState('');

    const handleChange = (event) => {
        setSelectedValue(event.target.value);
    };

    // console.log(activeStep, 'active')

    //resend

    const [resend, setResend] = useState(false)

    const handleresend = () => {
        setSeconds(15)
        setResend(true)
    }

    // console.log(resend, 'bool')

    // timer 
    const [seconds, setSeconds] = useState(15);

    // console.log(seconds, 'seconds');

    // console.log(paswdinput, 'value');

    useEffect(() => {
        if (activeStep === 2 && seconds > 0 || resend === true) {
            // console.log('secondtime')
            const timeoutId = setTimeout(() => {
                setSeconds((prevSeconds) => prevSeconds - 1);
                setResend(false)
            }, 1000);

            return () => clearTimeout(timeoutId);
            // Cleanup the timeout when component unmounts
        }





    }, [seconds, activeStep, resend]);






    return (
        <div className='Withdraw'>
            <Grid container spacing={0} className='depo-cont'>
                <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                    <TopHeader />
                </Grid>
                <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div className='twofa-comp'>
                        two - Factor Authentication
                    </div>
                </Grid>
                <Grid xs={12} sm={12} md={12} lg={10} xl={3}>

                    <Stepper activeStep={activeStep} alternativeLabel connector={<ColorStepConnector />}>

                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    <div>
                        {activeStep === steps.length ? (
                            <div>
                                <Typography>All steps completed</Typography>
                            </div>
                        ) : (
                            <div>
                                <Typography>
                                    {

                                        <div className='its-time-main'>
                                            <div className='secure-txt'>
                                                Its time to secure your account
                                            </div>
                                            <div className='key'>
                                                <img src={key} alt='key' />
                                            </div>
                                            <div className='set-up-txt'>
                                                Set up two - Factor Authentication (2FA)
                                            </div>
                                            <div className='terms-main'>
                                                <div className='trem-req'>
                                                    <img src={alert} alt='alert' />  Required
                                                </div>
                                                <div className='terms-txt'>
                                                    {terms.map((obj, ind) =>
                                                        <ul key={ind}>
                                                            <li>{obj}</li>
                                                        </ul>
                                                    )}
                                                </div>
                                            </div>
                                            {activeStep === 0 && <div className='twofa-input'>
                                                <FormControl sx={{ m: 1, width: '100%' }} variant="outlined">
                                                    <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                                                    <OutlinedInput
                                                        id="outlined-adornment-password"
                                                        type={showPassword ? 'text' : 'password'}
                                                        endAdornment={
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    aria-label="toggle password visibility"
                                                                    onClick={handleClickShowPassword}
                                                                    onMouseDown={handleMouseDownPassword}
                                                                    edge="end"
                                                                >
                                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        }
                                                        label="Password"
                                                        value={paswdinput}
                                                        onChange={handlePaswd}
                                                    />
                                                </FormControl>
                                            </div>
                                            }
                                            {activeStep === 1 || activeStep === 2 ? 
                                            <div style={{ marginTop: 'var(--mr-lg)' }}>
                                                <div>
                                                    <div className='email-sms'>
                                                        <Radio
                                                            checked={selectedValue === 'a'}
                                                            onChange={handleChange}
                                                            value="a"
                                                            name="radio-buttons"
                                                            inputProps={{ 'aria-label': 'A' }}
                                                            disabled={activeStep === 2}
                                                        />
                                                        <div>
                                                            <strong>Email:</strong>
                                                            I will get the verification code through my email account
                                                        </div>
                                                    </div>
                                                    <div className='email-sms'>
                                                        <Radio
                                                            checked={selectedValue === 'b'}
                                                            onChange={handleChange}
                                                            value="b"
                                                            name="radio-buttons"
                                                            inputProps={{ 'aria-label': 'A' }}
                                                            disabled={activeStep === 2}
                                                        />
                                                        <div>
                                                            <strong>SMS:</strong>
                                                            I will get the verification code as SMS text message
                                                        </div>
                                                    </div>
                                                </div>
                                                {activeStep === 2 && <>

                                                    <div className='mail-sent'>
                                                        An {selectedValue === 'a' ? 'Email' : 'SMS'} with 6-digit verification code has just sent to your {selectedValue === 'a' ? 'Email' : 'Number'}   <img src={alert} alt='alert' />
                                                    </div>
                                                    <div className='twofa-input'>
                                                        <FormControl sx={{ m: 1, width: '100%' }} variant="outlined">

                                                            <OutlinedInput
                                                                id="outlined-adornment-password"
                                                                type='number'

                                                                placeholder="Code"
                                                            />
                                                        </FormControl>
                                                    </div>
                                                    <div className='trem-req time'>
                                                        Timer:{seconds}sec
                                                    </div>
                                                </>}
                                            </div>
                                                : null
                                            }




                                            <div className='setup-btn'>
                                                {activeStep === 0 ? <Button onClick={handleNext} style={paswdinput?.length <= 3 || paswdinput?.length === 'undefined' ? { color: 'rgb(0 0 0 / 21%)', background: 'none', border: '1px solid #ccc' } : { background: 'rgba(0, 138, 255, 1)' }} disabled={paswdinput?.length <= 3 || paswdinput?.length === 'undefined'}>
                                                    setup
                                                </Button> :
                                                    activeStep === 1 ? <Button onClick={handleNext} style={selectedValue.length === 0 ? { color: 'rgb(0 0 0 / 21%)', background: 'none', border: '1px solid #ccc' } : { background: 'rgba(0, 138, 255, 1)' }} disabled={selectedValue.length === 0} >
                                                        send code
                                                    </Button> :
                                                        <div className='step-back'>
                                                            <Button onClick={handleNext} >
                                                                verify
                                                            </Button>
                                                            <Button onClick={handleresend} style={seconds !== 0 ? { color: 'rgb(0 0 0 / 21%)', background: 'none', border: '1px solid #ccc' } : { background: 'rgba(0, 138, 255, 1)' }} disabled={seconds !== 0} >
                                                                resend code
                                                            </Button>
                                                        </div>
                                                }

                                            </div>
                                        </div>
                                    }
                                </Typography>
                                <div >
                                    {/* <Button disabled={activeStep === 0} onClick={handleBack}>Back</Button> */}
                                    {/* <Button variant="contained" color="primary" >
                                        {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                    </Button> */}
                                </div>
                            </div>
                        )}
                    </div>

                </Grid>

            </Grid>
        </div>
    )
}

export default Withdraw
