import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Header from "../Header/Header";
import TopHeader from "../Header/TopHeader";
import LeftSideBar from "../SideBar/LeftSideBar";
import RightSideBar from "../SideBar/RightSideBar";
import nosweat from "../../images/nosweat.png";
import { Link } from "react-router-dom";
import "../Lobby/lobby.css";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import sgp from "../../images/sgp.svg";
import rht from "../../images/rht-arrow.svg";
import empty from "../../images/empty.png";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import profile1 from "../../images/surname-profile1.png";
import profile2 from "../../images/surname-profile2.png";
import vs from "../../images/vs.png";
import { useLocation, useNavigate } from 'react-router-dom'
import Slider from '@mui/material/Slider'

import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
// import Input from '@mui/joy/Input';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';
import Axios from "../../Axios";
import { ToastContainer, toast } from 'react-toastify';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ClipLoader from "react-spinners/ClipLoader";


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius: "10px",
  // border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Bet1v1 = ({ updateData }) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const token = window.localStorage.getItem("iphephile");

  // lobby table

  const [lobTable1, setLobTable1] = useState([
    {
      img1: `${profile1}`,
      midimg: `${vs}`,
      img2: `${profile2}`,
      title: '1 vs 1',
      name: "Guardians lander",
      sunname: "C.b Quantrill",
      num1: "+1.5",
      dnum1: "+1.5",
      num2: "5.00",
    },
    // { img1: `${profile1}`,midimg: `${vs}`, img2: `${profile2}`, name: 'Guardians lander', sunname: 'C.b Quantrill', num1: 'A', dnum1: 'B', num2: 'DJ' }
  ]);
  const [lobTable2, setLobTable2] = useState([
    {
      img1: `${profile1}`,
      midimg: `${vs}`,
      img2: `${profile2}`,
      name: "Guardians lander",
      sunname: "C.b Quantrill",
      num1: "A",
      dnum1: "B",
      num2: "DJ",
    },
  ]);
  const [lobTable3, setLobTable3] = useState([
    {
      img1: `${profile1}`,
      midimg: `${vs}`,
      img2: `${profile2}`,
      name: "Guardians lander",
      sunname: "C.b Quantrill",
      num1: "+1.5",
      dnum1: "+1.5",
      num2: "5.00",
    },
  ]);


  let location = useLocation()

  let dystate = location?.state
  let values = dystate?.value
  // console.log(dystate, 'state');
  // console.log(dystate?.value, 'dystatevalue');

  // timer

  // const [minutes, setMinutes] = useState(0);
  // const [seconds, setSeconds] = useState(60);
  const [title, setTitle] = useState('')
  const [name, setName] = useState('')
  const [amount, setAmount] = useState()
  const [nameErr, setNameErr] = useState(null)
  const [amountErr, setAmountErr] = useState(null)
  const [isloader, setLoader] = useState(false)


  const TimeDuration = async () => {
    const eventDate1 = dystate?.row?.event_duration
    const eventDate = eventDate1 * 1000

    const time = Date.parse(new Date(eventDate)) - Date.now();

    setDays(Math.floor(time / (1000 * 60 * 60 * 24)));
    setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
    setMinutes(Math.floor((time / 1000 / 60) % 60));
    setSeconds(Math.floor((time / 1000) % 60));

  }



  // popup
  const [open, setOpen] = React.useState(false)
  const [open1, setOpen1] = React.useState(false)
  const [index, setIndex] = useState()
  const [playerDetails, setPlayerDetails] = useState({})

  const handleOpen = (data, index) => {
    // console.log(data, 'dataaa');
    // console.log(index, 'index');
    let obj = {
      index: "",
      name: "",
      nickname: "",
      img: ""
    }
    obj.index = index
    obj.name = data.name
    obj.nickname = data.nickname
    obj.img = data.img

    setName(data.name)
    setAmount(dystate.row.minimum_betting_amount)
    setPlayerDetails(obj)


    setOpen(true)
  };

  const handleOpen1 = (data, team, index) => {
    // console.log(data, 'dataaa');
    // console.log(index, 'index');

    let obj = {
      index: "",
      name: "",
    }
    if (team.id == data) {
      setName(team.teamName[0].name)
      obj.name = team.teamName[0].name
    }
    obj.index = index
    setAmount(dystate.row.minimum_betting_amount)
    setPlayerDetails(obj)

    setOpen1(true)
  };


  const handleClose = () => setOpen(false);
  const handleClose1 = () => setOpen1(false);

  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [bet, setBet] = useState(false)

  useEffect(() => {
    const interval = setInterval(() => TimeDuration(), 1000);

    return () => clearInterval(interval);
  });

  // navigate data

  // const location = useLocation()
  const navigate = useNavigate()

  // let datatransfer = location.state


  function redirectToSidebar() {
    // navigate("/sidebar", {state: {nav:id}} )

  }

  const createBet = async () => {
    if (name == '') {
      setNameErr('please enter name')
    }
    else if (amount == '') {
      setAmountErr('please enter amount')
    }
    else if (amount < dystate.row.minimum_betting_amount) {
      setAmountErr(`mininum bet amount is ${dystate.row.minimum_betting_amount} `)
    }
    else {
      // const fee = amount / 100
      // const adminFee = fee * 2
      // const adminFee = amount + 2
      const data = {
        eventid: dystate.row._id,
        title: dystate.row.title,
        betting_type: dystate.row.betting_type,
        amount: amount,
        betamount: amount,
        player_details: playerDetails
      }
      try {
        setLoader(true)
        const response = await Axios.post('/users/createbet', data, {
          headers: {
            "Authorization": token
          }
        })
        if (response.data.success == true) {
          setLoader(false)
          setOpen(false)
          updateData({ message: response.data.message, tabvalue: values })
          toast.success(response.data.message)
        }
        else if (response.data.success == false) {
          setLoader(false)
          toast.error(response.data.message)
        }
      } catch (error) {
        setLoader(false)
        console.log(error, 'err');
      }
    }
  }

  const result = useLocation()

  const restultData = result?.state?.betting_type

  // useEffect(() => {
  //   if (restultData === 'djvsdj') {
  //   
  //   }

  // }, [restultData])
  useEffect(() => {
    if (restultData === 'djvsdj') {
      // setDj(true)
    }

  }, [restultData])




  const getBet = async (data) => {
    // console.log(data, 'data');
    const payload = {
      id: data?._id,
      name: name
    }
    try {
      setLoader(true)
      if (dystate?.row?.voting_limit <= dystate?.row?.total_betting_count) {
        setLoader(false)
        toast.error("Voting Limit is Finished")
      }
      else {
        const response = await Axios.post('/auth/getBetList', payload, {
          headers: {
            "Authorization": token
          }
        })

        if (response.data.success == false) {
          setLoader(false)
          toast.error(response.data.message)
          // setBet(true)
        }
        else {
          setLoader(false)
          createBet();
        }
      }
    } catch (error) {
      setLoader(false)
      console.log('error', error);
    }
  }


  return (
    <>
      <ToastContainer />
      <div className="lobby-page bet1v1">
        {/* <Box className="lobby-page-innner" sx={{ flexGrow: 1 }}>
                <Grid container spacing={0}>
                    <Grid className='lobby-content' item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <TopHeader />
                        <Header />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={2} xl={2}>
                        <LeftSideBar />
                    </Grid>
                   
                </Grid>
            </Box> */}

        <Box sx={{ flexGrow: 1 }}>
          {/* <Grid container spacing={0}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <TopHeader />
                        <Header />
                    </Grid>
                </Grid> */}

          {/* <Grid item xs={12} sm={12} md={12} lg={2} xl={2} className='sidebarcontanier'>
                        <LeftSideBar />
                    </Grid> */}

          {/* "overflow-x": "auto", */}

          <Grid
            container
            spacing={0}
            className="homebody-container"
            style={{ "flex-warp": "nowrap", "scroll-snap-align": "x manatory" }}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              style={{ "scroll-snap-align": "start" }}
            >
              <div className="no-sweat-first-bet bet1v1" >
                <div className='back-btn'
                  onClick={() => { navigate('/lobby', { state: { values } }) }}
                >
                  <ArrowBackIcon />
                </div>

                {/* {lobTable1?.map((row, ind) => ( */}

                <div className="table-lob">

                  <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                      <TableBody>
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                          className="tb-body-row"
                        >
                          <TableCell component="th" scope="row">
                            <div className="tab-ava-main-bg">
                              <div className="tab-ava-main flex-img form-struct">
                                <FormControl className="formtxt">
                                  <FormLabel><div className='preffered-title'>Title: <span>{dystate?.row?.title}</span></div></FormLabel>
                                  {/* <TextField placeholder='Title' className='text-field' /> */}
                                </FormControl>
                                <FormControl>
                                  {hours < 0 || minutes < 0 || seconds < 0 ? <div className="timer">Event Ended</div> : <FormLabel>Event End In: </FormLabel>}
                                  <div className="timer">
                                    {hours < 0 || minutes < 0 || seconds < 0 ? <></> : <>
                                      {hours < 10 ? "0" + hours : hours}:{minutes < 10 ? "0" + minutes : minutes}:{seconds < 10 ? "0" + seconds : seconds}
                                    </>}

                                  </div>
                                </FormControl>
                              </div>

                              {/* teamvsteam start */}

                              {dystate?.row?.betting_type === 'teamvsteam' &&
                                <>
                                  <div className="tab-ava-main flex-img bet1v1">
                                    {dystate?.row?.teams.map((team, index) => {
                                      // { console.log(index, 'index'); }
                                      return (
                                        <>
                                          <div className="team-container" key={index}>
                                            <div className="djvsdj-mian-div">
                                              <div className="ava-name">
                                                {team.players.map((player, ind) => {
                                                  return (
                                                    <div key={ind} className="profile-pic">
                                                      <Avatar
                                                        className="profile1"
                                                        alt={player.name}
                                                        src={player.img}
                                                      />
                                                      <div className="profile-info">
                                                        {player.name}
                                                        <div className="ava-innername username1">
                                                          {player.nickname}
                                                        </div>
                                                      </div>
                                                    </div>
                                                  )
                                                })}
                                              </div>
                                            </div>
                                            <div className="btn-1-table" key={index}>
                                              <Button
                                                onClick={() => { handleOpen1(team.id, team, index) }}
                                              >
                                                <div>
                                                  <div className="vr-color">Bet: {dystate.row.minimum_betting_amount}</div>
                                                </div>
                                              </Button>
                                            </div>
                                          </div>
                                          <div className="flex-button">


                                            {index < dystate.row.teams.length - 1 &&
                                              <div className='midimg2'>
                                                <img alt="Remy Sharp" src={vs} />
                                              </div>
                                            }
                                            <Modal open={open1}
                                              onClose={handleClose1}
                                              aria-labelledby="modal-modal-title"
                                              aria-describedby="modal-modal-description" className="bet1v1-popup">
                                              <Box sx={style}>
                                                <Button onClick={() => { handleClose1() }} class="popup"><CloseIcon /></Button>
                                                <Typography id="modal-modal-title" variant="h6" component="h2">

                                                  <div className='preffered-title'><span> {dystate.row.betting_type}</span></div>
                                                  <div className='preffered-title'><span>Title: {dystate.row.title}</span></div>

                                                </Typography>
                                                <div className="tab-ava-main-body">
                                                  <div className="tab-ava-main flex-img bet1v1" sx={{ mt: 2 }}>
                                                    <div className="ava-name">
                                                      <div className="profile-pic">
                                                      </div>
                                                      <div className="profile-info">
                                                        {dystate?.row?.teamAname}
                                                      </div>
                                                    </div>
                                                    <div className='vs'>
                                                      vs
                                                    </div>
                                                    <div className="ava-name">
                                                      <div className="profile-pic">
                                                      </div>
                                                      <div className="profile-info">
                                                        {dystate?.row?.teamBname}
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <FormControl className="formtxt">

                                                    <TextField placeholder='Name' className='text-field' value={name}
                                                    />
                                                  </FormControl>
                                                  <div className="error-code">
                                                    {nameErr && <div className="error-message" style={{ color: 'red', fontSize: "12px" }}>{nameErr}</div>}
                                                  </div>
                                                  <FormControl className="formtxt">
                                                    <TextField placeholder='Amount' className='text-field'
                                                      value={amount}
                                                    />
                                                  </FormControl>


                                                  <div className='display-flex'>Admin Fee: {dystate.row.admin_fee}</div>
                                                  <div className="btn-1-table">
                                                    {
                                                      isloader ? <ClipLoader color={'#00008B'} /> :
                                                        <Button
                                                          onClick={() => { createBet(); }}
                                                        >Submit</Button>
                                                    }</div>

                                                </div>
                                              </Box>
                                            </Modal>
                                          </div>

                                        </>
                                      )
                                    })}
                                  </div>

                                </>
                              }


                              {/* teamvsteam end */}

                              {
                                dystate?.row?.betting_type == 'onevsone' || dystate?.row?.betting_type == 'djvsdj' ?
                                  <>

                                    <div className="tab-ava-main flex-img bet1v1">
                                      <div className="djvsdj-mian-div">

                                        <div className="ava-name">
                                          <div className="profile-pic">
                                            <Avatar
                                              className="profile1"
                                              alt="Remy Sharp"
                                              src={dystate.row.teamA[0].img}
                                            />
                                          </div>
                                          <div className="profile-info">
                                            {dystate.row.teamA[0].name}
                                            <div className="ava-innername username1">
                                              {dystate.row.teamA[0].nickname}
                                            </div>
                                          </div>
                                        </div>


                                      </div>
                                      <div className='vs'>
                                        vs
                                      </div>

                                      <div className="djvsdj-mian-div">
                                        <div className="ava-name">
                                          <div className="profile-pic">
                                            <Avatar
                                              className="profile2"
                                              alt="Remy Sharp"
                                              src={dystate.row.teamA[1].img}
                                            />
                                          </div>
                                          <div className="profile-info">
                                            {dystate.row.teamA[1].name}
                                            <div className="ava-innername username2">
                                              {dystate.row.teamA[1].nickname}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="flex-button">
                                      <div className="btn-1-table">
                                        <Button
                                          onClick={() => { handleOpen(dystate.row.teamA[0], 0) }}
                                        >
                                          <div>
                                            <div className="vr-color">Bet: {dystate.row.minimum_betting_amount}</div>
                                          </div>
                                        </Button>
                                      </div>
                                      <div className="btn-1-table">
                                        <Button
                                          onClick={() => { handleOpen(dystate.row.teamA[1], 1) }}
                                        >
                                          <div>
                                            <div className="vr-color">Bet: {dystate.row.minimum_betting_amount}</div>
                                          </div>
                                        </Button>
                                      </div>
                                      <Modal open={open}
                                        onClose={handleClose}
                                        aria-labelledby="modal-modal-title"
                                        aria-describedby="modal-modal-description" className="bet1v1-popup">
                                        <Box sx={style}>
                                          <Button onClick={() => { handleClose() }} class="popup"><CloseIcon /></Button>
                                          <Typography id="modal-modal-title" variant="h6" component="h2">

                                            <div className='preffered-title'><span> {dystate.row.betting_type}</span></div>
                                            <div className='preffered-title'><span>Title: {dystate.row.title}</span></div>

                                          </Typography>
                                          <div className="tab-ava-main-body">
                                            <div className="tab-ava-main flex-img bet1v1" sx={{ mt: 2 }}>
                                              <div className="ava-name">
                                                <div className="profile-pic">

                                                </div>
                                                <div className="profile-info">
                                                  <>
                                                    {dystate.row.teamA[0].name}
                                                  </>

                                                </div>
                                              </div>
                                              <div className='vs'>
                                                vs
                                              </div>
                                              <div className="ava-name">
                                                <div className="profile-pic">

                                                </div>
                                                <div className="profile-info">
                                                  <>{dystate.row.teamA[1].name}</>
                                                </div>
                                              </div>
                                            </div>
                                            <FormControl className="formtxt">
                                              <TextField placeholder='Name' className='text-field' value={name}
                                              />
                                            </FormControl>
                                            <div className="error-code">
                                              {nameErr && <div className="error-message" style={{ color: 'red', fontSize: "12px" }}>{nameErr}</div>}
                                            </div>
                                            <FormControl className="formtxt">
                                              <TextField placeholder='Amount' className='text-field'
                                                value={amount}
                                              />
                                            </FormControl>
                                            <div className='display-flex'>Admin Fee: {dystate.row.admin_fee}%</div>
                                            <div className="btn-1-table">
                                              {
                                                isloader ? <ClipLoader color={'#00008B'} /> :
                                                  <Button
                                                    // onClick={() => { navigate('<RightSideBar />', { state: lobTable1 }) }}
                                                    onClick={() => { getBet(dystate.row); }}
                                                  >Submit</Button>
                                              }</div>
                                          </div>
                                        </Box>
                                      </Modal>
                                    </div>
                                  </> : <></>
                              }
                            </div>
                          </TableCell>

                          {/* <TableCell component="th" scope="row">
                            <div className="flex-button">
                              <div className="btn-1-table">
                                <Button onClick={(e) => { betSlip('player1') }}>
                                  <div>
                                    <div className="vr-color"> {dystate.minimum_betting_amount}</div>
                                    <span>+</span>{'1.5'}
                                  </div>
                                </Button>
                              </div>
                              <div className="btn-1-table">
                                <Button onClick={(e) => { betSlip('player2') }}>
                                  <div>
                                    <div className="vr-color">{dystate.minimum_betting_amount}</div>
                                     <span>+</span> {'2'}
                                  </div>
                                </Button>
                              </div>
                            </div>
                          </TableCell> */}


                        </TableRow>
                        {/* // ))} */}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
                {/* // ))} */}



              </div>
            </Grid>

            {/* {dystate.num2.toLowerCase() === 'dj' ? 
        <>  <Grid
        item
        xs={12}
        sm={12}
        md={6}
        lg={6}
        xl={6}
        style={{ "scroll-snap-align": "start" }}>
          <div className="slider-maindj">
          <div className="ab">
          A
        </div>
        <div className="dj-slider">
          <Slider defaultValue={58} aria-label="Default" valueLabelDisplay="on" />
        </div>
          </div>
    
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={6}
        lg={6}
        xl={6}
        style={{ "scroll-snap-align": "start" }}>
          <div className="slider-maindj">
          <div className="ab">B</div>
        <div className="dj-slider">
          <Slider defaultValue={36} aria-label="Default" valueLabelDisplay="on" />
        </div>
          </div>
       
      </Grid></>
       :  null}  */}


          </Grid>
        </Box>
      </div>
    </>
  );
};

export default Bet1v1;
