const consts = {
    // MainUrl: "http://localhost:3000",
    // Main: "Development",
    // BackendUrl: "http://localhost:3500/api/v1",
    //SocketUrl: "http://localhost:3500",

    MainUrl: "https://soundsystemarena.com/",
    Main: "Development",
    BackendUrl: "https://soundsystemarena.com/api/v1",
    SocketUrl: "https://soundsystemarena.com",
    route: "",
};

export default consts;