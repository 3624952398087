import React, { useState, useEffect } from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from "react-bootstrap/esm/Container";
import betlogo from '../../images/ssh-logo.png'
import './TwoFactorAuth.css'
import { useRef } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import Axios from '../../Axios'
import { useNavigate, useLocation } from 'react-router-dom'
import consts from '../../Constant';
import ClipLoader from "react-spinners/ClipLoader";
import { Tune } from '@mui/icons-material';


const TwoFactorAuth = () => {

  const verifySecret = useRef(null)
  const navigate = useNavigate()
  const location = useLocation()
  const [token, setToken] = useState('');
  const [usertoken, setUserToken] = useState('');
  const [isload, setLoad] = useState(false)



  useEffect(() => {
    // console.log(location.state, '11111')
    const tokenFromState = location?.state?.status?.token;
    setToken(tokenFromState);
    const uertoken = location?.state?.status?.user?._id
    setUserToken(uertoken)

  }, [])


  const verify2FA = async () => {
    try {
      setLoad(true)
      const { data } = await Axios.post(`/auth/verify2faOtp`, {
        secret: verifySecret.current.value
      }, {
        headers: {
          "Authorization": token
        }
      })
      if (data?.success === true) {
        setLoad(false)
        window.localStorage.setItem('iphephile', token)
        window.localStorage.setItem('iphephileid', usertoken)
        toast.success(data?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      setTimeout(() => {
        // verifySecret.current.value = ""
        navigate(`${consts.route}/`)
      }, 2000)


    } catch ({ response }) {
      if (response?.data?.success === false) {
        setLoad(false)
        toast.error("Invalid-Otp", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      setTimeout(() => {
        verifySecret.current.value = ""
      }, 2000)
    }
  }

  return (
    <>
      <ToastContainer />
      <div className='two-factor-auth-page'>
        <Container>
          <Row>
            {/* <Col xl={4} lg={4} md={12} sm={12} xs={12}>
            </Col> */}

            <Col xl={4} lg={4} md={12} sm={12} xs={12} id='mid-part-main-id'>
              <div className='inner-2fa'>
                <div className='betlogo'><img src={betlogo} alt="bet-logo" /></div>
                <div className='google-auth'><h3>Google Authenticator</h3></div>
                <h4>Google Verification</h4>
                <p>Enter the 6 digits code</p>
                <div className='input-code'><input type="text" ref={verifySecret} /></div>
                {
                  isload ?
                    <button >
                      <ClipLoader color={'#00008B'} />
                    </button> :
                    <button onClick={verify2FA}>Submit</button>
                }

              </div>
            </Col>

            <Col xl={4} lg={4} md={12} sm={12} xs={12}>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default TwoFactorAuth
