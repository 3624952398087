import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Header from '../Header/Header';
import TopHeader from '../Header/TopHeader';
import './Landing.css'
// import lanbg from '../../images/landing-title-bg.png'
import { Button } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import lc1 from "../../images/lc1.png"
import lc2 from "../../images/lc2.png"
import lc3 from "../../images/lc3.png"
import lc4 from "../../images/lc4.png"
import vs from "../../images/vs.png"
import cy1 from "../../images/cy1.png"
import cy2 from "../../images/cy2.png"
import cy3 from "../../images/cy3.png"
import cy4 from "../../images/cy4.png"
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import country from "../../images/countrymap.png"
import Footer from '../Footer/Footer';
import { useLocation, useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import Axios from "../../Axios.js"
import Badge from '@mui/material/Badge';
import Backdrop from '@mui/material/Backdrop';
import verify from '../../images/verify.gif'


const Landing = (props) => {

    const location = useLocation()
    const navigate = useNavigate()

    const [users, setusers] = useState(null)
    console.log(users, 'users');
    const token = window.localStorage.getItem("iphephile")


    const [cardy, setCardy] = useState([
        { img: `${cy1}` },
        { img: `${cy2}` },
        { img: `${cy3}` },
        { img: `${cy4}` },
    ])

    const [howplay, setHowplay] = useState([
        { name: 'Place a music' },
        { name: 'Place a music' },
        { name: 'Place a music' },
        { name: 'Place a music' },
    ])

    const [countries, setCountries] = useState([
        { name: 'Arizona' },
        { name: 'Colorado' },
        { name: 'Connecticut' },
        { name: 'Illinois' },
        { name: 'Indiana' },
        { name: 'Iowa' },
        { name: 'Kansas' },
        { name: 'Kentucky' },
        { name: 'Louisiana' },
        { name: 'Maryland' },
        { name: 'Massachusetts' },
        { name: 'Michigan' },
        { name: 'New Jersey' },
        { name: 'New York' },
        { name: 'Ohio' },
        { name: 'Pennsylvania' },
        { name: 'Tennessee' },
        { name: 'Virginia' },
        { name: 'Vermont' },
        { name: 'West Virginia' },
        { name: 'West Virginia' },
        { name: 'Mohegan Reservation (Connecticut)' },
    ])

    useEffect(() => {
        if (location?.state?.status == "Verified") {
            toast.success('Email Verified Successfully')
            // setTimeout(() =>{
            //     navigate('/')

            // },2500)
        }
        if (location?.state?.status == "Already Verified") {
            toast.error('Email Already Verified')
            // setTimeout(() =>{
            //     navigate('/')

            // },2500)
        }
    }, [])


    const [member, setMember] = useState(false);
    const [backdrop, setBackdrop] = useState(false);

    const handleBackdrop = () => {
        setBackdrop(true);
        const timer = setTimeout(() => {
            setBackdrop(false);
            setMember(true);
        }, 3000);

        return () => clearTimeout(timer);
    };

    const checks2fa = async () => {
        try {
            const data = await Axios.get(`/auth/check2fa`, {
                headers: {
                    "Authorization": token
                }
            })
            if (data) {
                setusers(data?.data?.result)
            }

        } catch (error) {
            console.log("error", error)

        }
    }


    useEffect(() => {
        if (token != null) {
            checks2fa()
        }
    }, [token])

    const userMemberShip = async () => {
        const payload = {
            _id: users._id
        }
        try {
            const response = await Axios.post('/users/addMemberShip', payload, {
                headers: {
                    "Authorization": window.localStorage.getItem('iphephile')
                }
            })
            if (response?.data?.success == true) {
                handleBackdrop()
                checks2fa()
            }

        } catch (error) {
            console.log(error, 'membership err');
        }
    }

    return (
        <>
            <ToastContainer />
            <div className='landing-page'>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={0}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TopHeader />
                        </Grid>
                    </Grid>


                    <Grid container spacing={0} sx={{ justifyContent: 'center' }}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <div className='sound-system-arena-title-main'>
                                <Grid container spacing={0}>
                                    <Grid item xs={12} sm={12} md={12} lg={10} xl={10}>
                                        <div className='sound-system-arena-text'>
                                            sound system arena
                                        </div>
                                        <div className='sound-system-arena-subtitle'>
                                            The best place to bet on, play online games, and build your team.
                                        </div>
                                        <div className='sound-arena-para'>
                                            Play against real people for cash
                                        </div>
                                        <div className='join-now-arena'>
                                            {
                                                users?.member_ship == true ? <></> :
                                                    <Button onClick={() => { userMemberShip() }} >
                                                        Become a member <ArrowForwardIcon />
                                                    </Button>
                                            }

                                        </div>

                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={2} xl={2}>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ margin: '50px 0px' }}>
                            <div className='explore-world'>
                                Explore the world
                            </div>
                            <div className='exp-world-sub'>
                                The best place to bet on music, play online games, and build your team.
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={10} xl={10}>
                            <div className='vs-1'>
                                <div className='vs-banner'>
                                    <div>
                                        <div className='vs-img1'>
                                            <img src={lc1} alt='lc-1' />
                                        </div>

                                        <div className='c1-name'>
                                            Tommy
                                        </div>
                                    </div>
                                    <div>
                                        <div className='battle'>
                                            BATTLE
                                        </div>
                                        <div>
                                            <img src={vs} alt='lc-1' />
                                        </div>
                                    </div>
                                    <div>
                                        <div className='vs-img2'>
                                            <img src={lc2} alt='lc-1' />
                                        </div>

                                        <div className='c1-name'>
                                            Bommy
                                        </div>
                                    </div>
                                </div>
                                <div className='sound-arena'>
                                    <Button>sound arena</Button>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ padding: { md: '60px 0px 0px 0px', sx: '0px' } }}>
                            <div className='explore-world'>
                                the content you love
                            </div>
                            <Grid container spacing={0}>

                                <Grid xs={12} sm={12} md={12} lg={8} xl={6}>
                                    <Grid container spacing={0} sx={{ alignItems: 'var(--txt-al-c)' }}>
                                        {cardy.map((row, ind) => {
                                            let mystring = ''
                                            mystring += (ind === 0 || ind === 3) ? 'img1' : 'img2';
                                            mystring += (ind === 0 || ind === 2) ? 'merge' : 'nomerge';

                                            return (
                                                <Grid xs={6} sm={6} md={6} lg={6} xl={6}>

                                                    <div className={mystring}>
                                                        <img src={row.img} alt='cy1' />
                                                    </div>
                                                </Grid>
                                            )
                                        })}
                                    </Grid>
                                </Grid>


                                <Grid xs={12} sm={12} md={12} lg={4} xl={4} sx={{ margin: 'auto' }}>
                                    <div className='cy-banner'>
                                        <div>
                                            <div className='cy-title' >
                                                sound system arena
                                            </div>
                                            <div className='cy-para'>
                                                Fusce justo mi, vehicula id arcu et, dapibus tristique lectus. Vivamus a elit sodales, tincidunt nunc non, maximus lacus. Fusce a augue sed dolor auctor iaculis vitae id mauris. Integer ut lectus non neque suscipit luctus. Mauris et erat id ipsum condimentum cursus. Sed tempus enim non massa mattis iaculis. In quis massa risus
                                            </div>
                                            <div className='join-now-arena'>
                                                <Button onClick={() => { navigate('/lobby') }}>
                                                    BET NOW <ArrowForwardIcon />
                                                </Button>
                                            </div>
                                        </div>

                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ padding: { md: '60px 0px 0px 0px', sx: '0px' } }}>
                            <div className='explore-world'>
                                where can i play
                            </div>
                            <Grid container spacing={0} sx={{ justifyContent: 'center' }}>
                                <Grid xs={12} sm={12} md={12} lg={10} xl={10}>
                                    <div className='location-list'>
                                        Available in 21 states.<span className='view-detail'>View Details</span>
                                        <p className='loc-para'>*This list does not include retail locations.</p>
                                    </div>
                                </Grid>
                                <Grid xs={12} sm={12} md={12} lg={6} xl={6}>
                                    <Stack direction='row' spacing={0} sx={{ textAlign: 'var(--txt-al-l)', justifyContent: 'space-around' }} className='country-stck'>
                                        <Stack spacing={2}>
                                            {countries.slice(0, 14 + 1).map((row, ind) => {
                                                return (
                                                    <div>
                                                        {row.name}
                                                    </div>
                                                )
                                            })}
                                        </Stack>
                                        <Stack spacing={2}>
                                            {countries.slice(14 + 1).map((row, ind) => {
                                                return (
                                                    <div>
                                                        {row.name}
                                                    </div>
                                                )
                                            })}
                                        </Stack>
                                    </Stack>

                                </Grid>
                                <Grid xs={12} sm={12} md={12} lg={6} xl={6}>
                                    <div className='Country-Map'>
                                        <img src={country} alt='Country Map' />
                                    </div>
                                </Grid>
                            </Grid>

                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ padding: { md: '60px 0px 0px 0px', sx: '0px' } }}>
                            <div className='explore-world'>
                                how to play
                            </div>
                            <div className='howtoplay-content-banner'>
                                <Grid container spacing={0}>
                                    {howplay.map((row, ind) => {
                                        return (
                                            <Grid xs={12} sm={6} md={6} lg={4} xl={3}>
                                                <div className='how-c'>
                                                    <div className='how-num'>
                                                        {'0' + (ind + 1)}
                                                    </div>
                                                    <div className='how-desc'>
                                                        {row.name}
                                                    </div>
                                                    <div className='how-sub'>
                                                        Learn how to play your favorite casino games. From slots to blackjack, you'll be a pro.
                                                    </div>

                                                </div>
                                            </Grid>
                                        )
                                    })}
                                </Grid>
                            </div>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ padding: { md: '60px 0px 0px 0px', sx: '0px' } }} >
                            <div className='explore-world'>
                                why sound system arena
                            </div>
                            <Grid container spacing={0} className='fusce-para' sx={{
                                justifyContent: 'center', margin: { md: '100px 0px 0px 0px', sx: '0px' }
                            }} >
                                <Grid xs={12} sm={12} md={11} lg={10} xl={10} sx={{
                                    padding: '0 15px'
                                }}>
                                    <Grid container spacing={0} className='whyssa-container'>
                                        <Grid xs={12} sm={12} md={6} lg={4} xl={3}>
                                            <div className='why-ava'>
                                                <Avatar alt="Remy Sharp" src={lc3} sx={{ width: 380, height: 380 }} />
                                            </div>
                                        </Grid>
                                        <Grid xs={12} sm={12} md={6} lg={8} xl={9}>
                                            <div className='whyssa-text'>
                                                Fusce justo mi, vehicula id arcu et, dapibus tristique lectus. Vivamus a elit sodales, tincidunt nunc non, maximus lacus. Fusce a augue sed dolor auctor iaculis vitae id mauris. Integer ut lectus non neque suscipit luctus. Mauris et erat id ipsum condimentum cursus. Sed tempus enim non massa mattis iaculis. In quis massa risus Fusce justo mi, vehicula id arcu et, dapibus tristique lectus. Vivamus a elit sodales, tincidunt nunc non, maximus lacus. Fusce a augue sed dolor auctor iaculis vitae id mauris. Integer ut lectus non neque suscipit luctus. Mauris et erat id ipsum condimentum cursus. Sed tempus enim non massa mattis iaculis. In quis massa risus
                                            </div>
                                        </Grid>
                                    </Grid>

                                </Grid>
                            </Grid>
                            <Grid container spacing={0} className='fusce-para' sx={{ justifyContent: 'center', margin: { md: '30px 0px 60px 0px', sm: '30px 0px 50px 0px' } }}>
                                <Grid xs={12} sm={12} md={11} lg={10} xl={10} sx={{
                                    padding: '0 15px'
                                }}>
                                    <Grid container spacing={0} className='whyssa-container secound-child'>

                                        <Grid xs={12} sm={12} md={6} lg={8} xl={9}>
                                            <div className='whyssa-text'>
                                                Fusce justo mi, vehicula id arcu et, dapibus tristique lectus. Vivamus a elit sodales, tincidunt nunc non, maximus lacus. Fusce a augue sed dolor auctor iaculis vitae id mauris. Integer ut lectus non neque suscipit luctus. Mauris et erat id ipsum condimentum cursus. Sed tempus enim non massa mattis iaculis. In quis massa risus Fusce justo mi, vehicula id arcu et, dapibus tristique lectus. Vivamus a elit sodales, tincidunt nunc non, maximus lacus. Fusce a augue sed dolor auctor iaculis vitae id mauris. Integer ut lectus non neque suscipit luctus. Mauris et erat id ipsum condimentum cursus. Sed tempus enim non massa mattis iaculis. In quis massa risus
                                            </div>
                                        </Grid>
                                        <Grid xs={12} sm={12} md={6} lg={4} xl={3}>
                                            <div className='why-ava2'>
                                                <Avatar alt="Remy Sharp" src={lc4} sx={{ width: 380, height: 380 }} />
                                            </div>
                                        </Grid>
                                    </Grid>

                                </Grid>
                            </Grid>

                        </Grid>
                    </Grid>
                    <Grid container spacing={0}>
                        <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Footer />
                        </Grid>
                    </Grid>
                </Box>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, display: 'flex', flexDirection: 'column', fontSize: '18px', fontWeight: '500' }}
                    open={backdrop}
                // onClick={handleClose}
                >
                    <img src={verify} alt='img' />
                    <div>
                        Verified!
                    </div>
                </Backdrop>
            </div >
        </>
    )
}

export default Landing
