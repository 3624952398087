import React, { useState } from 'react'
import { Grid } from '@mui/material'
import hlogo from "../../images/soundlogo.png"
import './Footer.css'
import Stack from '@mui/material/Stack';



function Footer() {

    const [countries, setCountries] = useState([
        { name: 'bet on' },
        { name: 'bet on' },
        { name: 'bet on' },
        { name: 'bet on' },
        { name: 'bet on' },
        { name: 'bet on' },
        { name: 'lobby' },
        { name: 'News' },
        { name: 'Research' },
        { name: 'TV' },
        { name: 'Members' },
        { name: 'Youtube' },
        { name: 'Instagram' },
        { name: 'Facebook' },
        { name: 'Twitter' },

    ])
    return (
        <div className='footer'>
            <Grid container spacing={0} sx={{ justifyContent: 'center' }}>
                <Grid xs={12} sm={12} md={12} lg={10} xl={10}>
                    <Grid container spacing={0}>
                        <Grid xs={12} sm={12} md={12} lg={6} xl={6}>
                            <div className='footer-left'>
                                <img src={hlogo} alt='hlogo' />
                                <div className='fl-para'>
                                    The best place to bet on, play online games, and build your team.
                                </div>
                            </div>
                        </Grid>
                        <Grid xs={12} sm={12} md={12} lg={6} xl={6}>
                            <Stack direction='row' spacing={0} sx={{ textAlign: 'var(--txt-al-l)', justifyContent: 'space-around' }} className='country-stck2'>
                                <Stack spacing={2}>
                                    <div className='fot-cat'>Categories</div>
                                    {countries.slice(0, 5 + 1).map((row, ind) => {
                                        return (
                                            <div className='ft-inner-txt'>
                                                {row.name}
                                            </div>
                                        )
                                    })}
                                </Stack>
                                <Stack spacing={2}>
                                    <div className='fot-cat'>Company</div>

                                    {countries.slice(5, 10 + 1).map((row, ind) => {
                                        return (
                                            <div className='ft-inner-txt'>
                                                {row.name}
                                            </div>
                                        )
                                    })}

                                </Stack>
                                <Stack spacing={2}>
                                    <div className='fot-cat'>Social Media</div>
                                    {countries.slice(10 + 1).map((row, ind) => {
                                        return (
                                            <div className='ft-inner-txt'>
                                                {row.name}
                                            </div>
                                        )
                                    })}

                                </Stack>
                            </Stack>
                            <div className='copyright'>
                                © sound system arena, 2024
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

export default Footer
