import React, { useEffect } from 'react'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import './Header.css'
import Tooltip from '@mui/material/Tooltip';
import logo from '../../images/logo.png'
import SearchIcon from '@mui/icons-material/Search';
import searchicon from '../../images/search-icon.png'
import bankicon from '../../images/bank-icon.svg'
import messageicon from '../../images/message-icon.svg'
import accounticon from '../../images/account-icon.svg'
import gamblingicon from '../../images/gambling-icon.svg'
import activityicon from '../../images/activity-icon.svg'
import breakicon from '../../images/break-icon.svg'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { QRCodeSVG } from "qrcode.react";
import Switch from '@mui/material/Switch';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import FilledInput from '@mui/material/FilledInput';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Token } from '@mui/icons-material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import DownloadIcon from '@mui/icons-material/Download';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import mybet from '../../images/mybet.png'
import home from '../../images/home.png'
import Offers from '../../images/Offers.png'
import LogoutIcon from '@mui/icons-material/Logout';
import Avatar from '@mui/material/Avatar';
import profile from '../../images/profile.svg';
import notification from '../../images/notification.svg';
import addfunds from '../../images/addfunds.gif';
import RefreshIcon from '@mui/icons-material/Refresh';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import ava from "../../images/sound-avatar.png"
import Axios from "../../Axios.js"
// import { Toaster, toast } from 'react-hot-toast'
import { ToastContainer, toast } from 'react-toastify';
import consts from '../../Constant'
import { NavLink } from 'react-router-dom';


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));



const useStyles = makeStyles({
  root: {
    background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    border: 0,
    borderRadius: 3,
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    color: 'white',
    height: 48,
    padding: '0 30px',
  },
  flexcls: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  dashboarbodycls: {
    background: 'transparent !important',
    borderRadius: '0px !important',
    boxShadow: 'none !important',
  },
  passwordeye: {
    borderRadius: '3px',
    background: 'rgba(255, 255, 255, 1)',
    marginLeft: '0px !important',
    marginRight: '0px !important',
    boxShadow: '4px 4px 15px 0px rgba(149, 206, 255, 0.74) inset'
  },
  profiletabhead: {
    borderBottom: '1px solid rgba(255, 255, 255, 0.10)',
  },
  popupboxcls: {
    borderRadius: '3px',
    background: 'rgba(255, 255, 255, 1)',
    background: 'rgba(5, 40, 90, 1) !important',
    width: '400px !important',
    '@media (max-width: 575.98px)': {
      width: '95% !important',
    },
  },
  loginpopupboxcls: {
    scrollbarWidth: 'none !important',
    overflowY: 'auto',
    borderRadius: '3px',
    border: 'none !important',
    background: 'rgba(5, 40, 90, 1) !important',
    width: '500px !important',
    '@media (max-width: 575.98px)': {
      width: '95% !important',
      height: 'auto !important',
    },
    '&::-webkit-scrollbar': {
      display: 'none !important'
    },
    '&::-webkit-scrollbar': {
      width: '0.5em !important'
    }

  },
  registerpopupboxcls: {
    height: '80% !important',
    scrollbarWidth: 'none !important',
    overflowY: 'auto',
    borderRadius: '3px',
    boxShadow: 'rgba(149, 206, 255, 0.74) !important',
    width: '500px !important',
    '@media (max-width: 991.98px)': {
      width: '95% !important',
      height: '90% !important',
    },
    '&::-webkit-scrollbar': {
      display: 'none !important'
    },
    '&::-webkit-scrollbar': {
      width: '0.5em !important'
    }

  },
  searchWrap: {
    transition: 'all 0.3s ease',
    opacity: 0,
    visibility: 'hidden',
    '&.active': {
      opacity: 1,
      visibility: 'visible',
    }
  }

});

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};



function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#000',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#000' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'rgba(5, 40, 90, 1)',
  border: 'none',
  boxShadow: 24,
  p: 4,
};


const Header = () => {

  const classes = useStyles();
  const navigate = useNavigate();
  const token = window.localStorage.getItem("iphephile")  //to store the user token
  const inpassref = useRef(null);                   //state variable to store the password register
  const forgotmail = useRef(null)                    //state variable to store the mail forgot
  const inputconfrimRef = useRef(null);                   //state variable to store the confirmpassword register
  const email = useRef(null);                             // state variable to store the email register
  const name = useRef(null);                               // state variable to store the name register
  const phone = useRef(null);                                // state variable to store the phone register
  const address = useRef(null);                              // state variable to store the address register
  const city = useRef(null);                                 // state variable to store the city register
  const country = useRef(null);                              // state variable to store the country register
  const lemail = useRef(null);                            //state variable to store the email login
  const lpswd = useRef(null);                             //state variable to store the password login
  const [mail, setMail] = useState("")                 // state variable to store the mail register
  const [emailerr, setemailerr] = useState(null)       // state variable to store the emailerr register
  const [usernameerr, setusernameerr] = useState(null)  // state variable to store the usernameerr register
  const [addresserr, setaddresserr] = useState(null)    // state variable to store the addresserr register
  const [countryerr, setcountryerr] = useState(null)   // state variable to store the countryerr register
  const [cityerr, setcityerr] = useState(null)        // state variable to store the cityerr register
  const [phoneerr, setPhoneerr] = useState(null)      // state variable to store the phoneerr register
  const [passworderr, setpassworderr] = useState(null)  // state variable to store the passworderr register
  const [cpassworderr, setcpassworderr] = useState(null)  // state variable to store the cpassworderr register
  const [lemailerr, setLemailerr] = useState(null)       // state variable to store the emailerr login
  const [lpswerr, setLPswerr] = useState(null)           // state variable to store the passsworderr login
  const [open, setOpen] = useState(false);
  const [opens, setOpens] = useState(false);
  const [openss, setOpenss] = useState(false);
  const [profileOpen, setProfileOpen] = useState(false);    // Define state variables for profile and detail open states
  const [detailOpen, setDetailOpen] = useState(false);      // Define state variables for profile and detail open states
  const [amount, setAmount] = useState()        // state variable to store the userwalletamount
  const [mailerr, setmailerr] = useState(null)   // state variable to store the mailerror forgot
  const [eye, setEye] = useState(false)         // state variable to store the visibility icon
  const [users, setusers] = useState(null)   // state variable to store the username



  // const paymentref = useRef(null)
  // const ethref = useRef(null)
  // const [ethTooltipTitle, setEthTooltipTitle] = useState('Copy ETH');
  // const bscref = useRef(null)
  // const [bscTooltipTitle, setBscTooltipTitle] = useState('Copy BSC');
  // const tronref = useRef(null)
  // const [tronTooltipTitle, setTronTooltipTitle] = useState('Copy TRON');
  // const btcref = useRef(null)
  // const [btcTooltipTitle, setBtcTooltipTitle] = useState('Copy BTC');
  // const xrpref = useRef(null)
  // const [xrpTooltipTitle, setXrpTooltipTitle] = useState('Copy XRP');
  // const [hoverdata, sethover] = useState()
  // const user = JSON.parse(localStorage.getItem("users"))
  // const [loginerr, setloginerr] = useState(null)
  // const [registererr, setregistererr] = useState(null)
  // const [isloginBot, setIsloginBot] = useState(false);
  // const [isregisterBot, setIsregisterBot] = useState(false);
  // const [confirmPass, setConfirmPass] = useState(null) 
  // const [profileData, setProfileData] = useState()
  // const [Name, setName] = useState(false);            
  // const [amounterr, setamounterr] = useState(null)       
  // const [opensss, setOpensss] = useState(false);         
  // const [openssss, setOpenssss] = useState(false);        
  // const [modalpay, setmodalpay] = useState(false);           
  // const [addressopen, setaddressopen] = useState(false);
  // const [twoFactorEnable, setTwoFactorEnable] = useState("disable")
  // const [secret, setSecret] = useState(null)
  // const [qrCode, setQrCode] = useState(null)
  // const [ena, setEna] = useState(false)
  // const [addr, setAddr] = useState({})
  // const secrets = useRef(null)
  // const tokenCheck = window.localStorage.getItem('Malfunction')
  // const handleLogincaptchaChange = (value) => {
  //   if (value) {
  //     setIsloginBot(true);
  //     setloginerr(null)
  //   } else {
  //     setIsloginBot(false);
  //   }
  // };

  // const handleRegistercaptchaChange = (value) => {
  //   if (value) {
  //     setIsregisterBot(true);
  //     setregistererr(null)
  //   } else {
  //     setIsregisterBot(false);
  //   }
  // };

  const handleOpen = () => {
    setcpassworderr("")
    setpassworderr("")
    setPhoneerr("")
    setemailerr("")
    setOpen(true);
    setOpens(false);
    setOpenss(false)
  }
  const handleClose = () => setOpen(false);


  const handleOpens = () => {
    setOpens(true);
    setOpen(false)
  }
  const handleCloses = () => {
    setcpassworderr("")
    setpassworderr("")
    setPhoneerr("")
    setemailerr("")
    setOpens(false)
  };

  const handleOpenss = () => {
    setOpenss(true);
    setOpen(false)
  }
  const handleClosess = () => setOpenss(false);


  // const handleOpensss = () => {
  //   setOpensss(true);
  //   setOpenss(false)
  // }
  // const handleClosesss = () => setOpensss(false);

  // const handleOpenssss = () => {
  //   setOpenssss(true);
  //   setOpensss(false)
  // }
  // const handleClosessss = () => setOpenssss(false);
  // const handleopenpayment = () => {
  //   setmodalpay(true)
  //   setName(true)
  //   console.log("helloe", modalpay)

  // }

  // const [expanded, setExpanded] = React.useState(false);

  // const handleChange = (panel) => (event, isExpanded) => {
  //   setExpanded(isExpanded ? panel : false);
  // };



  const [showPassword, setShowPassword] = React.useState(false);
  const [showPasswordCnf, setShowPasswordCnf] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowPasswordCnf = () => setShowPasswordCnf((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };


  const handleProfileOpen = () => {                   // Function to handle toggling profile open state
    // Toggle profile open state and close detail if open
    setProfileOpen(!profileOpen)
    setDetailOpen(false)
  }


  const handleProfileDetail = () => {               // Function to handle toggling detail open state
    // Toggle detail open state and close profile if open
    setDetailOpen(!detailOpen)
    setProfileOpen(false)

  }
  // const [value, setValue] = React.useState(0);

  // const profiletab = (event, newValue) => {
  //   setValue(newValue);
  // };
  // function verifynum() {
  //   setPhoneerr(null)
  //   let re = /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/;
  //   if (re.test(phone.current.value)) {
  //     setPhoneerr("")
  //   } else if (phone.current.value === "") {
  //     setPhoneerr("")
  //   } else {
  //     setPhoneerr("Invalid-Mobilenumber")
  //   }
  // }

  const lemailhandleChange = () => {
    const emailRegex = new RegExp(
      /^[A-Za-z0-9_!#$%&'*+\/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/,
      "gm"
    );
    const inputValue = lemail.current.value;

    if (inputValue.trim() === "") {
      setLemailerr("")
    } else if (!(emailRegex.test(inputValue))) {
      setLemailerr("Invalid Email")
    } else {
      setLemailerr("")
    }
  }

  const lpasswordchange = () => {
    const passwordregex = new RegExp(
      /(^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,16}$)+/,
      "gm"
    );
    const inputValue = lpswd.current.value;

    if (inputValue.trim() === "") {
      setLPswerr("")
    }
    // else if (!(passwordregex.test(inputValue))) {
    //   setLPswerr("*Password must be a minimum 8 characters & Maximum 16 characters.Eg: Abc@123")
    // } 
    else {
      setLPswerr("")
    }
  }

  const verifynum = () => {
    const reg = /^\d{6,17}$/;
    const inputValue = phone.current.value;
    if (inputValue === '') {
      setPhoneerr("")
    } else if (!(reg.test(inputValue))) {
      setPhoneerr("*Phone Number should be 6 to 17 digits")
    } else {
      setPhoneerr("")
    }

    // if (inputValue.trim() === "") {
    //   setPhoneerr("");
    // } else if (inputValue.length > 17) {
    //   setPhoneerr("*Phone Number must be 17 digits or less");
    // } else if (inputValue.length < 7) {
    //   setPhoneerr("*Phone Number must be 7 digits or more");
    // }
    // else if (!(reg.test(inputValue))) {
    //   setPhoneerr("*Only numbers, plus signs, and hyphens are allowed");
    // } else {
    //   setPhoneerr("");
    // }
  }
  // function verifyamount() {
  //   setamounterr(null)
  //   let re = /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/;
  //   console.log(re.test(paymentref.current.value))
  //   if (!re.test(paymentref.current.value)) {
  //     setamounterr("")
  //   } else if (paymentref.current.value === "") {
  //     setamounterr("")
  //   } else {
  //     setamounterr("Amount must be number")
  //   }
  // }

  function getPassword() {
    const passwordregex = new RegExp(
      /(^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,16}$)+/,
      "gm"
    );
    const inputValue = inpassref.current.value;

    if (inputValue.trim() === "") {
      setpassworderr("")
    } else if (!(passwordregex.test(inputValue))) {
      setpassworderr("*Password must be a minimum 8 characters & Maximum 16 characters.Eg: Abc@1234")
    } else {
      setpassworderr("")
    }
  }

  function confirmPassword() {
    if (inputconfrimRef.current.value !== inpassref.current.value) {
      setcpassworderr('Password do not match');
    }
    else if (inputconfrimRef === inpassref) {
      setcpassworderr('');
    } else {
      setcpassworderr('');
    }
  }

  function verifyemail() {
    setemailerr(null)
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(email.current.value)) {
      setMail("")
    } else if (email.current.value === "") {
      setMail("")
    } else {
      setMail("Invalid-Email-Format")
    }
  }


  const [password, setPassword] = React.useState(false);

  const handleClickPassword = () => setPassword((show) => !show);



  const logout = () => {
    // console.log("remove")
    window.localStorage.removeItem("iphephile")
    window.localStorage.removeItem("iphephileid")

    toast.success('Logged Out Successfully', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: "light",
    });


    setTimeout(() => {
      // window.location.reload();
      navigate(`${consts.route}/`)
    }, 1500);

    // console.log(`${consts.route}/`, "dsds")

  }

  // const classess = useStyles();
  // const [isActive, setIsActive] = useState(false);

  // const handleInputClick = () => {
  //   setIsActive(true);
  // };

  // const handleCloseClick = () => {
  //   setIsActive(false);
  // };


  // current time
  // Define a state variable named date and a function to update it named setDate

  const [date, setDate] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setDate(new Date());                // Update the date state variable with the current date and time
    }, 1000);                             // Update every second


    return () => clearInterval(interval);    // Clean up the interval by clearing it when the component unmounts or the dependency array changes
  }, []);                                     // Empty dependency array ensures the effect runs only once after the initial render




  // Define a variable named formattedDate to store the formatted date string
  const formattedDate = date.toLocaleString('en-US', {
    month: 'numeric', // Display the month as a number (e.g., 1 for January)
    day: 'numeric', // Display the day of the month as a number
    year: 'numeric', // Display the year as a number
    hour: 'numeric', // Display the hour in 12-hour format
    minute: 'numeric', // Display the minute
    hour12: true // Use 12-hour time format (true for AM/PM)
  });


  // hide details

  const [isHidden, setIsHidden] = useState(false);  // Define a state variable named isHidden and a function to update it named setIsHidden


  const toggleDetails = () => {
    // Toggle the value of the isHidden state variable

    setIsHidden(!isHidden);
  };


  const register = async () => {
    try {
      // console.log("name", name.current.value)
      let reg = /^[0-9]+$/;
      let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      const passwordregex = new RegExp(
        /(^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,16}$)+/,
        "gm"
      );

      if (!email.current.value) {
        setemailerr("*Email is Required")
      }
      else if (!(re.test(email.current.value))) {
        setemailerr("*Invalid Email")
      }
      else if (!name.current.value) {
        setusernameerr("*Name is Required")
      }
      else if (!phone.current.value) {
        setPhoneerr("*Phone No is Required")
      }
      else if (phone.current.value.length < 7) {
        setPhoneerr("*Phone Number should be 7 digits or more")
      }
      else if (phone.current.value.length > 17) {
        setPhoneerr("*Phone Number should be 17 digits or less")
      }
      else if (!(reg.test(phone.current.value))) {
        setPhoneerr("*Invalid Phone Number")
      }
      else if (!address.current.value) {
        setaddresserr("*Address is Required")
      }
      else if (!city.current.value) {
        setcityerr("*city is Required")
      }
      else if (!country.current.value) {
        setcountryerr("*country is Required")
      }
      else if (!inpassref.current.value) {
        setpassworderr("*Password is Required")
      }
      else if (!(passwordregex.test(inpassref.current.value))) {
        setpassworderr("*Password must be a minimum 8 characters & Maximum 16 characters.Eg: Abc@1234")
      }
      else if (!inputconfrimRef.current.value) {
        setcpassworderr("*Confirm Password is Required")
      }
      else if (inpassref.current.value !== inputconfrimRef.current.value) {
        setcpassworderr("*Password and Confirm Password must be Equal")
      }
      else {
        // setIslogin(true)
        const registerdata = {
          user_name: name.current.value,
          email: email.current.value,
          phone: phone.current.value,
          address: address.current.value,
          city: city.current.value,
          country: country.current.value,
          password: inpassref.current.value,
          confirmpassword: inputconfrimRef.current.value
        }
        const { data } = await Axios.post('/auth/register', registerdata)
        // console.log(data, "import")

        if (data?.result) {
          setOpens(false)
          handleCloses()
          setTimeout(() => {
            navigate('/lobby')
            // setIslogin(false)

            toast.success('Your user account has been successfully registered. A verification email has been sent to your registered email address. Please verify your email address')
          }, 500);

        } if (data?.success === false) {
          setTimeout(() => {
            navigate('/')
            toast.error(data?.message)
            // setIslogin(false)

          }, 500);
        }
      }
    } catch (error) {
      // setIslogin(false)

      // console.log(error, "err")
      if (error?.response?.data?.message === "EMAIL ALREADY EXISTS") {
        setemailerr(error?.response?.data?.message)
        if (error?.response?.data?.message === "name invalid format") {
          setusernameerr(error?.response?.data?.message)
        }
      } else {
        setpassworderr(error?.response?.data?.message)
      }
    }

  }


  // const loginTo = async() => {
  //   try {

  //     if (!lemail.current.value) {
  //       setLemailerr("*Email is Required")
  //       }
  //       else if (!lpswd.current.value) {
  //         setLPswerr("*Password is required")
  //       }
  //       else {
  //         // setIslogin(true)
  //           // console.log("Login")
  //           const loginData = {
  //               email: lemail.current.value,
  //               password: lpswd.current.value,
  //           }

  //           const { data } = await Axios.post("/users/login",loginData).then((res)=>{
  //               console.log(res,'reswww')

  //               if (res?.data?.success) {
  //                   // setIslogin(true)
  //                   setProfileData(res?.data?.result)
  //                    toast.success('LoggedIn Successfully')

  //                   // console.log(res?.data?.result?.token)

  //                setTimeout(() => {
  //                   // setIslogin(false)
  //                }, 5000);

  //                 window.localStorage.setItem(
  //                   "iphephile",
  //                   res?.data?.result?.token
  //                 )
  //                 window.localStorage.setItem(
  //                   "iphephileid",
  //                   res?.data?.result?.user?._id
  //                 )
  //                 // settingtoken(res?.data?.result?.token)
  //                 // navigate('/')
  //                 setOpen(false) 
  //                 // window.location.reload();
  //               }

  //               else{
  //                 // setIslogin(false)
  //                 console.log('iscomeorrrr')

  //                   if (
  //                       res?.data?.message === "Please Enter a Valid Email Address" || res?.data?.message === "BLOCKED_USER" ||
  //                       res?.data?.message === "Please Verify Email" || res?.data?.message === "USER DOES NOT EXIST"

  //                     ) {
  //                       setLemailerr(res?.data?.message);
  //                     }
  //                     if (res?.data?.message === "Incorrect password" || res?.data?.message === "PASSWORD TOO SHORT MIN 5") {
  //                       setLPswerr(res?.data?.message);
  //                     } else {
  //                       setLemailerr(res?.data?.message);
  //                     }
  //               }
  //           }).catch((err)=>{
  //               console.log(err,'errreeeee')
  //               // setIslogin(false)

  //               if (
  //                   err?.response?.data?.message === "Please Enter a Valid Email Address" || err?.response?.data?.message === "BLOCKED_USER" ||
  //                   err?.response?.data?.message === "Please Verify Email" || err?.response?.data?.message === "USER DOES NOT EXIST"

  //                 ) {
  //                   setLemailerr(err?.response?.data?.message);
  //                 }
  //                 if (err?.response?.data?.message === "Wrong Password" || err?.response?.data?.message === "PASSWORD TOO SHORT MIN 5") {
  //                   setLPswerr(err?.response?.data?.message);
  //                 } else {
  //                   setLemailerr(err?.response?.data?.message);
  //                 }
  //           })

  //         }
  //       } catch (error) {
  //         // setIslogin(false)

  //         if (
  //           error?.response?.data?.message === "Invalid Email" ||
  //           error?.response?.data?.message === "Please Verify Email" || error?.response?.data?.message === "USER DOES NOT EXIST"

  //         ) {
  //           setemailerr(error?.response?.data?.message);
  //         }
  //         if (error?.response?.data?.message === "Incorrect password" || error?.response?.data?.message === "PASSWORD TOO SHORT MIN 5") {
  //           setpassworderr(error?.response?.data?.message);
  //         } else {
  //           setemailerr(error?.response?.data?.message);
  //         }
  //       }
  // }

  const loginTo = async () => {

    try {
      if (lemail.current.value === "") {
        setLemailerr("Please Enter Email")
      } else if (lpswd.current.value === "") {
        setLPswerr("Please Enter Password")
      } else {
        const { data } = await Axios.post(`/users/login`, {
          email: lemail.current.value,
          password: lpswd.current.value
        })
        // console.log(data, "datas")
        if (data.success === false) {
          // window.localStorage.setItem('Brouhaha', data.result.token)
          //window.localStorage.setItem('Brou', data.result.user?._id)

          toast.error(data.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
        else if (data.success === true && data.result.user.f2A_Status === "false") {
          window.localStorage.setItem('iphephile', data.result.token)
          window.localStorage.setItem('iphephileid', data.result.user?._id)
          toast.success(data.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "light",
          });

          setTimeout(() => {
            navigate(`${consts.route}/`)
          }, 2000)
        } else {
          window.localStorage.setItem('iphephile', data.result.token)
          window.localStorage.setItem('iphephileid', data.result.user?._id)
          toast.success(data.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "light",
          });

          setTimeout(() => {
            navigate(`${consts.route}/two-factor-auth`)
            handleClose()
          }, 2000)
        }
      }
    } catch ({ response }) {
      // console.log(response, "err")
      if (response?.data?.message === 'Please Verify Email') {
        // console.log("if1")
        setLemailerr(response?.data?.message)
      }
      else if (response?.data?.message === "Invalid Email") {
        // console.log("if2")
        setLemailerr("Admin Blocked You")
      }
      else if (response?.data?.message === "BLOCKED_USER") {
        // console.log("if2")
        setLemailerr("Admin Blocked You")
      }
      else if (response?.data?.message === 'Wrong Password') {
        // console.log("if3")
        setLPswerr(response?.data?.message)
      }
      else if (response?.data?.message === "Please Enter Valid Email") {
        setLemailerr(response?.data?.message)
      }
    }

  }


  const checks2fa = async () => {
    try {
      const data = await Axios.get(`/auth/check2fa`, {
        headers: {
          "Authorization": token
        }
      })
      // console.log(data, "datsss")
      if (data) {
        setAmount(parseFloat(data?.data?.result?.amount).toFixed(3))
        setusers(data?.data?.result)
      }

    } catch (error) {
      console.log("error", error)

    }
  }


  useEffect(() => {
    if (token != null) {
      checks2fa()
    }

  }, [token])

  const forgot = async () => {

    try {
      if (forgotmail.current.value === "") {
        setmailerr("Please Enter Email")
      } else {
        await Axios.post(`/auth/forgot`, {
          email: forgotmail.current.value,
        })
          .then((response) => {

            if (response?.data?.success == false) {
              toast.error(response?.data?.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
              })
              window.localStorage.removeItem("iphephile")
              window.localStorage.removeItem("iphephileid")
              setTimeout(() => {
                navigate(`${consts.route}/`)
              }, 2000);
            }
            else {
              navigate('/emailotp', { state: { email: forgotmail.current.value } })
            }

          })
          .catch((error) => {
            // console.log(error.response.data, "messa")
            if (error.response.data.message === "EMAIL_IS_NOT_VALID") {
              toast.error('Invalid Email', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            } else if (error.response.data.message === "Invalid Email") {
              toast.error('Invalid Email', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            }

          });
        // if (data.success === true && data.result.user.f2A_Status === "false") {
        //   window.localStorage.setItem('Brouhaha', data.result.token)
        //   toast.success(data.message, {
        //     position: "top-right",
        //     autoClose: 3000,
        //     hideProgressBar: false,
        //     closeOnClick: true,
        //     pauseOnHover: false,
        //     draggable: true,
        //     progress: undefined,
        //     theme: "light",
        //   });

        //   setTimeout(() => {
        //     navigate(`${consts.route}/`)
        //   }, 2000)
        // } else {
        //   window.localStorage.setItem('Brouhaha', data.result.token)
        //   toast.success(data.message, {
        //     position: "top-right",
        //     autoClose: 3000,
        //     hideProgressBar: false,
        //     closeOnClick: true,
        //     pauseOnHover: false,
        //     draggable: true,
        //     progress: undefined,
        //     theme: "light",
        //   });

        //   setTimeout(() => {
        //     navigate(`${consts.route}/two-factor-auth`)
        //   }, 2000)
        // }
      }
    } catch ({ response }) {
      // console.log(response, "err")
      if (response?.data?.message === 'Please Verify Email') {
        // console.log("if1")
        setmailerr(response?.data?.message)
      } else if (response?.data?.message === "Invalid Email") {
        // console.log("if2")
        setmailerr(response?.data?.message)
      } else if (response?.data?.message === "Please Enter Valid Email") {
        setmailerr(response?.data?.message)
      }
    }

  }




  return (


    <>
      {/* <Toaster
        position="top-center"
        reverseOrder={false}
      /> */}
      {/* <ToastContainer /> */}

      <Box sx={{ display: 'flex' }} className='main-header'>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Grid container spacing={0} className='header-container'>

            <Grid item xs={12} sm={12} md={12} lg={6} xl={6} className='left-mobile'>
              <div className='logo-part-outer'>
                <div className='menu-block-header'>
                  <ul className='menu-block-list'>
                    {/* <li><NavLink to="" className={({ isActive }) => (isActive ? 'active' : 'h-nav')}>Home</NavLink></li> */}
                    <li><NavLink to="/mybets" className={({ isActive }) => (isActive ? 'active' : 'h-nav')}> Bets History</NavLink></li>
                    {/* <li><NavLink to="" className={({ isActive }) => (isActive ? 'active' : 'h-nav')}>Offers</NavLink></li> */}
                    <li><NavLink to="/history" className={({ isActive }) => (isActive ? 'active' : 'h-nav')}>Transaction History</NavLink></li>
                    <li><NavLink to="/mySurvey" className={({ isActive }) => (isActive ? 'active' : 'h-nav')}>Survey History</NavLink></li>

                  </ul>
                </div>

                <div className="mobile-bottom-menu">
                  <div className="mobile-bottom-menu-inner">
                    <ul className="mobile-bottom-menu-list">
                      <li><a href=""><img src={home} alt="home" /> <span>Home</span></a></li>
                      <li><a id="wallet-id-mobile" href="#"> <img src={mybet} alt="mybet" /> <span>Bets</span></a></li>
                      <li><a id="live-id-mobile" href="#"> <img src={Offers} alt="Offers" /> <span>Offers</span></a></li>
                      <li className='funds mobile' onClick={(() => { navigate('/deposit') })} ><a><img src={addfunds} alt="Offers" /><span>Add funds</span></a></li>
                    </ul>
                  </div>
                </div>

              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={6} xl={6} className='right-mobile'>
              <div className='login-register-block'>
                {/* <ul className='login-register-block-list'>
                  {token ?
                    <>
                      <li className='user-after-login' onClick={handleProfileDetail}><img src={profile} className='img-fluid' /></li>
                      <li className='user-after-login' onClick={handleProfileOpen} ><img src={notification} className='img-fluid' /></li>
                      <li className='login-register-button'><Link>Add funds</Link></li>
                      <li className='login-register-button' onClick={(() => { navigate('/deposit') })} ><Link>Add funds</Link></li>
                    </>
                    :
                    <>
                      <li className='login-register-button'><Link onClick={handleOpen}>Log in</Link></li>
                      <li className='login-register-button'><Link onClick={handleOpens}>Join Now</Link></li>
                    </>}





                </ul> */}

                <div className='user-login-part'>
                  {profileOpen &&
                    <div className='user-profile-dropdwn profile-drop-bg'>
                      <div className='notification-pop-up'>
                        <div className='notify-center'>
                          <div className='notify-head'>
                            <p>Notification Center</p>
                          </div>
                          <div className='after-login'>

                            <ul>
                              <li className='user-after-login'><RefreshIcon /></li>
                            </ul>

                          </div>
                        </div>
                        <div className='notiication-details'>
                          <p>You haven’t received any new notifications</p>
                        </div>
                      </div>
                    </div>
                  }
                </div>

                <div className='user-login-part'>
                  {detailOpen &&
                    <div className='user-profile-dropdwn'>

                      <div class='top-user-block'>
                        <div className='avatar-pro'>
                          <div className='profile-img'><Avatar alt="Remy Sharp" src={ava} /></div>
                          {/* {console.log(users, "profileData")} */}
                          <div className='user-info'>
                            {users?.user_name ? users?.user_name : ""}
                          </div>
                        </div>
                        <div>Last login:{formattedDate}</div>
                        {/* <div className='btn-1'>
                            <Button onClick={(() => { navigate('/twofactor') })}>2FA</Button>
                          </div> */}

                      </div>

                      <div className='mx-hgt'>
                        <div className='mid-user-block'>
                          <div className='USR-COIN'>
                            ${amount ? amount : '$ 0.00'}
                            <div className='inr'>
                              PLAYABLE BALANCE
                            </div>
                          </div>
                          <div className='USR-COIN'>
                            {eye ? '$ --' : '$ 0.00'}
                            <div className='inr'>
                              BONUS
                            </div>
                          </div>
                          <div className='remove-icon' onClick={(() => { setEye(!eye) })}>{eye ? <RemoveRedEyeIcon /> : <VisibilityOffIcon />}</div>
                        </div>


                        <div className="details-container">

                          <div className={`details ${isHidden ? 'hidden' : ''}`}>

                            <div className='btm-user-btn'>
                              <div>
                                Sportsbook/Casino deposits
                              </div>
                              <div>
                                $ 0.00
                              </div>
                            </div>

                            <div className='btm-user-btn'>
                              <div>
                                Sportsbook bonuses
                              </div>
                              <div>
                                $ 0.00
                              </div>
                            </div>

                            <div className='btm-user-btn'>
                              <div>
                                Shared winnings
                              </div>
                              <div>
                                $ 0.00
                              </div>
                            </div>

                            <div className='btm-user-btn'>
                              <div>
                                Playable balance
                              </div>
                              <div>
                                ${amount}
                              </div>
                            </div>

                          </div>
                          <div className='btm-user-btn' onClick={toggleDetails}>
                            {isHidden ? 'Show details' : 'Hide details'}
                          </div>
                        </div>
                        <div className='prf-btn'>
                          <div className='btn-1'>
                            <Button onClick={(() => { navigate('/deposit') })}>Add funds</Button>
                          </div>
                          <div className='btn-2'>
                            <Button onClick={(() => { navigate('/withdraw') })}>Withdraw funds</Button>
                          </div>
                        </div>
                        <div className='more-inf'>
                          <div className='more-inf-inner'><Link to='/editprofile'> Edit Profile</Link>
                          </div>
                          <div className='more-inf-inner'>
                            Account overview
                          </div>
                          <div className='more-inf-inner'><Link to='/twofac'>Two-Factor Authentication</Link></div>
                          <div className='more-inf-inner'>
                            Transaction history
                          </div>
                          <div className='more-inf-inner'>
                            Tax Information
                          </div>
                          <div className='more-inf-inner'>
                            Responsible gaming
                          </div>
                          <div className='more-inf-inner'>
                            Preferences
                          </div>
                          <div className='more-inf-inner'>
                            Help & Support
                          </div>
                        </div>



                      </div>
                      <div className='header-secound'>
                        <ul className='gaming'>
                          <li className='login-register-button'><Link onClick={logout}>Log Out <LogoutIcon /></Link></li>
                        </ul>
                      </div>

                      {/* <div className='bottom-user-block'>
                        <Box sx={{ width: '100%' }}>
                          <Box className={classes.profiletabhead}>
                            <Tabs value={value} onChange={profiletab} aria-label="basic tabs example" className='all-tab profile-tab'>
                              <Tab label="Account" className="tab-section" {...a11yProps(0)} />
                              <Tab label="2FA" className="tab-section" {...a11yProps(1)} />
                              <Tab label="My Offers" className="tab-section" {...a11yProps(2)} />
                              <Tab label="Alerts" className="tab-section" {...a11yProps(3)} />
                            </Tabs>
                          </Box>

                          <CustomTabPanel value={value} index={0}>

                            <Box sx={{ flexGrow: 1 }} className='profile-tab-content'>
                              <Grid container spacing={0} style={{ "flexWrap": "wrap" }}>

                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                  <div className="account-sec">

                                    <div className="popup-icons">
                                      <div className="icons"><img src={messageicon} alt="message-icon" /></div>
                                      <h4>Messages</h4>
                                    </div>
                                    <div className="popup-icons">
                                      <div className="icons"><img src={gamblingicon} alt="gambling-icon" /></div>
                                      <h4>Gambling</h4>
                                    </div>
                                    <div className="popup-icons">
                                      <div className="icons"><img src={accounticon} alt="MyActivity-icon" /></div>
                                      <h4>My Activity</h4>
                                    </div>
                                  </div>
                                </Grid>

                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                  <div className="account-sec">



                                    <div className="popup-icons">
                                      <div className="icons"><img src={gamblingicon} alt="History-icon" /></div>
                                      <h4>History</h4>
                                    </div>
                                    <div className="popup-icons">
                                      <div className="icons"><img src={breakicon} alt="break-icon" /></div>
                                      <h4>Take a Break</h4>
                                    </div>
                                  </div>
                                </Grid>



                              </Grid>
                            </Box>

                          </CustomTabPanel>

                          <CustomTabPanel value={value} index={1}>

                            <div className='two-fa-outer'>
                              <div class="two-fa-status">
                                <div>
                                  <h3>2FA Status</h3>
                                  <span class="disabled-block">
                                    <span class="disabled-dot"></span>Disabled</span>
                                </div>
                                <div class="enable-button">

                                  <button type="button" class="btn btn-success" fdprocessedid="1dk1v">Enable</button> :
                                  <button type="button" class="btn btn-success" fdprocessedid="1dk1v"> Disable</button>
                                </div>
                              </div>


                              {ena === true ?
                                <div className="qrSection">
                                  Security Code:
                                  <div className='Security-Codes'>
                                    <span>{secret}</span>

                                  </div>
                                  <div className='Security-Code qr-code'>
                                    <span>QR Code:</span>
                                    <div className='qr-code-code'>
                                      <QRCodeSVG value={qrCode} />
                                    </div>
                                    <div className='verif-2fa-digit'>
                                      <div className='verif-2fa-digit-inner'>
                                        <input type="text" ref={secrets} />
                                        <Button className="verifyButton">Verify</Button>
                                      </div>
                                      {emailerr !== null ? <div style={{ textAlign: "center", color: "red" }} >{emailerr}</div> : <></>}
                                    </div>
                                  </div>
                                </div> :
                                <div>
                                </div>
                              }
                            </div>
                          </CustomTabPanel>

                          <CustomTabPanel value={value} index={2}>
                            <div class="offers">
                              <h4>Open Account Offer - get up to RS. 4000 in Bet Credits</h4>
                              <p>We’ll give you 15% of your qualifying deposit in Bet Credits (up to RS. 4000
                                ) when you meet the qualifying settled bet requirement on your qualifying
                                deposit (capped at RS. 26666.67 ).</p>
                              <p>Claim By 26 Jun 17:03. Min deposit RS. 400 . Min odds, bet and payment method
                                restrictions apply. Returns exclude Bet Credits stake.<span>Time limits and T&amp;C’s apply</span>
                              </p>
                            </div>
                          </CustomTabPanel>

                          <CustomTabPanel value={value} index={3}>
                            <div className='radio-btn'>
                              <FormControlLabel
                                control={<IOSSwitch sx={{ m: 1 }} defaultChecked />}
                                label="Winning Bets" className='inner-radio'
                              />
                            </div>

                            <div className='radio-btn'>
                              <FormControlLabel
                                control={<IOSSwitch sx={{ m: 1 }} defaultChecked />}
                                label="Auto Cash Out" className='inner-radio'
                              />
                            </div>

                            <div className='radio-btn'>
                              <FormControlLabel
                                control={<IOSSwitch sx={{ m: 1 }} defaultChecked />}
                                label="Free Bets" className='inner-radio'
                              />
                            </div>
                          </CustomTabPanel>

                          <div className="popup-link">
                            <div className='header-secound last'>
                              <ul className='gaming'>
                                <li>Responsible Gaming</li>
                                <li>Help</li>
                              </ul>
                            </div>

                            <div className='header-secound'>
                              <ul className='gaming'>
                                <li className='login-register-button'><Link to="" onClick={logout}>Log Out <LogoutIcon /></Link></li>
                              </ul>
                            </div>
                          </div>

                        </Box>
                      </div> */}

                    </div>
                  }
                </div>

              </div>
              {/* {modalpay &&
                <Modal open={modalpay}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description">
                  <Box sx={style} className={classes.popupboxcls} >
                    <Grid container spacing={0}>

                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Item className={classes.dashboarbodycls}>
                          <div className='popup ' >
                            <CloseIcon onClick={() => setmodalpay(false)} />
                          </div>
                          <h6 className='heading-txt'>Payament</h6>

                          <Grid container spacing={0} className='inner-popup'>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                              <Item className={classes.dashboarbodycls}>
                                <div className='user-icon binance'><img src={binancecoin} alt='user'/></div>
                                <div className='formtxt'>
                                  <TextField
                                    id="outlined-multiline-flexible"
                                    name='amount'
                                    label="Amount"
                                    type="number"

                                    inputRef={paymentref}
                                    multiline="multiline" className='text-field' />
                                  {amounterr !== null ? <h3 className='h3'>{amounterr}</h3> : <></>}
                                </div>
                                <div className='text-binance formtxt'>
                                  <Button className='binance-btn' >Payment</Button>
                                </div>
                              </Item>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={2} xl={2}>
                              <Item className={classes.dashboarbodycls}>
                              </Item>
                            </Grid>
                          </Grid>
                        </Item>
                      </Grid>
                    </Grid>
                  </Box>
                </Modal>
              } */}


              {open &&
                <Modal open={open}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description">
                  <Box sx={style} className={classes.loginpopupboxcls} >
                    <Grid container spacing={0}>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Item className={classes.dashboarbodycls}>
                          <div className='popup ' >
                            <CloseIcon onClick={handleClose} />
                          </div>
                          <h6 className='heading-txt'>Login to SSA Account</h6>
                          <Grid container spacing={0} className='inner-popup'>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                              <Item className={classes.dashboarbodycls}>
                                {/* <div className='user-icon binance'><img src={binancecoin} alt='user'/></div> */}
                                <div className='formtxt'>
                                  <TextField
                                    id="outlined-multiline-flexible"
                                    // label="Email"
                                    placeholder='Email'
                                    inputRef={lemail}
                                    onChange={() => lemailhandleChange()}
                                    multiline="multiline" className='text-field' />
                                  {lemailerr !== null ? <h3 className='h3'>{lemailerr}</h3> : <></>}
                                </div>
                                <FormControl sx={{ m: 1, width: '100%' }} variant="outlined" className={classes.passwordeye}>

                                  <OutlinedInput
                                    id="outlined-adornment-password"
                                    type={showPassword ? 'text' : 'password'}
                                    onChange={() => lpasswordchange()}
                                    inputRef={lpswd}
                                    endAdornment={
                                      <InputAdornment position="end" className='eyeicon'>
                                        <IconButton
                                          aria-label="toggle password visibility"
                                          onClick={handleClickShowPassword}
                                          onMouseDown={handleMouseDownPassword}
                                          edge="end"
                                        >
                                          {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                      </InputAdornment>
                                    }
                                    // label="Password"
                                    placeholder='Password'

                                  />
                                </FormControl>
                                {lpswerr !== null ? <h3 className='h3'>{lpswerr}</h3> : <></>}
                                <div className='text-binance formtxt'>
                                  <Button className='binance-btn' onClick={loginTo}>Log in</Button>
                                </div>

                                <div className='forget'>
                                  <Button onClick={handleOpenss}>Forgot Your password?</Button>
                                </div>
                                <div className='forget'>
                                  <span>Don't have a account? </span><Button onClick={handleOpens}>Sign up now</Button>
                                </div>
                              </Item>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={2} xl={2}>
                              <Item className={classes.dashboarbodycls}>
                              </Item>
                            </Grid>
                          </Grid>
                        </Item>
                      </Grid>
                    </Grid>
                  </Box>
                </Modal>
              }

              {opens &&
                <Modal open={opens}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description" >
                  <Box sx={style} className={classes.registerpopupboxcls}>
                    <div className='popup ' >
                      <CloseIcon onClick={handleCloses} />
                    </div>
                    <Grid container spacing={0}>

                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Item className={classes.dashboarbodycls}>
                          <h6 className='heading-txt'>Create a SSA Account</h6>
                          <Grid container spacing={0} className='inner-popup'>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                              <Item className={classes.dashboarbodycls}>

                                <div className='formtxt'>
                                  <TextField
                                    id="outlined-multiline-flexible"
                                    // label="Email"
                                    placeholder='Email'
                                    multiline="multiline" className='text-field'
                                    inputRef={email}
                                    onChange={verifyemail}
                                  />
                                  <h3 className='h3' >{mail}</h3>
                                  {emailerr !== null ? <h3 className='h3'>{emailerr}</h3> : <></>}
                                </div>

                                <div className='formtxt'>
                                  <TextField
                                    id="outlined-multiline-flexible"
                                    // label="Name"
                                    placeholder='Name'
                                    multiline="multiline" className='text-field'
                                    inputRef={name}
                                    onChange={() => { setusernameerr(null) }}
                                  />
                                  {usernameerr ? <div className='h3'>{usernameerr}</div> : <></>}
                                </div>

                                <div className='formtxt'>
                                  <TextField
                                    id="outlined-multiline-flexible"
                                    // label="Phone Number"
                                    type="number"
                                    placeholder='Phone Number'
                                    multiline="multiline"
                                    className='text-field'
                                    inputRef={phone}
                                    onChange={() => verifynum()}
                                  />
                                  {phoneerr ? <div className='h3'>{phoneerr}</div> : <></>}
                                </div>

                                <div className='formtxt'>
                                  <TextField
                                    id="outlined-multiline-flexible"
                                    // label="Phone Number"
                                    placeholder='Address'
                                    multiline="multiline" className='text-field'
                                    inputRef={address}
                                    onChange={() => { setaddresserr(null) }}
                                  />
                                  {addresserr ? <div className='h3'>{addresserr}</div> : <></>}
                                </div>

                                <div className='formtxt'>
                                  <TextField
                                    id="outlined-multiline-flexible"
                                    // label="Phone Number"
                                    placeholder='city'
                                    multiline="multiline" className='text-field'
                                    inputRef={city}
                                    onChange={() => { setcityerr(null) }}
                                  />
                                  {cityerr ? <div className='h3'>{cityerr}</div> : <></>}
                                </div>

                                <div className='formtxt'>
                                  <TextField
                                    id="outlined-multiline-flexible"
                                    // label="Phone Number"
                                    placeholder='country'
                                    multiline="multiline" className='text-field'
                                    inputRef={country}
                                    onChange={() => { setcountryerr(null) }}
                                  />
                                  {countryerr ? <div className='h3'>{countryerr}</div> : <></>}
                                </div>

                                <FormControl sx={{ m: 1, width: '100%' }} variant="outlined" className={classes.passwordeye}>
                                  <OutlinedInput
                                    id="outlined-adornment-password"
                                    type={showPassword ? 'text' : 'password'}
                                    inputRef={inpassref}
                                    onChange={getPassword}
                                    placeholder='Password'
                                    endAdornment={
                                      <InputAdornment position="end" className='eyeicon'>
                                        <IconButton
                                          aria-label="toggle password visibility"
                                          onClick={handleClickShowPassword}
                                          onMouseDown={handleMouseDownPassword}
                                          edge="end"
                                        >
                                          {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                      </InputAdornment>
                                    }
                                  />
                                </FormControl>
                                {passworderr !== null ? <h3 className='h3'>{passworderr}</h3> : <></>}

                                <FormControl sx={{ m: 1, width: '100%' }} variant="outlined" className={classes.passwordeye}>
                                  <OutlinedInput
                                    id="outlined-adornment-password"
                                    type={password ? 'text' : 'password'}
                                    inputRef={inputconfrimRef}
                                    onChange={confirmPassword}
                                    placeholder='ConfirmPassword'
                                    endAdornment={
                                      <InputAdornment position="end" className='eyeicon'>
                                        <IconButton
                                          aria-label="toggle password visibility"
                                          onClick={handleClickPassword}
                                          onMouseDown={handleMouseDownPassword}
                                          edge="end"
                                        >
                                          {password ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                      </InputAdornment>
                                    }
                                  />
                                </FormControl>
                                {/* {confirmPass ? <h3 className='h3'> {confirmPass} </h3> : <></>} */}
                                {cpassworderr !== null ? <h3 className='h3'>{cpassworderr}</h3> : <></>}

                                <div className='text-binance formtxt'>
                                  <Button className='binance-btn' onClick={() => { register() }}  >Create account</Button>
                                </div>

                                <p>By creating an account you are agreeing to SSA  betting Terms of Use
                                  and Privacy Policy and to be updated about SSA  betting products,
                                  news, and promotions. Users must be 18+ (21+ in MA) to play Fantasy
                                  and 21+ to place bets on Sportsbook.</p>
                                <div className='forget'>
                                  <span>Already have a SSA  betting account?</span> <Button onClick={handleOpen}>Sign in</Button>
                                </div>
                              </Item>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={2} xl={2}>
                              <Item className={classes.dashboarbodycls}>
                              </Item>
                            </Grid>
                          </Grid>
                        </Item>
                      </Grid>
                    </Grid>
                  </Box>
                </Modal>
              }


              {openss &&
                <Modal open={openss}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description" >
                  <Box sx={style} className={classes.popupboxcls}>
                    <Grid container spacing={0}>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Item className={classes.dashboarbodycls}>
                          <div className='popup' >
                            <CloseIcon onClick={handleClosess} />
                          </div>
                          <h6 className='heading-txt'>Forgot Your password?</h6>
                          <Grid container spacing={0} className='inner-popup'>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                              <Item className={classes.dashboarbodycls}>
                                {/* <div className='user-icon binance'><img src={binancecoin} alt='user'/></div> */}
                                <div className='formtxt'>
                                  <TextField
                                    id="outlined-multiline-flexible"
                                    // label="Email"
                                    placeholder='Email'
                                    multiline="multiline" className='text-field'
                                    inputRef={forgotmail} onChange={() => { setmailerr(null) }} />
                                </div>
                                {mailerr !== null ? <div style={{ textAlign: "center", color: "red" }} >{mailerr}</div> : <></>}

                                <div className='text-binance formtxt'>
                                  <Button className='binance-btn' onClick={forgot}>Send Verification Code</Button>
                                </div>
                                <div className='forget'>
                                  <span>I remainder my password </span><Button onClick={handleOpen}>Login now </Button>
                                </div>

                                {/* <div className='forget'>
                    <span>Don't have a FanDuel account? </span><Link to="" onClick={handleOpenss}> Sign up now</Link>
                  </div> */}

                              </Item>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={2} xl={2}>
                              <Item className={classes.dashboarbodycls}>
                              </Item>
                            </Grid>
                          </Grid>
                        </Item>
                      </Grid>
                    </Grid>
                  </Box>
                </Modal>
              }


              {/* {opensss &&
                <Modal open={opensss}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description" >
                  <Box sx={style} className={classes.popupboxcls}>
                    <Grid container spacing={0}>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Item className={classes.dashboarbodycls}>
                          <div className='popup' >
                            <CloseIcon onClick={handleClosesss} />
                          </div>
                          <h6 className='heading-txt'>Enter OTP code </h6>
                          <p className='para'>bhar......25@gmail.com</p>
                          <Grid container spacing={0} className='inner-popup'>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                              <Item className={classes.dashboarbodycls}>
                                <div className='user-icon binance'><img src={binancecoin} alt='user'/></div>
                                <div className='formtxt'>
                                  <TextField
                                    id="outlined-multiline-flexible"
                                    label="Code"
                                    multiline="multiline" className='text-field' />
                                </div>
                                <div className='forget resend'>
                                  <Link to="">Resend Code</Link>
                                </div>
                                <div className='text-binance formtxt'>
                                  <Button className='binance-btn'>Verify Code</Button>
                                </div>
                                <div className='forget'>
                                  <span>I remainder my password </span><Link to="" onClick={handleOpen}>Login now </Link>
                                </div>
                              </Item>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={2} xl={2}>
                              <Item className={classes.dashboarbodycls}>
                              </Item>
                            </Grid>
                          </Grid>
                        </Item>
                      </Grid>
                    </Grid>
                  </Box>
                </Modal>
              } */}


              {/* {openssss &&
                <Modal open={openssss}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description" >
                  <Box sx={style} className={classes.popupboxcls}>
                    <Grid container spacing={0}>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Item className={classes.dashboarbodycls}>
                          <div className='popup' >
                            <CloseIcon onClick={handleClosessss} />
                          </div>
                          <h6 className='heading-txt'>2Factor Authentication </h6>
                          <Grid container spacing={0} className='inner-popup'>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                              <Item className={classes.dashboarbodycls}>
                                <div className='user-icon binance'><img src={binancecoin} alt='user'/></div>
                                <div className='formtxt'>
                                  <TextField
                                    id="outlined-multiline-flexible"
                                    label="Security Code"
                                    multiline="multiline" className='text-field' />
                                </div>

                                <div className='forget resend'>
                                  <Link to="">Resend Code</Link>
                                </div>

                                <div className='text-binance formtxt'>
                                  <Button className='binance-btn'>Enter your 6 Digit code</Button>
                                </div>


                                <div className='forget'>
                                  <span>I remainder my password </span><Button onClick={handleOpen}>Login now </Button>
                                </div>

                                <div className='forget'>
                    <span>Don't have a FanDuel account? </span><Link to="" onClick={handleOpens}> Sign up now</Link>
                  </div>

                              </Item>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={2} xl={2}>
                              <Item className={classes.dashboarbodycls}>
                              </Item>
                            </Grid>
                          </Grid>
                        </Item>
                      </Grid>
                    </Grid>
                  </Box>
                </Modal>
              } */}



            </Grid>

          </Grid>
        </Grid >
      </Box >
    </>
  )
}

export default Header
