import React, { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles';
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import winbet from '../../images/win-bet.png'
import empty from '../../images/empty.png'
import Button from '@mui/material/Button';
import minus from '../../images/minus.png'
import layer from '../../images/layer.png'
import livenow from '../../images/Livenow.png'
import FormControl, { formControlClasses } from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import AttachMoneyRoundedIcon from '@mui/icons-material/AttachMoneyRounded';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import profile1 from '../../images/surname-profile1.png'
import profile2 from '../../images/surname-profile2.png'
import vs from '../../images/vs.png'
import Axios from "../../Axios";
import { RiseLoader } from 'react-spinners';

const useStyles = makeStyles({
    root: {
        background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
        border: 0,
        borderRadius: 3,
        boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
        color: 'white',
        height: 48,
        padding: '0 30px',
    },
    flexcls: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center'
    }
});


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#008aff",
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

function createData(sno, title) {
    return { sno, title };
}

const rows = [
    createData('01', "1vs1"),
    createData('02', "1vs1"),
    createData('03', "1vs1"),
    createData('04', "1vs1"),
];



const RightSideBar = ({ data }) => {



    const [betDetails, setBetDetails] = useState([])
    const [betresultDetails, setBetresultDetails] = useState([]);
    const value = data.tabvalue
    const [pendingCount, setPendingCount] = useState(0)
    const [resultCount, setResultCount] = useState(0)
    const token = window.localStorage.getItem('iphephile')


    // const location = useLocation();


    const betType = async (value) => {
        if (value == 0) {
            return 'all'
        }
        else if (value == 1) {
            return 'onevsone'
        }
        else if (value == 2) {
            return 'djvsdj'
        }
        else if (value == 3) {
            return 'vote'
        }
        else if (value == 4) {
            return 'lineup'
        }
    }

    const getUserBets = async () => {
        const bettingType = await betType(value)
        let payload = {
            type: "pending",
            bettingType: bettingType
        }
        try {
            const response = await Axios.post('/users/getuserbet', payload, {
                headers: {
                    Authorization: window.localStorage.getItem('iphephile')
                }
            })
            if (response.data.success == true) {
                setPendingCount(response.data.result.length)
                setBetDetails(response.data.result)
            }
            else {
                setPendingCount(0)
                setBetDetails([])
            }
        } catch (error) {
            setBetDetails([])
        }
    }


    const getUserBetsResult = async () => {
        try {
            const response = await Axios.post('/users/getuserbet', { type: "" }, {
                headers: {
                    Authorization: window.localStorage.getItem('iphephile')
                }
            })
            if (response.data.success == true) {
                setResultCount(response.data.result.length)
                setBetresultDetails(response.data.result)
            }
        } catch (error) {
            setResultCount(0)
            setBetresultDetails([])
        }
    }

    let loaction = useLocation()

    let dystate = loaction.state

    // if (data.message == 'Betting Submitted Successfully') {
    //     getUserBets()
    // }
    useEffect(() => {
        getUserBets()
        getUserBetsResult()
    }, [data.message])

    useEffect(() => {
        if (token != null) {
            getUserBets()
            getUserBetsResult()
        }

    }, [value])

    // useEffect(() => {
    //     if (token != null) {
    //         getUserBetsResult()
    //     }
    // }, [token])



    const classes = useStyles();

    return (
        <div className='sidebar Right-Sidebar betslip-side'>
            <Box sx={{ flexGrow: 1 }} id="bet-slip-id-list-outer">
                <Grid spacing={0} id="bet-slip-id-list-mid">
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} id="bet-slip-id-list-inner" style={{ "display": "none" }}>
                        <div className='betslip-head-with-count'>
                            <div className='count-betslip'><span>0</span></div>
                            <div className='head-betslip'>Betslip</div>
                        </div>
                        <div className='betslip-head-with-counts' style={{ "marginTop": "20px" }}>

                            <div className='betslip-list'>

                                <div className='empty'><img src={empty} alt="empty" /></div>
                                <h3>Betslip empty</h3>
                                <p>Add selections to place bet</p>
                            </div>

                        </div>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} id="bet-slip-id-list-inner">
                        <div className='betslip-head-with-count'>
                            <div className='count-betslip'><span>{pendingCount}</span></div>
                            <div className='head-betslip'>Pending History</div>
                        </div>
                        <div className='betslip-head-with-counts' style={{ "marginTop": "20px" }}>

                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell>S.No</StyledTableCell>
                                            <StyledTableCell align="center">Title</StyledTableCell>
                                            <StyledTableCell align="center">Name</StyledTableCell>
                                            <StyledTableCell align="center">Bet Amount</StyledTableCell>
                                            <StyledTableCell align="center">Result</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            betDetails?.length > 0 ?
                                                (
                                                    <>
                                                        {betDetails?.map((row, ind) =>
                                                            <StyledTableRow key={row.sno1}>
                                                                <StyledTableCell scope="row">
                                                                    {ind + 1}
                                                                </StyledTableCell>
                                                                <StyledTableCell align="center">{row?.title}</StyledTableCell>
                                                                <StyledTableCell align="center">{row?.player_details?.name}</StyledTableCell>
                                                                <StyledTableCell align="center">$ {row?.betamount}</StyledTableCell>
                                                                <StyledTableCell align="center">{row?.result}</StyledTableCell>
                                                            </StyledTableRow>
                                                        )}
                                                    </>
                                                )
                                                : (
                                                    // <p>No data found</p>
                                                    <StyledTableRow >
                                                        <StyledTableCell colspan={5} align="center">No data found</StyledTableCell>
                                                    </StyledTableRow>
                                                )
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>

                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} id="bet-slip-id-list-inner" sx={{ mt: 4 }}>
                        <div className='betslip-head-with-count'>
                            <div className='count-betslip'><span>{resultCount}</span></div>
                            <div className='head-betslip'>History</div>
                        </div>
                        <div className='betslip-head-with-counts' style={{ "marginTop": "20px", marginBottom: '70px' }}>

                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell>S.No</StyledTableCell>
                                            <StyledTableCell align="center">Title</StyledTableCell>
                                            <StyledTableCell align="center">Name</StyledTableCell>
                                            <StyledTableCell align="center">Bet Amount</StyledTableCell>
                                            <StyledTableCell align="center">Result</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            betresultDetails?.length > 0 ?
                                                (
                                                    <>
                                                        {betresultDetails?.map((row, ind) =>
                                                            <StyledTableRow key={row.sno1}>
                                                                <StyledTableCell scope="row">
                                                                    {ind + 1}
                                                                </StyledTableCell>
                                                                <StyledTableCell align="center">{row.title}</StyledTableCell>
                                                                <StyledTableCell align="center">{row.player_details.name}</StyledTableCell>
                                                                <StyledTableCell align="center">$ {row.betamount}</StyledTableCell>
                                                                <StyledTableCell align="center">{row.result}</StyledTableCell>
                                                            </StyledTableRow>
                                                        )}
                                                    </>
                                                )
                                                : (
                                                    // <p>No data found</p>
                                                    <StyledTableRow >
                                                        <StyledTableCell colspan={5} align="center">No data found</StyledTableCell>
                                                    </StyledTableRow>
                                                )
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>


                        </div>
                    </Grid>

                </Grid>

            </Box>

        </div>
    )
}

export default RightSideBar
