import React, { useState, useRef, useEffect } from 'react'
import './optresend.css'
import Header from "../Header/Header";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from "react-bootstrap/esm/Container";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Link, useNavigate, useLocation } from 'react-router-dom'
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import sound from '../../images/soundlogo.png'

import Axios from '../../Axios'
import { ToastContainer, toast } from 'react-toastify';
import { NavItem } from 'react-bootstrap';
import consts from '../../Constant';
import CircularProgress from '@mui/material/CircularProgress';
import ClipLoader from "react-spinners/ClipLoader";
import { Height } from '@mui/icons-material';


const Otpresend = () => {

    const navigate = useNavigate()
    const location = useLocation();
    const email = useRef(null)
    const password = useRef(null)
    const [loader, setLoader] = useState(false)

    const [emailerr, setemailerr] = useState(null)
    const [passerr, setpasserr] = useState(null)

    const [showPassword, setshowPassword] = useState(false)
    const [counter, setCounter] = useState(60);
    const handleClickShowPassword = () => setshowPassword((show) => !show);

    const [verifystatus, setverifystatus] = useState("empty")

    // console.log(location?.state.email, "location")

    const onLogin = async () => {
        try {
            setLoader(true)
            const { data } = await Axios.post(`/auth/forgot`, {
                email: location?.state?.email
            })
            // console.log(data, "data")
            if (data?.success === true) {
                setLoader(false)
                toast.success(data.message, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
            // if (data.success === true) {
            //     window.localStorage.setItem('Brouhaha', data.result.token)
            //     toast.success(data.message, {
            //         position: "top-right",
            //         autoClose: 3000,
            //         hideProgressBar: false,
            //         closeOnClick: true,
            //         pauseOnHover: false,
            //         draggable: true,
            //         progress: undefined,
            //         theme: "light",
            //     });

            //     setTimeout(() => {
            //         navigate('/')
            //     }, 2000)
            // }

        } catch ({ response }) {
            // console.log(response, "err")
            if (response?.data?.message === "Already Verified") {
                setLoader(false)
                toast.error(response?.data?.message, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
            // if (response?.data?.message === 'Please Verify Email') {
            //     setemailerr(response?.data?.message)
            // } else if (response?.data?.message === "Invalid Email") {
            //     setemailerr(response?.data?.message)
            // } else if (response?.data?.message === 'Wrong Password') {
            //     setpasserr(response?.data?.message)
            // }
        }
    }

    useEffect(() => {
        counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
    }, [counter]);

    return (
        <div className="login-page-main1">
            {/* <Header/> */}
            <ToastContainer />
            <div className='login-section1'>
                <Container>
                    <div className='login-bg1 otpbg'>
                        <div className='login-inside1 reset-inner'>
                            <img src={sound} style={{ width: "200px", Height: '200px', margin: '15px 0px' }} />
                            <div className='success'>
                                <span className="login-heading-style1">SUCCESS</span>
                                <i class="fa fa-check-circle tick-green" style={{ color: '#126e51' }} aria-hidden="true"></i>
                            </div>
                            <div>
                                <p>
                                    Please click on the verification link sent to your Email ID to
                                    change your account Password. If you cannot find the email, please check your spam and trash folders to ensure that the email hasn’t been
                                    marked as spam or accidentally deleted.
                                </p>
                                {
                                    counter == 0 ? (
                                        loader ?
                                            <Button type="submit" onClick={() => { onLogin() }} className='email-cancel-btn'>
                                                {/* <CircularProgress animation="border" role="status" sx={{ color: '#fff' }} /> */}
                                                <ClipLoader color={'#00008B'} />
                                            </Button> :
                                            <Button type="submit" onClick={() => { onLogin() }} className='email-cancel-btn'>Resend Email</Button>
                                    ) : (
                                        <Button type="submit" onClick={() => { onLogin() }} className='email-cancel-btn resend' disabled>Resend Email</Button>
                                    )
                                }
                                <div >Resend Email : {counter} sec</div>
                                {/* <Button type="submit" onClick={() => { onLogin() }} className='email-cancel-btn'>Resend Email</Button> */}
                                <Button type="submit" onClick={() => { navigate(`${consts.route}/`) }} className='email-cancel-btn'>Cancel</Button>


                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
}

export default Otpresend
