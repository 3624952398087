import React, { useState, useEffect } from 'react'
import './Deposit.css'
import { Grid } from '@mui/material'
import TopHeader from '../Header/TopHeader'
import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';
import { useRef } from 'react';
import { QRCodeSVG } from "qrcode.react";
import Menuclose from '../../images/Menuclose.png'
import { Link } from 'react-router-dom'
import Axios from '../../Axios';
import { ToastContainer, toast } from 'react-toastify';
import ClipLoader from "react-spinners/ClipLoader";
import { useNavigate } from 'react-router-dom'
import consts from '../../Constant';
import { toBeChecked } from '@testing-library/jest-dom/matchers';


function TwoFac() {
    const [age, setAge] = React.useState(10);

    const tokenCheck = window.localStorage.getItem('iphephile')

    const handleChange = (event) => {
        setAge(event.target.value);
    };

    const [age2, setAge2] = React.useState(10);

    const handleChange2 = (event) => {
        setAge2(event.target.value);
    };

    const [inputValue, setInputValue] = useState('GIEHIHFD54FWEFUHYW5F4CQWGD');
    const [copy, setCopy] = useState(false)
    const navigate = useNavigate();


    const copyText = () => {
        navigator.clipboard.writeText(inputValue);
        setCopy(true)
    };

    const [down, setDown] = useState()


    const handleDownload = () => {
        setDown(true)
        setTimeout(() => {
            setDown(false);
        }, 7000)

    }
    const [amounterr, setAmounterr] = useState("")
    // const [amount, setAmount] = useState()

    // const handleAmount = (no) => {

    //     setAmounterr("")
    //     setAmount(no)

    // }

    const [secret, setSecret] = useState(null)
    const secrets = useRef(null)
    const [qrCode, setQrCode] = useState(null)
    const [emailerr, setemailerr] = useState(null)
    const [ena, setEna] = useState(false)
    const [twoFactorEnable, setTwoFactorEnable] = useState("disable")
    const [secret1, setSecret1] = useState('')
    const [twofastatus, setTwofastatus] = useState(false)
    const [isload, setLoad] = useState(false)
    const [isloader, setLoader] = useState(false)


    const enable = async () => {
        setLoad(!false)
        if (tokenCheck !== null) {
            const data = await Axios.get(`/auth/check2fa`, {
                headers: {
                    "Authorization": tokenCheck
                }
            })

            // setAmount( parseFloat(data?.data?.result?.amount).toFixed(3))
            // setusers(data?.data?.result)


            if (data?.data?.result?.status === true) {
                setTwoFactorEnable("enable")
                setLoad(false)
            } else {
                setTwoFactorEnable("disable")
                setEna(false)
                setLoad(false)
            }
        } else {
            setTwoFactorEnable("enable")
            setLoad(false)
        }
    }


    useEffect(() => {
        if (tokenCheck != null) {
            enable()
        }
    }, [tokenCheck])


    const handleEnable = async () => {
        setEna(true)
        const { data } = await Axios.post(`/auth/generate2fa`, {}, {
            headers: {
                "Authorization": window.localStorage.getItem('iphephile')
            }
        })
        if (data.success == false && data.message == 'Admin Blocked You') {
            toast.error(data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
            })
            window.localStorage.removeItem("iphephile")
            window.localStorage.removeItem("iphephileid")
            setTimeout(() => {
                navigate(`${consts.route}/`)
            }, 2000);
        }
        else if (data.success == false) {
            toast.error(data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
            })
        }
        else if (data.success == true) {
            setSecret(data.result.secret)
            setQrCode(data.result.otpauth_url)
        }

    }

    const handleDisable = async () => {
        setLoader(true)
        const { data } = await Axios.post(`/auth/disable2fa`, {}, {
            headers: {
                "Authorization": window.localStorage.getItem('iphephile')
            }
        })
        if (data?.success === true) {
            setLoader(false)
            setEna(false)
            setTwoFactorEnable("disable")
            toast.success(data?.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setTimeout(() => {
                navigate('/lobby')
            }, 2000);
        } else {
            setLoader(false)
            toast.error(data?.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }

    }


    const verify2FA = async () => {
        try {
            if (secret1 === '') {
                setemailerr('Please Enter otp')

            }
            else {
                setLoader(true)
                const { data } = await Axios.post(`/auth/verify2fa`, {
                    secret: secret1
                }, {
                    headers: {
                        "Authorization": tokenCheck
                    }
                }
                )
                // console.log(data, "datass")


                if (data?.success == true) {
                    setLoader(false)
                    setEna(false)
                    // setSecret1()
                    setTwoFactorEnable("enable")
                    setSecret1()
                    toast.success(data?.message, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    setTimeout(() => {
                        navigate('/lobby');
                    }, 3000);
                }
                else {
                    setLoader(false)

                    toast.error(data?.message, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }

            }

        } catch ({ response }) {
            console.log(response, "error");
            toast.error(response?.data?.message)

        }

        finally {
            setSecret1('')
        }
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault(); // Prevent default form submission behavior
        }
    }

    return (
        <div className='deposit'>
            <ToastContainer />
            <Grid container spacing={0} className='depo-cont'>
                <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                    <TopHeader />
                </Grid>
                <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Link to="/lobby" className="backmenu">
                        <img src={Menuclose} alt="close" />
                    </Link>
                    <div className='deposit-comp'>
                        Two-Factor Authentication
                    </div>
                </Grid>
                <Grid xs={12} sm={12} md={12} lg={12} xl={5}>
                    <div className='depo-form'>
                        <div className='select-cry'>
                            2FA Status
                        </div>
                        {/* {twoFactorEnable === "disable" ?
                            <span className='disabled-block'><span className='disabled-dot'></span>Disabled</span>

                            : <span className='enabled-block'><span className='enabled-dot'></span>Enabled</span>
                        } */}
                        {twoFactorEnable === "disable" ?
                            <div className='enable-button'><Button onClick={() => handleEnable()} variant="success">{isload ? <ClipLoader size={20} color={'white'} /> : "Enable"}</Button></div>
                            :
                            isloader ?
                                <div className='disable-button'>
                                    <Button style={{ backgroundColor: 'Red', color: 'white' }}>
                                        <ClipLoader color={'#00008B'} />
                                    </Button></div>
                                :
                                <div className='disable-button'>
                                    <Button style={{ backgroundColor: 'Red', color: 'white' }} onClick={() => handleDisable()} variant="danger">
                                        {/* {isload ? <ClipLoader size={20} color={'white'} /> : "Disable"} */} Disable
                                    </Button></div>
                        }


                        {/* <Button onClick={copyText}>{copy ? 'Copied!' : 'Copy'}</Button> */}
                        {ena === true ?
                            <div>

                                <div>
                                    <div className='Security-Code'>
                                        <span>Security Code:</span>
                                        {secret}
                                    </div>
                                    <div className='Security-Code qr-code'>
                                        <span>QR Code:</span>
                                        <div className='qr-code-code'>
                                            <QRCodeSVG value={qrCode} />
                                        </div>
                                        <div className='verif-2fa-digit'>
                                            <div className='verif-2fa-digit-inner'>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='formtxt'>
                                    <TextField
                                        id="outlined-multiline-flexible"
                                        placeholder="Code"
                                        multiline="multiline" className='text-field' value={secret1}
                                        onKeyDown={(e) => handleKeyDown(e)}
                                        onChange={(e) => { setSecret1(e.target.value); setemailerr(''); }} />
                                </div>
                                {emailerr !== null ? <div style={{ textAlign: "center", color: "red" }} >{emailerr}</div> : <></>}

                                <div className='text-binance formtxt'>
                                    {
                                        isloader ?
                                            <Button className='binance-btn'> <ClipLoader color={'#00008B'} /></Button>
                                            :
                                            <Button className='binance-btn' onClick={() => { verify2FA() }}>Verify</Button>
                                    }

                                </div>
                            </div>

                            : <></>}


                        {/* <div className='qr-down-div'>
                            {down ? <img src={downs} style={{ width: "50px", height: "50px" }} /> : <button onClick={handleDownload}>DOWNLOAD</button>}
                        </div> */}

                    </div>
                </Grid>

            </Grid>
        </div>
    )
}

export default TwoFac
