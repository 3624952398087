import React, { useState } from 'react'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Header from '../Header/Header';
import TopHeader from '../Header/TopHeader';
// import './Landing.css'
// import lanbg from '../../images/landing-title-bg.png'
import { Button } from '@mui/material';

import Footer from '../Footer/Footer';
import coming from '../../images/coming.gif'


const Coming = () => {


    return (
        <div className='landing-page'>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={0}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <TopHeader />
                    </Grid>
                </Grid>


                <Grid container spacing={0} sx={{ justifyContent: 'center' }} className='image-coming'>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>

                        <div className='coming-soon'>
                            <img src={coming} className='coming' />
                            Coming Soon!!
                        </div>
                    </Grid>
                </Grid>
            </Box>

        </div >
    )
}

export default Coming
