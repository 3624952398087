import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Footer from "../Footer/Footer";
import TopHeader from "../Header/TopHeader";
import img1 from "../../images/research-img.png";
import img2 from "../../images/research-img-2.png";
import "../Research/Research.css";
import musicvs from "../../images/music-vs-music.png";
import eagle from "../../images/eagle-music.png";
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import pteam from "../../images/p-team.png";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.min.css";
import "owl.carousel/dist/assets/owl.theme.default.min.css";

const Research = () => {

   //Owl Carousel Settings
   const options = {
    margin: 30,
    items: 4,
    responsiveClass: true,
    nav: true,
    autoplay: true,
    autoplayTimeout: 4000,
    dots: false,
    loop:false,
    smartSpeed: 2000,
    responsive: {
        0: {
            items: 1,
        },
        400: {
            items: 1,
        },
        700: {
            items: 2,
        },
        1200: {
            items: 4,
        },
    },
};

  return (
    <div className="research-page">
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={0}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TopHeader />
          </Grid>
        </Grid>
        <Grid container spacing={0} className="body-container">
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Grid container className="sound-system-highlight">
              <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                <div className="research-left-block">
                  <div className="research-img-block">
                    <img src={img1} alt="research-img" />
                  </div>
                  <div className="research-content-block">
                    <span className="research-content-title">
                      sound system highlight
                    </span>
                    <h2>Player Prop Bets for Tuesday 2/27/24</h2>
                    <div className="research-betting">
                      <span className="research-betting-player">
                        Annie Nader
                      </span>
                      •<span className="research-betting-hourago">17h</span>
                    </div>
                    <p>
                      Derrick White has a great matchup on tap against the
                      Sixers. Which other NBA props look interesting for
                      Tuesday?
                    </p>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                <div className="research-center-block">
                  <div className="research-sub-block">
                    <div className="research-sub-block-left">
                      <span className="research-music">Music</span>
                      <h3>
                        Betting Podcast: Formula 1 Bahrain Grand Prix Betting
                        Preview
                      </h3>
                      <div className="research-betting">
                        <span className="research-betting-player">
                          Annie Nader
                        </span>
                        •<span className="research-betting-hourago">17h</span>
                      </div>
                    </div>
                    <div className="research-sub-block-right">
                      <img src={img2} alt="music-img" />
                    </div>
                  </div>
                  <div className="research-sub-block">
                    <div className="research-sub-block-left">
                      <span className="research-music">Music</span>
                      <h3>
                        Betting Picks for Tuesday 2/27/24: Will the Knicks Clip
                        the Pelicans?
                      </h3>
                      <div className="research-betting">
                        <span className="research-betting-player">
                          Riley Thomas
                        </span>
                        •<span className="research-betting-hourago">17h</span>
                      </div>
                    </div>
                    <div className="research-sub-block-right">
                      <img src={img2} alt="music-img" />
                    </div>
                  </div>
                  <div className="research-sub-block">
                    <div className="research-sub-block-left">
                      <span className="research-music">Music</span>
                      <h3>Run It Back: Tuesday, February 27th, 2024</h3>
                      <div className="research-betting">
                        <span className="research-betting-player">
                          Annie Nader
                        </span>
                        •<span className="research-betting-hourago">17h</span>
                      </div>
                    </div>
                    <div className="research-sub-block-right">
                      <img src={img2} alt="music-img" />
                    </div>
                  </div>
                  <div className="research-sub-block">
                    <div className="research-sub-block-left">
                      <span className="research-music">Music</span>
                      <h3>
                        College Basketball Betting Picks for Tuesday 2/27/24
                      </h3>
                      <div className="research-betting">
                        <span className="research-betting-player">
                          Riley Thomas
                        </span>
                        •<span className="research-betting-hourago">17h</span>
                      </div>
                    </div>
                    <div className="research-sub-block-right">
                      <img src={img2} alt="music-img" />
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                <div className="research-right-block">
                  <div className="research-sub-block">
                    <div className="research-sub-block-left">
                      <span className="research-music">Music</span>
                      <h3>
                        Betting Podcast: Formula 1 Bahrain Grand Prix Betting
                        Preview
                      </h3>
                      <div className="research-betting">
                        <span className="research-betting-player">
                          Annie Nader
                        </span>
                        •<span className="research-betting-hourago">17h</span>
                      </div>
                    </div>
                    <div className="research-sub-block-right">
                      <img src={img2} alt="music-img" />
                    </div>
                  </div>
                  <div className="research-sub-block">
                    <div className="research-sub-block-left">
                      <span className="research-music">Music</span>
                      <h3>
                        Betting Podcast: Formula 1 Bahrain Grand Prix Betting
                        Preview
                      </h3>
                      <div className="research-betting">
                        <span className="research-betting-player">
                          Annie Nader
                        </span>
                        •<span className="research-betting-hourago">17h</span>
                      </div>
                    </div>
                    <div className="research-sub-block-right">
                      <img src={img2} alt="music-img" />
                    </div>
                  </div>
                  <div className="research-sub-block">
                    <div className="research-sub-block-left">
                      <span className="research-music">Music</span>
                      <h3>
                        Betting Podcast: Formula 1 Bahrain Grand Prix Betting
                        Preview
                      </h3>
                      <div className="research-betting">
                        <span className="research-betting-player">
                          Annie Nader
                        </span>
                        •<span className="research-betting-hourago">17h</span>
                      </div>
                    </div>
                    <div className="research-sub-block-right">
                      <img src={img2} alt="music-img" />
                    </div>
                  </div>
                  <div className="research-sub-block">
                    <div className="research-sub-block-left">
                      <span className="research-music">Music</span>
                      <h3>
                        Betting Podcast: Formula 1 Bahrain Grand Prix Betting
                        Preview
                      </h3>
                      <div className="research-betting">
                        <span className="research-betting-player">
                          Annie Nader
                        </span>
                        •<span className="research-betting-hourago">17h</span>
                      </div>
                    </div>
                    <div className="research-sub-block-right">
                      <img src={img2} alt="music-img" />
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      
        <Grid container spacing={0} >
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className="upcoming-music body-container">
              <h1>Upcoming music</h1>
            </div>

           

            <div className="upcoming-music-carousel-outer">
            <OwlCarousel className="owl-theme owl-carousel nft" {...options}>
            <div className="upcoming-music-carousel-card item">
                <div className="upcoming-music-carousel-img">
                      <img src={musicvs} alt="music-img" />
                </div>
                <Box>
                  <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <div className="gaming-country">
                      <div><img src={eagle} alt="music-img" /></div>
                    <div>NO</div> 
                    </div>
                    <p>
                    34-24
                    </p>
                    <div className="gaming-score"><span className="grey">-6</span><span className="royal-blue blue-rate">-240</span></div>
                  </Stack>
                </Box>
                <Divider className="atdivider"  textAlign="left">@</Divider>
                <Box>
                  <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <div className="gaming-country">
                      <div><img src={pteam} alt="music-img" /></div>
                    <div>IND</div> 
                    </div>
                    <p>
                    33-26
                    </p>
                    <div className="gaming-score"><span className="grey">-6</span><span className="royal-blue blue-rate">-240</span></div>
                  </Stack>
                </Box>
             
            </div>

            <div className="upcoming-music-carousel-card item">
            <div className="upcoming-music-carousel-img">
                  <img src={musicvs} alt="music-img" />
            </div>
                <Box>
                  <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <div className="gaming-country">
                      <div><img src={eagle} alt="music-img" /></div>
                    <div>NO</div> 
                    </div>
                    <p>
                    34-24
                    </p>
                    <div className="gaming-score"><span className="grey">-6</span><span className="royal-blue blue-rate">-240</span></div>
                  </Stack>
                </Box>
                <Divider className="atdivider"  textAlign="left">@</Divider>
                <Box>
                  <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <div className="gaming-country">
                      <div><img src={pteam} alt="music-img" /></div>
                    <div>IND</div> 
                    </div>
                    <p>
                    33-26
                    </p>
                    <div className="gaming-score"><span className="grey">-6</span><span className="royal-blue blue-rate">-240</span></div>
                  </Stack>
                </Box>
             
            </div>

            <div className="upcoming-music-carousel-card item">
            <div className="upcoming-music-carousel-img">
                  <img src={musicvs} alt="music-img" />
            </div>
                <Box>
                  <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <div className="gaming-country">
                      <div><img src={eagle} alt="music-img" /></div>
                    <div>NO</div> 
                    </div>
                    <p>
                    34-24
                    </p>
                    <div className="gaming-score"><span className="grey">-6</span><span className="royal-blue blue-rate">-240</span></div>
                  </Stack>
                </Box>
                <Divider className="atdivider"  textAlign="left">@</Divider>
                <Box>
                  <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <div className="gaming-country">
                      <div><img src={pteam} alt="music-img" /></div>
                    <div>IND</div> 
                    </div>
                    <p>
                    33-26
                    </p>
                    <div className="gaming-score"><span className="grey">-6</span><span className="royal-blue blue-rate">-240</span></div>
                  </Stack>
                </Box>
             
            </div>

            <div className="upcoming-music-carousel-card item">
            <div className="upcoming-music-carousel-img">
                  <img src={musicvs} alt="music-img" />
            </div>
                <Box>
                  <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <div className="gaming-country">
                      <div><img src={eagle} alt="music-img" /></div>
                    <div>NO</div> 
                    </div>
                    <p>
                    34-24
                    </p>
                    <div className="gaming-score"><span className="grey">-6</span><span className="royal-blue blue-rate">-240</span></div>
                  </Stack>
                </Box>
                <Divider className="atdivider"  textAlign="left">@</Divider>
                <Box>
                  <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <div className="gaming-country">
                      <div><img src={pteam} alt="music-img" /></div>
                    <div>IND</div> 
                    </div>
                    <p>
                    33-26
                    </p>
                    <div className="gaming-score"><span className="grey">-6</span><span className="royal-blue blue-rate">-240</span></div>
                  </Stack>
                </Box>
             
            </div>

            <div className="upcoming-music-carousel-card item">
            <div className="upcoming-music-carousel-img">
                  <img src={musicvs} alt="music-img" />
            </div>
                <Box>
                  <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <div className="gaming-country">
                      <div><img src={eagle} alt="music-img" /></div>
                    <div>NO</div> 
                    </div>
                    <p>
                    34-24
                    </p>
                    <div className="gaming-score"><span className="grey">-6</span><span className="royal-blue blue-rate">-240</span></div>
                  </Stack>
                </Box>
                <Divider className="atdivider"  textAlign="left">@</Divider>
                <Box>
                  <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <div className="gaming-country">
                      <div><img src={pteam} alt="music-img" /></div>
                    <div>IND</div> 
                    </div>
                    <p>
                    33-26
                    </p>
                    <div className="gaming-score"><span className="grey">-6</span><span className="royal-blue blue-rate">-240</span></div>
                  </Stack>
                </Box>
             
            </div>
            </OwlCarousel>



            </div>
          </Grid>
        </Grid>
        <Grid container spacing={0}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Footer />
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default Research;
