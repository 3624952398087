import React, { useState, useRef, useEffect } from 'react'
import { useParams, useLocation } from 'react-router-dom'
import loadingimg from '../../images/success.gif'
// import loadingimg from '../../images/loading-new.gif'
import failed from '../../images/failed.gif'
// import failed from '../../images/failed-new.gif'
import './Loading.css'
import { ToastContainer, toast } from 'react-toastify';
import consts from '../../Constant';
import { useNavigate } from 'react-router-dom';
import Confetti from 'react-confetti';



const Loading = () => {
    const params = useParams()
    const location = useLocation()
    const navigate = useNavigate()

    useEffect(() => {
        // console.log(params, location, "param")
        if (location.search === "?payment=success") {
            setPaymentStatus(true)
            // toast.success('Amount Deposited Successfully', {
            //     position: "top-right",
            //     autoClose: 5000,
            //     hideProgressBar: false,
            //     closeOnClick: true,
            //     pauseOnHover: false,
            //     draggable: true,
            //     progress: undefined,
            //     theme: "light",
            //   });
            setTimeout(() => {
                navigate(`${consts.route}/`)
            }, 5000)

        } else if (location.search === "?payment=failure") {
            setPaymentStatus(false)
            // toast.success('Payment Failed', {
            //     position: "top-right",
            //     autoClose: 5000,
            //     hideProgressBar: false,
            //     closeOnClick: true,
            //     pauseOnHover: false,
            //     draggable: true,
            //     progress: undefined,
            //     theme: "light",
            //   });
            setTimeout(() => {
                navigate(`${consts.route}/`)
            }, 3000)



        }
    }, [])

    const [paymentStatus, setPaymentStatus] = useState(false);
    // console.log(paymentStatus, 'paymentStatus');

    const [shouldShowConfetti, setShouldShowConfetti] = useState(true);



    return (


        <div className='loader-page birthday-wisher'>
            <ToastContainer />
            {paymentStatus ?
                <div className='loader-page-inner'>
                    <div className='loading-img'><img src={loadingimg} alt="loading" /></div>
                    <div className='loading-caption'>
                        <p >Deposit request being Processed,Waiting For Admin Approval !</p>
                        {shouldShowConfetti && <Confetti />}
                    </div>
                </div>
                :
                <div className='loader-page-inner  payment-failed-cls'>
                    <div className='loading-img'><img src={failed} alt="loading" /></div>
                    <div className='loading-caption'>
                        <h6>Payment Failed!</h6>
                    </div>
                </div>
                // <div className='loader-page-inner payment-failed-cls'>
                //     <div className='loading-img'><img src={failed} alt="loading" /></div>
                //     <div className='loading-caption'>
                //         <h6>Payment Failed</h6>
                //     </div>
                // </div>
            }
        </div>
    )
}

export default Loading
