import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import '../Lobby/lobby.css'
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import './Survey.css'
import nobets from "../../images/nobets.png"
import { useLocation } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import Axios from '../../Axios';
import consts from '../../Constant';
import CircularProgress from '@mui/material/CircularProgress';
import ava from '../../images/tv-background.png'
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import CancelTwoToneIcon from '@mui/icons-material/CancelTwoTone';
import Modal from '@mui/material/Modal';
// import { useNavigate, useLocation } from 'react-router-dom';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import nodata from '../../images/thankyou.gif'
import { ToastContainer, toast } from 'react-toastify';
import ClipLoader from "react-spinners/ClipLoader";
import logo from "../../images/favicon.png"
import Chip from '@mui/material/Chip';
import StadiumIcon from '@mui/icons-material/Stadium';
import VolumeUpOutlinedIcon from '@mui/icons-material/VolumeUpOutlined';
import Checkbox from '@mui/material/Checkbox';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import timerImg from '../../images/icons8-timer.gif'
import FormLabel from "@mui/material/FormLabel";


function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function CircularProgressWithLabel(props) {
    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress variant="determinate" {...props} />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography variant="caption" component="div" color="text.secondary">
                    {`${Math.round(props.value)}%`}
                </Typography>
            </Box>
        </Box>
    );
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    bgcolor: '#fff',
    // border: '2px solid #000',
    borderRadius: '10px',
    boxShadow: 24,
    p: 4,
    '@media(max-width:575.98px)': {
        width: '80%'
    },

};


function Survey(props) {

    const [value, setValue] = useState(0);
    const [survey, setSurvey] = useState(false)

    const [values, setValues] = useState()
    const [data, setData] = useState([])
    const [loader, setLoader] = useState(false)
    const [answer, setAnswer] = useState([])
    // console.log(answer, 'answer');
    const [answererr, setAnswererr] = useState('')
    const [surveyDetails, setSurveyDetails] = useState([])
    const [radio, setRadio] = React.useState('');

    const [days, setDays] = useState(0);
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);
    const [index, setIndex] = useState()
    const [time, setTime] = useState([]);

    const [isload, setLoad] = useState(false)


    const TimeDuration = async () => {
        for (let i = 0; i < surveyDetails.length; i++) {
            if (surveyDetails[i].status == 0) {
                const eventDate = surveyDetails[i]?.end_time
                const times = Date.parse(new Date(eventDate)) - Date.now();
                let obj = {
                    id: surveyDetails[i]._id,
                    time: times
                }
                setTime(prevTime => {
                    const existingIndex = prevTime.findIndex(a => a.id === surveyDetails[i]._id);
                    if (existingIndex >= 0) {
                        const updatedTimer = [...prevTime];
                        updatedTimer[existingIndex] = obj;
                        return updatedTimer;
                    } else {
                        return [...prevTime, obj];
                    }
                });
            }
        }
    }


    const navigate = useNavigate()

    const handleOpenSurvey = (data) => {
        // console.log(data, 'popup');
        setSurvey(true)
        setData(data)
    }
    const handleCloseSurbey = () => setSurvey(false)


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handelRadiochange = (event) => {
        setRadio(event.target.value)
    }
    // console.log(radio, 'targetchecked1');

    // const handleValueChange = async (index, answers, id) => {
    //     // console.log(index, answers, id, 'value');
    //     // answer.map(x => x.id == id ?setAnswer([...answer, data]):  console.log(x, 'id'));
    //     var data = {
    //         id: id,
    //         answers: answers
    //     }
    //     console.log(data, answer.length, 'data');
    //     if (answer.length > 0) {
    //         for (let i = 0; i < answer.length; i++) {
    //             console.log(answer[i].id, id, 'change');
    //             if (answer[i].id == id) {
    //                 // console.log('if');
    //                 const myNextList = [...answer];
    //                 const artwork = myNextList.find(
    //                     a => a.id === id
    //                 );
    //                 artwork.answers = answers;
    //                 setAnswer(myNextList);
    //             }
    //             else {
    //                 console.log('else');
    //                 setAnswer(answer => [...answer, data]);
    //             }
    //         }
    //     }
    //     else {
    //         console.log('length else');
    //         setAnswer(answer => [...answer, data]);
    //     }
    // }

    const handleValueChange = async (index, answers, id) => {

        var data = {
            id: id,
            answers: answers
        };

        // Check if answer with the same id already exists
        const existingIndex = answer.findIndex(a => a.id === id);
        // console.log(existingIndex, 'existingindex');
        if (existingIndex >= 0) {
            // Update existing answer
            const updatedAnswers = [...answer];
            updatedAnswers[existingIndex] = data;
            setAnswer(updatedAnswers);
            setAnswererr('')
        } else {
            // Add new answer
            setAnswer([...answer, data]);
            setAnswererr('')
        }
    };

    // console.log(answer, 'answer');

    const getSurvey = async () => {
        setLoad(true)
        try {
            const response = await Axios.post('/auth/getSurvey', { type: 'survey' }, {
                headers: {
                    "Authorization": window.localStorage.getItem('iphephile')
                }
            })
            // console.log(response, 'surveyres');
            if (response?.data?.success) {
                setSurveyDetails(response?.data?.result)
                setLoad(false)
            }
            else {
                setSurveyDetails([])
                setLoad(false)
            }
        } catch (error) {
            console.log(error, 'err');
            setLoad(false)
        }
    }

    useEffect(() => {
        // if (window.localStorage.getItem('iphephile')) {
        getSurvey()
        // }
    }, [])

    useEffect(() => {
        const interval = setInterval(() => TimeDuration(), 1000);

        return () => clearInterval(interval);
    });

    // useEffect(() => {
    //     TimeDuration()
    // }, [surveyDetails])




    const addAns = async (data) => {
        // console.log(data._id, 'ind');
        const payload = {
            data: answer,
            _id: data._id
        }
        // console.log(payload, 'payload');
        try {
            if (answer.length < 0 || answer.length < data?.surveyDetails.length) {
                setAnswererr('Please Answer for all questions')
            }
            else {
                setLoader(true)
                const response = await Axios.post('/users/addAnswers', payload, {
                    headers: {
                        "Authorization": window.localStorage.getItem('iphephile')
                    }
                })
                // console.log(response, 'anss');
                if (response?.data?.success) {
                    toast.success(response?.data?.message)
                    setAnswer([])
                    setLoader(false)
                    handleCloseSurbey()
                }
                else {
                    toast.error(response?.data?.message)
                    setAnswer([])
                    setLoader(false)
                    handleCloseSurbey()
                }
            }
        } catch (error) {
            setLoader(false)
            setAnswer([])
            console.log(error, 'err');
        }
    }

    // const addIndex = (questionIndex, answerIndex) => {
    //     setIndex(prevIndices => {
    //         const updatedIndices = [...prevIndices];
    //         updatedIndices[questionIndex] = answerIndex;
    //         return updatedIndices;
    //     });
    //     setInd(prevIndices => {
    //         const updatedIndices = [...prevIndices];
    //         updatedIndices[questionIndex] = questionIndex;
    //         return updatedIndices;
    //     });
    // };

    const renderClip = (id) => {
        const timer = time?.find(i => i.id === id);
        const count = timer ? timer.time : 0;
        const days = (Math.floor(count / (1000 * 60 * 60 * 24)));
        const hours = (Math.floor((count / (1000 * 60 * 60)) % 24));
        const minutes = (Math.floor((count / 1000 / 60) % 60));
        const seconds = (Math.floor((count / 1000) % 60));
        return (
            <Chip
                label={`${days < 10 ? "0" + days : days}:${hours < 10 ? "0" + hours : hours}:${minutes < 10 ? "0" + minutes : minutes}:${seconds < 10 ? "0" + seconds : seconds}`}
                color="primary"
                variant="outlined"
                icon={<img src={timerImg}
                    style={{ width: '25px', height: '25px', borderRadius: '50%' }} />} />

        );
    };

    return (
        <>
            <ToastContainer />
            <div className='my-bets'>
                <Box sx={{ flexGrow: 1 }}>

                    <Grid container spacing={0} className='homebody-container' style={{ "overflow-x": "auto", "flex-warp": "nowrap", "scroll-snap-align": "x manatory" }}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ "scroll-snap-align": "start" }}>
                            <div className='no-sweat-first-bet'>

                                <Grid container spacing={0}>
                                    <div className='my-bets-title'>
                                        <ArrowBackIcon className='cursor' onClick={() => { navigate(-1) }} />   Survey
                                    </div>
                                    <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Box sx={{ width: '100%' }}>
                                            <Box sx={{ borderColor: 'divider' }}>
                                                {/* <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" className='mybet-tab'>
                                                <Tab label="Active" {...a11yProps(0)} />
                                                <Tab label="Settled" {...a11yProps(1)} />

                                            </Tabs> */}
                                            </Box>
                                            <CustomTabPanel value={value} index={0} className='custum-tab'>
                                                {isload ?
                                                    <>
                                                        <div className='clip-loader'>
                                                            <ClipLoader color={'#00008B'} />
                                                        </div>
                                                    </> :
                                                    <Grid container>

                                                        {
                                                            surveyDetails?.length > 0 ?
                                                                surveyDetails.filter(data => data.status === 0).length > 0 ?
                                                                    surveyDetails
                                                                        .filter(data => data.status === 0)
                                                                        .map((data, ind) => (
                                                                            <Grid xs={12} sm={12} md={12} lg={12} xl={12} sx={{ p: 1 }} >
                                                                                <div className="div-dropzone-1mxhs-1 history" >
                                                                                    <div className="designer-info-frame-parent ">
                                                                                        <img
                                                                                            className="designer-info-frame"
                                                                                            loading="eager"
                                                                                            alt=""
                                                                                            src={logo}
                                                                                        />
                                                                                        <div className="eatsleepdesignrepeat">
                                                                                            <div className="kiiara-castillo-parent">
                                                                                                <div className="kiiara-castillo">  {data?.adminDetails?.name}</div>
                                                                                                <div className="eatsleepdesignrepeat1">
                                                                                                    {data?.title}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="histry-title display-2">
                                                                                        <div style={{ wordBreak: "break-word" }}>
                                                                                            <strong>Title:</strong>
                                                                                            {data?.title}
                                                                                        </div>

                                                                                        <div className='text-right '>
                                                                                            {renderClip(data._id)}
                                                                                            {/* <Chip label={`${hours < 10 ? "0" + hours : hours}:${minutes < 10 ? "0" + minutes : minutes}:${seconds < 10 ? "0" + seconds : seconds}`} color="primary" variant="outlined" icon={<img src={timer} style={{ width: '25px', height: '25px', borderRadius: '50%' }} />} /> */}
                                                                                        </div>

                                                                                    </div>
                                                                                    <div className='survey-ssa'>
                                                                                        {/* {
                                                                                data?.surveyDetails.map((ques, index) => (
                                                                                    <div className="progress-yes">
                                                                                        <CircularProgressWithLabel value={85} /><div className="f-r-a-m-e" >{ques?.question}</div>
                                                                                    </div>
                                                                                ))
                                                                            } */}
                                                                                        <div className="progress-yes">
                                                                                            <div className="f-r-a-m-e">Number Of Questions : {data?.surveyDetails?.length > 0 ? data?.surveyDetails?.length : 0}</div>
                                                                                        </div>
                                                                                        <Button variant='contained' onClick={() => { handleOpenSurvey(data) }} className='display-1'>
                                                                                            Participate <StadiumIcon />
                                                                                        </Button>
                                                                                        {/* <div className="progress-yes">
                                                                                        <div className="f-r-a-m-e">{hours < 0 || minutes < 0 || seconds < 0 ? <div className="timer">Survey Ended</div> : <FormLabel>Survey End In: </FormLabel>}
                                                                                            <div className="timer">
                                                                                                {hours < 0 || minutes < 0 || seconds < 0 ? <></> : <>
                                                                                                    {hours < 10 ? "0" + hours : hours}:{minutes < 10 ? "0" + minutes : minutes}:{seconds < 10 ? "0" + seconds : seconds}
                                                                                                </>}
                                                                                            </div></div>
                                                                                    </div> */}

                                                                                        {/* <div className="progress-yes">
                                                                                        <div className="f-r-a-m-e">
                                                                                            {timers[data.id]?.hours < 0 || timers[data.id]?.minutes < 0 || timers[data.id]?.seconds < 0 ? (
                                                                                                <div className="timer">Survey Ended</div>
                                                                                            ) : (
                                                                                                <>
                                                                                                    <FormLabel>Survey End In: </FormLabel>
                                                                                                    <div className="timer">
                                                                                                        {timers[data.id]?.hours < 10 ? "0" + timers[data.id]?.hours : timers[data.id]?.hours}:
                                                                                                        {timers[data.id]?.minutes < 10 ? "0" + timers[data.id]?.minutes : timers[data.id]?.minutes}:
                                                                                                        {timers[data.id]?.seconds < 10 ? "0" + timers[data.id]?.seconds : timers[data.id]?.seconds}
                                                                                                    </div>
                                                                                                </>
                                                                                            )}
                                                                                        </div>
                                                                                    </div> */}
                                                                                    </div>



                                                                                </div>
                                                                            </Grid>
                                                                        ))
                                                                    : <>
                                                                        <Grid xs={12} sm={12} md={12} lg={12} xl={12} sx={{ p: 1 }} >
                                                                            <p className='no-data'>
                                                                                <img src={nodata} />
                                                                                <div>
                                                                                    No Data Found
                                                                                </div>
                                                                            </p>
                                                                        </Grid></>
                                                                : <> <Grid xs={12} sm={12} md={12} lg={12} xl={12} sx={{ p: 1 }} >
                                                                    <p className='no-data'>
                                                                        <img src={nodata} />
                                                                        <div>
                                                                            No Data Found
                                                                        </div>
                                                                    </p>
                                                                </Grid></>
                                                        }
                                                    </Grid>
                                                }
                                            </CustomTabPanel>
                                            <CustomTabPanel value={value} index={1}>
                                                <div className='my-bets-join'>
                                                    <div> <img alt="Remy Sharp" src={nobets} /></div>
                                                    <h6>No settled bets</h6>
                                                    <p>You must be logged in to see bets</p>
                                                    <Button>
                                                        Log in or join now
                                                    </Button>
                                                </div>
                                            </CustomTabPanel>

                                        </Box>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>

                        <Modal
                            open={survey}

                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box sx={style}>
                                <div className="back-icon-survey-hist">
                                    <CancelTwoToneIcon onClick={handleCloseSurbey} />
                                </div>

                                <div className="survey-questions">
                                    {
                                        data?.surveyDetails?.map((datas, index) => (
                                            <div className="div-dropzone-1mxhs-1 history survey-attc" >
                                                <div className="histry-title"><span>{`${index + 1})`}</span>
                                                    {datas.question}
                                                </div>
                                                <div className="survey-check-main">
                                                    <FormControl>
                                                        <RadioGroup
                                                            row
                                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                                            name="row-radio-buttons-group"
                                                        >
                                                            {/* {
                                                                datas?.ans?.map((ans, ind) => (
                                                                    <FormControlLabel
                                                                        value={ans.answers ? ans.answers : ""}
                                                                        onChange={() => { setValues(ans.answers ? ans.answers : "") }}
                                                                        control={<Radio
                                                                            onChange={(e) => addIndex(index, ind)}
                                                                            // onClick={(e) => { updatedDivs(e.target.checked, ind, datas, ans?.answers) }}
                                                                            className='strong-agree' />}
                                                                        label={ans.answers ? ans.answers : ""} />
                                                                ))
                                                            } */}

                                                            <FormControlLabel
                                                                value='Strongly Agree'
                                                                control={
                                                                    <Radio />}
                                                                onChange={(e) => { handleValueChange(index, e.target.value, datas._id) }}
                                                                className='strong-agree'
                                                                label="Strongly Agree" />
                                                            <FormControlLabel
                                                                value='Agree'
                                                                control={
                                                                    <Radio />}
                                                                onChange={(e) => { handleValueChange(index, e.target.value, datas._id) }}

                                                                className='agree'
                                                                label="Agree" />
                                                            <FormControlLabel
                                                                value='Disagree'
                                                                control={
                                                                    <Radio className='disagree' />}
                                                                onChange={(e) => { handleValueChange(index, e.target.value, datas._id) }}
                                                                className='dis-agree'
                                                                label="Disagree" />

                                                            {/* <FormControlLabel value={answers[2]?.answers3} onChange={(e) => { setValues(answers[2]?.answers3) }} control={<Radio className='disagree' />} label="Disagree" /> */}

                                                        </RadioGroup>
                                                    </FormControl>
                                                    {answererr && <p style={{ color: 'red', fontSize: "12px", textAlign: "center" }}> {answererr}</p>}

                                                </div>
                                            </div>
                                        ))
                                    }

                                    <div>
                                        {
                                            loader ?
                                                <div className='btn-submit text-center'>
                                                    <ClipLoader color={'#00008B'} />
                                                </div>
                                                :
                                                <div className='btn-submit text-center'>
                                                    <Button onClick={() => { addAns(data) }} variant='contained'>
                                                        Submit
                                                    </Button>
                                                </div>
                                        }
                                    </div>

                                </div>


                            </Box>
                        </Modal>


                    </Grid>

                </Box>
            </div>
        </>
    )
}

export default Survey
