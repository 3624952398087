import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import '../Lobby/lobby.css'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import '../Mybets/Mybets.css'
import nobets from "../../images/nobets.png"
import { useLocation } from 'react-router-dom';
import Axios from '../../Axios';
import CircularProgress from '@mui/material/CircularProgress';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}



function History(props) {

    const [value, setValue] = useState(0);
    const [depositHistory, setDepositHistory] = useState([])
    const [WithdarwHistory, setWithdrawHistory] = useState([])
    const token = window.localStorage.getItem('iphephile')


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const offer = useLocation()

    // console.log(offer.state, 'offer');
    const [data, setData] = useState([])


    // const fetchData = async () => {
    //     try {
    //         const response = await axios.get('http://192.168.1.6:4005/dashtab');
    //         // Assuming the response data is an array
    //         setData(response.data);
    //     } catch (error) {
    //         console.error('Error fetching data:', error);
    //     }
    // };

    const withdraw = async () => {

        try {
            const depo = await Axios.get(`/users/WithdarwHistory`,
                {
                    headers: {
                        Authorization: window.localStorage.getItem('iphephile')
                    }
                })
            if (depo) {
                const hist = depo?.data?.result

                setWithdrawHistory(hist)
            }
            else {
                setLoader(false)
                setWithdrawHistory([])
            }

        } catch (error) {
            console.log(error)
            setLoader(false)
        }
    }

    const [loader, setLoader] = useState(true)

    const deposit = async () => {
        setLoader(true)
        try {
            const depo = await Axios.get(`/users/depositHistory`,
                {
                    headers: {
                        Authorization: window.localStorage.getItem('iphephile')
                    }
                })
            if (depo) {
                setLoader(false)
                const hist = depo?.data?.result
                setDepositHistory(hist)
            }

        } catch (error) {
            setLoader(false)
            console.log(error)
        }
    }
    // useEffect(() => {
    //     setLoader(true)
    //     const timer = setTimeout(() => {
    //         setLoader(false)
    //     }, 3000);

    //     return () => clearTimeout(timer)

    // }, [data]);

    useEffect(() => {
        deposit()
        withdraw()
    }, [token]);

    const navigate = useNavigate()
    return (
        <div>
            <Box sx={{ flexGrow: 1 }}>

                <Grid container spacing={0} className='homebody-container' style={{ "overflow-x": "auto", "flex-warp": "nowrap", "scroll-snap-align": "x manatory" }}>

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ "scroll-snap-align": "start" }}>
                        <div className='no-sweat-first-bet'>

                            <Grid container spacing={0}>
                                <div className='my-bets-title'>
                                    <ArrowBackIcon className='cursor' onClick={() => { navigate(-1) }} />   Transaction History
                                </div>
                                <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Box sx={{ width: '100%' }}>
                                        <Box sx={{ borderColor: 'divider' }}>
                                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" className='mybet-tab'>
                                                <Tab label="Deposit History" {...a11yProps(0)} />
                                                <Tab label="Withdraw History" {...a11yProps(1)} />

                                            </Tabs>
                                        </Box>
                                        <CustomTabPanel value={value} index={0}>
                                            <div className='custum-tab'>
                                                <TableContainer >
                                                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell>S.No</TableCell>
                                                                <TableCell align="center">Date & Time</TableCell>
                                                                <TableCell align="center">Payment Type</TableCell>
                                                                <TableCell align="center">Amount</TableCell>
                                                                <TableCell align="center">Currency</TableCell>
                                                                <TableCell align="center">Payment Id</TableCell>
                                                                <TableCell align="center">Status</TableCell>
                                                                {/* <TableCell align="right">Protein&nbsp;(g)</TableCell> */}
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {loader ?
                                                                <TableRow>
                                                                    <TableCell colSpan={8}>
                                                                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                                                            <CircularProgress />
                                                                        </Box>
                                                                    </TableCell>
                                                                </TableRow> :
                                                                <>
                                                                    {
                                                                        depositHistory.length > 0 ?
                                                                            <>
                                                                                {
                                                                                    depositHistory.map((row, ind) => {
                                                                                        return (
                                                                                            <>
                                                                                                <TableRow
                                                                                                    key={row.name}
                                                                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                                                >
                                                                                                    <TableCell component="th" scope="row">
                                                                                                        {ind + 1
                                                                                                        }
                                                                                                    </TableCell>
                                                                                                    <TableCell align="center">{new Date(row.createdAt).toLocaleString(undefined, { "hour12": true })}</TableCell>
                                                                                                    <TableCell align="center">{row.others.payment_method_types}</TableCell>
                                                                                                    <TableCell align="center">$ {row.amount}</TableCell>
                                                                                                    <TableCell align="center">
                                                                                                        {row.others.currency.toUpperCase()}
                                                                                                    </TableCell>
                                                                                                    <TableCell align="center">
                                                                                                        {row.payment_intent || '- -'}
                                                                                                    </TableCell>
                                                                                                    <TableCell align="center">
                                                                                                        {
                                                                                                            row.status == 0 ?
                                                                                                                <p style={{ color: "orange" }}>Pending</p> :
                                                                                                                row.status == 1 ?
                                                                                                                    <p style={{ color: "green" }}>Approved</p> :
                                                                                                                    row.status == 2 ?
                                                                                                                        <p style={{ color: "red" }}>Declined</p> :
                                                                                                                        <></>
                                                                                                        }

                                                                                                    </TableCell>
                                                                                                </TableRow>

                                                                                            </>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </>
                                                                            :
                                                                            <TableRow>
                                                                                <TableCell colSpan={8}>
                                                                                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                                                                        <div className='my-bets-join'>
                                                                                            <div> <img alt="Remy Sharp" src={nobets} /></div>
                                                                                            <h6>No Data Found</h6>
                                                                                            {/* <p>You must be logged in to see bets</p>
                                                                                <Button>
                                                                                    Log in or join now
                                                                                </Button> */}
                                                                                        </div>
                                                                                    </Box>
                                                                                </TableCell>
                                                                            </TableRow>
                                                                    }
                                                                </>
                                                            }

                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>

                                            </div>



                                        </CustomTabPanel>
                                        <CustomTabPanel value={value} index={1}>
                                            <div className='custum-tab'>
                                                <TableContainer >
                                                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell>S.No</TableCell>
                                                                <TableCell>Date & Time</TableCell>
                                                                <TableCell align="center">Name</TableCell>
                                                                <TableCell align="center">Bank Name</TableCell>
                                                                <TableCell align="center">Amount</TableCell>
                                                                <TableCell align="center">IFSC code</TableCell>
                                                                <TableCell align="center">Status</TableCell>
                                                                {/* <TableCell align="right">Protein&nbsp;(g)</TableCell> */}
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {WithdarwHistory.length > 0 ? loader ?
                                                                <TableRow>
                                                                    <TableCell colSpan={8}>
                                                                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                                                            <CircularProgress />
                                                                        </Box>
                                                                    </TableCell>
                                                                </TableRow> :
                                                                <>
                                                                    {WithdarwHistory.map((row, ind) => {
                                                                        return (
                                                                            <>
                                                                                <TableRow
                                                                                    key={row.name}
                                                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                                >
                                                                                    <TableCell component="th" scope="row">
                                                                                        {ind + 1
                                                                                        }
                                                                                    </TableCell>
                                                                                    <TableCell align="center">{new Date(row.createdAt).toLocaleString(undefined, { "hour12": true })}</TableCell>
                                                                                    <TableCell align="center"> {row.name}</TableCell>
                                                                                    <TableCell align="center">{row.bank_name}</TableCell>
                                                                                    <TableCell align="center">
                                                                                        $ {row.amount}
                                                                                    </TableCell>
                                                                                    <TableCell align="center">
                                                                                        {row.ifsc}
                                                                                    </TableCell>
                                                                                    <TableCell align="center">
                                                                                        {row.status == 0 ?
                                                                                            <p style={{ color: "orange" }}>Pending</p>
                                                                                            : row.status == 1 ?
                                                                                                <p style={{ color: "green" }}>Approved</p> :
                                                                                                row?.status == 2 ?
                                                                                                    <>
                                                                                                        <p style={{ color: "red" }}>Declined</p>
                                                                                                    </> :
                                                                                                    <></>
                                                                                        }
                                                                                    </TableCell>
                                                                                </TableRow>

                                                                            </>
                                                                        )
                                                                    })}
                                                                </> :
                                                                <TableRow>
                                                                    <TableCell colSpan={8}>
                                                                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                                                            <div className='my-bets-join'>
                                                                                <div> <img alt="Remy Sharp" src={nobets} /></div>
                                                                                <h6>No Data Found</h6>
                                                                                {/* <p>You must be logged in to see bets</p>
                                                                                <Button>
                                                                                    Log in or join now
                                                                                </Button> */}
                                                                            </div>
                                                                        </Box>
                                                                    </TableCell>
                                                                </TableRow>
                                                            }

                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>

                                            </div>



                                        </CustomTabPanel>
                                        <CustomTabPanel value={value} index={2}>
                                            <div className='custum-tab'>
                                                <TableContainer >
                                                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell>Sno</TableCell>
                                                                <TableCell align="right">Date</TableCell>
                                                                <TableCell align="right">Name</TableCell>
                                                                <TableCell align="right">Kyc</TableCell>
                                                                {/* <TableCell align="right">Protein&nbsp;(g)</TableCell> */}
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {data.length > 0 ? loader ?
                                                                <TableRow>
                                                                    <TableCell colSpan={4}>
                                                                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                                                            <CircularProgress />
                                                                        </Box>
                                                                    </TableCell>
                                                                </TableRow> :
                                                                <>
                                                                    {data.map((row, ind) => {
                                                                        return (
                                                                            <>
                                                                                <TableRow
                                                                                    key={row.name}
                                                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                                >
                                                                                    <TableCell component="th" scope="row">
                                                                                        {ind + 1
                                                                                        }
                                                                                    </TableCell>
                                                                                    <TableCell align="right">{row.date}</TableCell>
                                                                                    <TableCell align="right">{row.name}</TableCell>
                                                                                    <TableCell align="right">{row.kyc}</TableCell>
                                                                                </TableRow>

                                                                            </>
                                                                        )
                                                                    })}
                                                                </> :
                                                                <TableRow>
                                                                    <TableCell colSpan={4}>
                                                                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                                                            <div className='my-bets-join'>
                                                                                <div> <img alt="Remy Sharp" src={nobets} /></div>
                                                                                <h6>No active bets</h6>
                                                                                <p>You must be logged in to see bets</p>
                                                                                <Button>
                                                                                    Log in or join now
                                                                                </Button>
                                                                            </div>
                                                                        </Box>
                                                                    </TableCell>
                                                                </TableRow>
                                                            }

                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>

                                            </div>



                                        </CustomTabPanel>

                                    </Box>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>


                </Grid>

            </Box >
        </div >
    )
}

export default History
