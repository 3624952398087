import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import '../Lobby/lobby.css'
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import './Survey.css'
import nobets from "../../images/nobets.png"
import { useLocation } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import Axios from '../../Axios';
import consts from '../../Constant';
import CircularProgress from '@mui/material/CircularProgress';
import ava from '../../images/tv-background.png'
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import CancelTwoToneIcon from '@mui/icons-material/CancelTwoTone';
import Modal from '@mui/material/Modal';
// import { useNavigate, useLocation } from 'react-router-dom';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import nodata from '../../images/thankyou.gif'
import { ToastContainer, toast } from 'react-toastify';
import logo from "../../images/favicon.png"


function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function CircularProgressWithLabel(props) {
    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress variant="determinate" {...props} />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography variant="caption" component="div" color="text.secondary">
                    {`${Math.round(props.value)}%`}
                </Typography>
            </Box>
        </Box>
    );
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    bgcolor: '#fff',
    // border: '2px solid #000',
    borderRadius: '10px',
    boxShadow: 24,
    p: 4,
    '@media(max-width:575.98px)': {
        width: '80%'
    },

};


function MySurvey(props) {

    const [value, setValue] = useState(0);
    const [survey, setSurvey] = useState(false)

    const [values, setValues] = useState()
    const [data, setData] = useState([])
    const [loader, setLoader] = useState(true)
    const [answer, setAnswer] = useState([])
    // console.log(answer, 'answer');
    const [surveyDetails, setSurveyDetails] = useState([])

    const navigate = useNavigate()

    const handleOpenSurvey = (data) => {
        // console.log(data, 'popup');
        setSurvey(true)
        setData(data)
    }
    const handleCloseSurbey = () => setSurvey(false)


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    // const handleValueChange = async (index, answers, id) => {
    //     // console.log(index, answers, id, 'value');
    //     // answer.map(x => x.id == id ?setAnswer([...answer, data]):  console.log(x, 'id'));
    //     var data = {
    //         id: id,
    //         answers: answers
    //     }
    //     console.log(data, answer.length, 'data');
    //     if (answer.length > 0) {
    //         for (let i = 0; i < answer.length; i++) {
    //             console.log(answer[i].id, id, 'change');
    //             if (answer[i].id == id) {
    //                 // console.log('if');
    //                 const myNextList = [...answer];
    //                 const artwork = myNextList.find(
    //                     a => a.id === id
    //                 );
    //                 artwork.answers = answers;
    //                 setAnswer(myNextList);
    //             }
    //             else {
    //                 console.log('else');
    //                 setAnswer(answer => [...answer, data]);
    //             }
    //         }
    //     }
    //     else {
    //         console.log('length else');
    //         setAnswer(answer => [...answer, data]);
    //     }
    // }

    const handleValueChange = async (index, answers, id) => {
        var data = {
            id: id,
            answers: answers
        };

        // Check if answer with the same id already exists
        const existingIndex = answer.findIndex(a => a.id === id);

        if (existingIndex >= 0) {
            // Update existing answer
            const updatedAnswers = [...answer];
            updatedAnswers[existingIndex] = data;
            setAnswer(updatedAnswers);
        } else {
            // Add new answer
            setAnswer([...answer, data]);
        }
    };



    const getSurvey = async () => {
        try {
            const response = await Axios.post('/auth/getSurvey', { type: '' }, {
                headers: {
                    "Authorization": window.localStorage.getItem('iphephile')
                }
            })
            // console.log(response, 'surveyres');
            if (response?.data?.success) {
                setSurveyDetails(response?.data?.result)
            }
            else {
                setSurveyDetails([])
            }
        } catch (error) {
            console.log(error, 'err');
        }
    }

    useEffect(() => {
        // if (window.localStorage.getItem('iphephile')) {
        getSurvey()
        // }
    }, [])





    return (
        <>
            <ToastContainer />
            <div className='my-bets'>
                <Box sx={{ flexGrow: 1 }}>

                    <Grid container spacing={0} className='homebody-container' style={{ "overflow-x": "auto", "flex-warp": "nowrap", "scroll-snap-align": "x manatory" }}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ "scroll-snap-align": "start" }}>
                            <div className='no-sweat-first-bet'>

                                <Grid container spacing={0}>
                                    <div className='my-bets-title'>
                                        <ArrowBackIcon className='cursor' onClick={() => { navigate(-1) }} />   Survey
                                    </div>
                                    <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Box sx={{ width: '100%' }}>
                                            <Box sx={{ borderColor: 'divider' }}>
                                                {/* <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" className='mybet-tab'>
                                                <Tab label="Active" {...a11yProps(0)} />
                                                <Tab label="Settled" {...a11yProps(1)} />

                                            </Tabs> */}
                                            </Box>
                                            <CustomTabPanel value={value} index={0} className='custum-tab'>

                                                <Grid container>
                                                    {
                                                        surveyDetails?.length > 0 ?
                                                            surveyDetails && surveyDetails?.map((data, index) => (
                                                                <Grid xs={12} sm={12} md={12} lg={12} xl={12} sx={{ p: 1 }} onClick={() => { handleOpenSurvey(data) }}>
                                                                    <div className="div-dropzone-1mxhs-1 history" >
                                                                        <div className="designer-info-frame-parent ">
                                                                            <img
                                                                                className="designer-info-frame"
                                                                                loading="eager"
                                                                                alt=""
                                                                                src={logo}
                                                                            />
                                                                            <div className="eatsleepdesignrepeat">
                                                                                <div className="kiiara-castillo-parent">
                                                                                    <div className="kiiara-castillo">  {data?.admin?.name}</div>
                                                                                    <div className="eatsleepdesignrepeat1">
                                                                                        {data?.title}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="histry-title">
                                                                            <strong>Title:</strong>
                                                                            {data?.title}
                                                                        </div>
                                                                        <div className='survey-ssa'>
                                                                            {/* {
                                                                                data?.surveyDetails.map((ques, index) => (
                                                                                    <div className="progress-yes">
                                                                                        <CircularProgressWithLabel value={85} /><div className="f-r-a-m-e" >{ques?.question}</div>
                                                                                    </div>
                                                                                ))
                                                                            } */}
                                                                            <div className="progress-yes">
                                                                                <div className="f-r-a-m-e">Number Of Questions : {data?.survey?.length > 0 ? data?.survey?.length : 0}</div>
                                                                            </div>
                                                                        </div>



                                                                    </div>
                                                                </Grid>
                                                            ))
                                                            : <>
                                                                <Grid xs={12} sm={12} md={12} lg={12} xl={12} sx={{ p: 1 }} >
                                                                    <p className='no-data'>
                                                                        <img src={nodata} />
                                                                        <div>
                                                                            No Data Found
                                                                        </div>
                                                                    </p>
                                                                </Grid>
                                                            </>
                                                    }
                                                </Grid>
                                            </CustomTabPanel>
                                            <CustomTabPanel value={value} index={1}>
                                                <div className='my-bets-join'>
                                                    <div> <img alt="Remy Sharp" src={nobets} /></div>
                                                    <h6>No settled bets</h6>
                                                    <p>You must be logged in to see bets</p>
                                                    <Button>
                                                        Log in or join now
                                                    </Button>
                                                </div>
                                            </CustomTabPanel>

                                        </Box>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>

                        <Modal
                            open={survey}

                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box sx={style}>
                                <div className="back-icon-survey-hist">
                                    <CancelTwoToneIcon onClick={handleCloseSurbey} />
                                </div>

                                <div className="survey-questions">
                                    {
                                        data?.survey?.map((datas, index) => (
                                            <div className="div-dropzone-1mxhs-1 history survey-attc" >
                                                {/* {console.log(datas, 'datas')} */}
                                                <div className="histry-title"><span>{`${index + 1})`}</span>
                                                    {datas.question}
                                                </div>
                                                <div className="survey-check-main">
                                                    <FormControl>
                                                        <RadioGroup
                                                            row
                                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                                            name="row-radio-buttons-group"
                                                        >
                                                            {
                                                                datas.userResponse.response == 'strongly agree' && datas.userResponse.userId ?
                                                                    <FormControlLabel value='Strongly Agree' control={<Radio className='agree' value="" checked />} label="Strongly Agree" />
                                                                    :
                                                                    <FormControlLabel value='Strongly Agree' control={<Radio className='agree' value="" disabled />} label="Strongly Agree" />

                                                            }

                                                            {
                                                                datas.userResponse.response == 'agree' && datas.userResponse.userId ?
                                                                    <FormControlLabel value=' Agree' control={<Radio className='agree' value='' checked />} label="Agree" />
                                                                    :
                                                                    <FormControlLabel value=' Agree' control={<Radio className='agree' value='' disabled />} label="Agree" />

                                                            }

                                                            {
                                                                datas.userResponse.response == 'disagree' && datas.userResponse.userId ?
                                                                    <FormControlLabel value=' Disagree' control={<Radio className='agree' value="" checked />} label="Disagree" />
                                                                    :
                                                                    <FormControlLabel value=' Disagree' control={<Radio className='agree' disabled />} label="Disagree" />

                                                            }



                                                        </RadioGroup>
                                                    </FormControl>

                                                </div>
                                            </div>
                                        ))
                                    }


                                </div>


                            </Box>
                        </Modal>


                    </Grid>

                </Box>
            </div>
        </>
    )
}

export default MySurvey
