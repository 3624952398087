import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Header from '../Header/Header'
import TopHeader from '../Header/TopHeader'
import LeftSideBar from '../SideBar/LeftSideBar';
import RightSideBar from '../SideBar/RightSideBar';
import nosweat from '../../images/nosweat.png'
import { Link } from 'react-router-dom'
import '../Lobby/lobby.css'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import sgp from '../../images/sgp.svg';
import rht from '../../images/rht-arrow.svg';
import nodata from '../../images/thankyou.gif'

import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import profile1 from '../../images/surname-profile1.png'
import profile2 from '../../images/surname-profile2.png'
import vs from '../../images/vs.png'
import { useLocation, useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
// import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from "react-router-dom";
import Axios from '../../Axios';
import consts from '../../Constant';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { styled } from "@mui/material/styles";

import SpeedDial from "@mui/material/SpeedDial";
import { DateTimePicker } from '@mui/lab';

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }} className='tab-scroll'>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const StyledSpeedDial = styled(SpeedDial)(({ theme }) => ({
    position: "absolute",
    "&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft": {
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    "&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight": {
        top: theme.spacing(2),
        left: theme.spacing(2),
    },
}));

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};



function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const arr = [{}, {}, {}]

const Lobby = ({ updateData }) => {
    const token = window.localStorage.getItem('iphephile')
    const location = useLocation()
    const navigate = useNavigate()

    const [direction, setDirection] = React.useState("up");
    const [hidden, setHidden] = React.useState(false);

    const handleDirectionChange = (event) => {
        setDirection(event.target.value);
    };

    const handleHiddenChange = (event) => {
        setHidden(event.target.checked);
    };


    const [value, setValue] = useState(0);
    const [betHistory, setBetHistory] = useState([])
    const [betType, setBetType] = useState('all')
    const [event, setEvent] = useState([])


    const handleChange = (event, newValue) => {
        updateData({ tabvalue: newValue })
        setValue(newValue);
    };


    const checkDates = (eventStart) => {
        const eventDate = new Date(eventStart * 1000);
        // console.log(eventDate.toLocaleString(), 'eventDate');
        const currentDate = new Date();
        // console.log(currentDate.toLocaleString(), 'currentDate');

        if (eventDate.toLocaleString() <= currentDate.toLocaleString()) {
            return "Live";
        } else {
            return eventDate.toLocaleString();
        }
    };



    useEffect(() => {
        if (location?.state?.status == "Verified") {
            toast.success('Email Verified Successfully')
            // setTimeout(() =>{
            //     navigate('/')

            // },2500)
        }
        if (location?.state?.status == "Already Verified") {
            toast.error('Email Already Verified')
            // setTimeout(() =>{
            //     navigate('/')

            // },2500)
        }
    }, [])

    useEffect(() => {
        if (token) {
            if (value == 0 && location?.state?.values == undefined) {
                getBetHistory('all')
            }
            else if (location?.state && location?.state?.values !== undefined) {

                setValue(location.state.values);
                if (location.state.values == 0) {
                    getBetHistory('all')
                }
                else if (location.state.values == 1) {
                    getBetHistory('onevsone')
                }
                else if (location.state.values == 2) {
                    getBetHistory('djvsdj')
                }
                else if (location.state.values == 3) {
                    getBetHistory('vote')
                }
                else if (location.state.values == 4) {
                    getBetHistory('lineup')
                }
            }
        }
        // else if (location.state.values == undefined) {
        //     setValue(0);
        // }
    }, [location?.state?.values]);

    // useEffect(() => {
    //     // getEvent();
    //     if (value == 0) {
    //         getBetHistory('all')
    //     }
    // }, [])



    // lobby table

    const [lobTable1, setLobTable1] = useState([
        { img1: `${profile1}`, midimg: `${vs}`, img2: `${profile2}`, title: '1 vs 1', name: 'Roman Regins', sunname: 'C.b Quantrill', opp: 'Rock', num1: '+1.5', dnum1: '+1.5', num2: '5.00' },
        { img1: `${profile1}`, midimg: `${vs}`, img2: `${profile2}`, title: '1 vs 1', name: 'Cobra', sunname: 'C.b Quantrill', num1: '+1.5', dnum1: '+1.5', num2: '5.00', opp: 'Rock' },
        { img1: `${profile1}`, midimg: `${vs}`, img2: `${profile2}`, title: '1 vs 1', name: 'Guardians lander', sunname: 'C.b Quantrill', num1: '+1.5', dnum1: '+1.5', num2: '5.00', opp: 'rock' },
        { img1: `${profile1}`, midimg: `${vs}`, img2: `${profile2}`, title: '1 vs 1', name: 'Guardians lander', sunname: 'C.b Quantrill', num1: '+1.5', dnum1: '+1.5', num2: '5.00', opp: 'rock' },
        // { img1: `${profile1}`,midimg: `${vs}`, img2: `${profile2}`, name: 'Guardians lander', sunname: 'C.b Quantrill', num1: 'A', dnum1: 'B', num2: 'DJ' }
    ])

    const [lobTable2, setLobTable2] = useState([
        { img1: `${profile1}`, midimg: `${vs}`, img2: `${profile2}`, title: 'Dj vs Dj', name: 'Guardians lander', sunname: 'C.b Quantrill', num1: 'A', dnum1: 'B', num2: 'DJ' },
        { img1: `${profile1}`, midimg: `${vs}`, img2: `${profile2}`, title: 'Dj vs Dj', name: 'Guardians lander', sunname: 'C.b Quantrill', num1: 'A', dnum1: 'B', num2: 'DJ' },
        { img1: `${profile1}`, midimg: `${vs}`, img2: `${profile2}`, title: 'Dj vs Dj', name: 'Guardians lander', sunname: 'C.b Quantrill', num1: 'A', dnum1: 'B', num2: 'DJ' },

    ])
    const [lobTable3, setLobTable3] = useState([
        { img1: `${profile1}`, midimg: `${vs}`, img2: `${profile2}`, title: 'Vote', name: 'Guardians lander', sunname: 'C.b Quantrill', num1: '+1.5', dnum1: '+1.5', num2: '5.00' },
        { img1: `${profile1}`, midimg: `${vs}`, img2: `${profile2}`, title: 'Vote', name: 'Guardians lander', sunname: 'C.b Quantrill', num1: '+1.5', dnum1: '+1.5', num2: '5.00' },
        { img1: `${profile1}`, midimg: `${vs}`, img2: `${profile2}`, title: 'Vote', name: 'Guardians lander', sunname: 'C.b Quantrill', num1: '+1.5', dnum1: '+1.5', num2: '5.00' },

        // { img1: `${profile1}`,midimg: `${vs}`, img2: `${profile2}`, name: 'Guardians lander', sunname: 'C.b Quantrill', num1: 'A', dnum1: 'B', num2: 'DJ' }
    ])

    const [lobTable4, setLobTable4] = useState([
        { img1: `${profile1}`, midimg: `${vs}`, img2: `${profile2}`, title: 'Lineup', name: 'Guardians lander', sunname: 'C.b Quantrill', num1: 'a-c-d-b', dnum1: '+1.5', num2: 'd-c-b-a' },
        { img1: `${profile1}`, midimg: `${vs}`, img2: `${profile2}`, title: 'Lineup', name: 'Guardians lander', sunname: 'C.b Quantrill', num1: 'b-a-d-c', dnum1: '+1.5', num2: 'a-d-b-c' },
        { img1: `${profile1}`, midimg: `${vs}`, img2: `${profile2}`, title: 'Lineup', name: 'Guardians lander', sunname: 'C.b Quantrill', num1: 'c-d-a-b', dnum1: '+1.5', num2: 'c-a-b-d' },

        // { img1: `${profile1}`,midimg: `${vs}`, img2: `${profile2}`, name: 'Guardians lander', sunname: 'C.b Quantrill', num1: 'A', dnum1: 'B', num2: 'DJ' }
    ])

    // const history = useHistory();

    const getBetHistory = async (bettingType) => {
        try {
            const response = await Axios.post('/auth/getonevsone', { betting_type: bettingType }, {
                headers: {
                    Authorization: window.localStorage.getItem('iphephile')
                }
            })
            setBetHistory(response.data.result)

        } catch (error) {
            console.log(error, 'err');
        }
    }


    const getStatus = ({ status }) => {
        if (status == 0) {
            toast.error('Event Not Started')
        }
        else if (status == 2) {
            toast.error('Event Ended')
        }
    }

    const getEvent = async () => {
        try {
            const response = await Axios.post('/event/get_event', {}, {
                headers: {
                    Authorization: window.localStorage.getItem('iphephile')
                }
            })
            setEvent(response.data.result)
        } catch (error) {
            console.log(error, 'err');
        }
    }


    return (

        <>
            <ToastContainer />

            <div className='lobby-page'>


                {/* <Box className="lobby-page-innner" sx={{ flexGrow: 1 }}>
                <Grid container spacing={0}>
                    <Grid className='lobby-content' item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <TopHeader />
                        <Header />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={2} xl={2}>
                        <LeftSideBar />
                    </Grid>
                   
                </Grid>
            </Box> */}


                <Box sx={{ flexGrow: 1 }}>

                    {/* <Grid container spacing={0}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <TopHeader />
                        <Header />
                    </Grid>
                </Grid> */}

                    {/* <Grid item xs={12} sm={12} md={12} lg={2} xl={2} className='sidebarcontanier'>
                        <LeftSideBar />
                    </Grid> */}

                    {/* "overflow-x": "auto", */}
                    {/* <Box sx={{ transform: "translateZ(0px)", flexGrow: 1 }}>

                        <StyledSpeedDial
                            ariaLabel="SpeedDial playground example"
                            hidden={hidden}
                            icon={<img src='back' />}
                            direction="Right"
                        >

                        </StyledSpeedDial>

                    </Box> */}
                    <Grid container spacing={0} className='homebody-container' style={{ "flex-warp": "nowrap", "scroll-snap-align": "x manatory" }}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ "scroll-snap-align": "start" }}>
                            <div className='no-sweat-first-bet'>
                                <div className='back-btn' onClick={(() => { navigate('/') })} >
                                    <ArrowBackIcon />
                                </div>
                                <div className='nosweat'>
                                    <img src={nosweat} alt="nosweat" />
                                </div>
                                <div className='more-info-twobtn'>
                                    {/* <div className='moreInfo'><Link to="" >Join now</Link></div> */}
                                    <div className='joinNow'><Link to="" >Place to bet on</Link></div>
                                </div>
                            </div>

                        </Grid>

                        <Grid container spacing={0}>
                            <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Box sx={{ width: '100%' }}>
                                    <Box sx={{ borderColor: 'divider' }}>

                                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" className='lob-tab'>
                                            <Tab label="All" {...a11yProps(0)} onClick={(e) => { getBetHistory('all') }} />
                                            <Tab label="1 vs 1" {...a11yProps(1)} onClick={(e) => { getBetHistory('onevsone') }} />
                                            <Tab label="Dj vs Dj" {...a11yProps(2)} onClick={(e) => { getBetHistory('djvsdj') }} />
                                            <Tab label="Vote" {...a11yProps(3)} onClick={(e) => { getBetHistory('vote') }} />
                                            <Tab label="Lineup" {...a11yProps(4)} onClick={(e) => { getBetHistory('lineup') }} />

                                        </Tabs>


                                        {/* <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" className='lob-tab'>
                                            <Tab label="All" {...a11yProps(0)} onClick={(e) => { getBetHistory('all') }} />
                                            {
                                                event && event.map((data, ind) => (
                                                    <>
                                                        <Tab label="All" {...a11yProps(0)} onClick={(e) => { getBetHistory('all') }} />
                                                        {
                                                            data?.name == 'onevsone' ?
                                                                <Tab label="1 vs 1" {...a11yProps(1)} onClick={(e) => { getBetHistory('onevsone') }} /> :
                                                                data?.name == 'djvsdj' ?
                                                                    <Tab label="Dj vs Dj" {...a11yProps(2)} onClick={(e) => { getBetHistory('djvsdj') }} /> :
                                                                    data?.name == 'vote' ?
                                                                        <Tab label="Vote" {...a11yProps(3)} onClick={(e) => { getBetHistory('vote') }} /> :
                                                                        data?.name == 'lineup' ?
                                                                            <Tab label="Lineup" {...a11yProps(4)} onClick={(e) => { getBetHistory('lineup') }} /> :
                                                                            <></>
                                                        }
                                                    </>

                                                    <Tab label={data?.name}
                                                        {...a11yProps(ind + 1)}
                                                        onClick={(e) => { getBetHistory(data?.name); setBetType(data.name) }} />
                                                ))
                                            }


                                        </Tabs> */}
                                    </Box>
                                    <CustomTabPanel value={value} index={0}>
                                        <div className='lob-tab-panel-dvi1' style={{ "display": "none" }}>
                                            <div className='mlb-odd'>
                                                MLB Odds
                                            </div>
                                            <div className='more-mlb'>
                                                More MLB <KeyboardArrowRightIcon />
                                            </div>
                                        </div>
                                        {betHistory?.length > 0 ?
                                            <>
                                                {betHistory && betHistory?.map((row, ind) => (

                                                    <>

                                                        {/* <div className='table-lob' >
                                                    <TableContainer component={Paper}>
                                                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                                            <TableHead>
                                                                <TableRow className='headr-row'>
                                                                    <TableCell>Teams</TableCell>
                                                                    <TableCell>Spread</TableCell>
                                                                    <TableCell >Money</TableCell>
                                                                    <TableCell>Total</TableCell>

                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                <TableRow

                                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                    className='tb-body-row'
                                                                >
                                                                    <TableCell component="th" scope="row">
                                                                        <div className='tab-ava-main'>
                                                                            <div>
                                                                                <Avatar alt="Remy Sharp" src={row.teamA[0].img} />
                                                                            </div>
                                                                            <div className='ava-name'>
                                                                                {row.teamA[0].name}
                                                                                <div className='ava-innername'>
                                                                                    {row.teamA[0].nickname}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='tab-ava-main'>
                                                                            <div>
                                                                                <Avatar alt="Remy Sharp" src={row.teamA[1].img} />
                                                                            </div>
                                                                            <div className='ava-name'>
                                                                                {row.teamA[1].name}
                                                                                <div className='ava-innername'>
                                                                                    {row.teamA[1].nickname}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </TableCell>

                                                                    <TableCell component="th" scope="row">
                                                                        <div>
                                                                            <div className='btn-1-table'>
                                                                                <Button><div>
                                                                                    {row.minimum_betting_amount}
                                                                                    <div className='vr-color'>
                                                                                        {row.num2}

                                                                                    </div>
                                                                                </div></Button>
                                                                            </div>
                                                                            <div className='btn-2-table'>
                                                                                <Button>
                                                                                    <div>
                                                                                        {row.minimum_betting_amount}
                                                                                        <div className='vr-color2'>
                                                                                            {row.num2}
                                                                                        </div>
                                                                                    </div>
                                                                                </Button>
                                                                            </div>
                                                                        </div>
                                                                    </TableCell>
                                                                    <TableCell component="th" scope="row">
                                                                        <div>
                                                                            <div className='btn-2-table'>
                                                                                <Button>
                                                                                    <div>
                                                                                        {row.minimum_betting_amount}
                                                                                        <div className='vr-color2'>
                                                                                            {row.num2}
                                                                                        </div>
                                                                                    </div>
                                                                                </Button>
                                                                            </div>
                                                                            <div className='btn-2-table'>
                                                                                <Button>
                                                                                    <div>
                                                                                        {row.minimum_betting_amount}
                                                                                        <div className='vr-color2'>
                                                                                            {row.num2}
                                                                                        </div>
                                                                                    </div>
                                                                                </Button>
                                                                            </div>
                                                                        </div>
                                                                    </TableCell>
                                                                    <TableCell component="th" scope="row">
                                                                        <div>
                                                                            <div className='btn-2-table'>
                                                                                <Button>
                                                                                    <div>
                                                                                        {row.minimum_betting_amount}
                                                                                        <div className='vr-color2'>
                                                                                            {row.num2}
                                                                                        </div>
                                                                                    </div>
                                                                                </Button>
                                                                            </div>
                                                                            <div className='btn-2-table'>
                                                                                <Button>
                                                                                    <div>
                                                                                        {row.minimum_betting_amount}
                                                                                        <div className='vr-color2'>
                                                                                            {row.num2}
                                                                                        </div>
                                                                                    </div>
                                                                                </Button>
                                                                            </div>
                                                                        </div>
                                                                    </TableCell>
                                                                </TableRow>
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </div> */}

                                                        {row?.betting_type == 'onevsone' ? (
                                                            <div className='table-lob'>
                                                                <TableContainer component={Paper}>
                                                                    <Table sx={{ minWidth: 650 }} aria-label="simple table">

                                                                        <TableBody className='tb-body-row'>
                                                                            <TableRow

                                                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}

                                                                            >
                                                                                <TableCell component="th" scope="row">
                                                                                    <div className='preffered-title'>
                                                                                        <span> Bet Title: <span className='title-span'>{row?.title}</span></span>
                                                                                    </div></TableCell>
                                                                                <TableCell component="th" scope="row">
                                                                                    <div className='preffered-title'>
                                                                                        <span> Bet Type:  <span className='title-span'>{row?.betting_type == 'onevsone' ? '1vs1' : ""}</span></span>
                                                                                    </div>
                                                                                </TableCell>
                                                                                <TableCell component="th" scope="row">
                                                                                    <div className='preffered-title'>
                                                                                        <span> Bet Status: {
                                                                                            row?.status == '0' ?
                                                                                                <span className='title-span'>upcoming</span> :
                                                                                                row?.status == '1' ?
                                                                                                    <span className='title-span'>Ongoing</span> :
                                                                                                    row?.status == '2' ?
                                                                                                        <span className='title-span'>Ended</span> :
                                                                                                        row?.status == '3' ?
                                                                                                            <span className='title-span'>Blocked</span> :
                                                                                                            <></>
                                                                                        }</span>
                                                                                    </div>

                                                                                </TableCell>
                                                                            </TableRow>
                                                                            <TableRow

                                                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                            >
                                                                                <TableCell component="th" scope="row">
                                                                                    <div>
                                                                                        <div className='btn-1-table'>
                                                                                            {
                                                                                                row?.status == '1' ?
                                                                                                    <Button
                                                                                                        onClick={() => { navigate('/bet1v1', { state: { row, value } }) }}
                                                                                                    ><div>
                                                                                                            <div className='vr-color'>
                                                                                                                Bet:$ {row?.minimum_betting_amount}
                                                                                                            </div>

                                                                                                        </div>
                                                                                                    </Button> :
                                                                                                    row?.status == '0' ?
                                                                                                        <Button onClick={() => { getStatus({ status: 0 }) }}>
                                                                                                            <div>
                                                                                                                <div className='vr-color' >
                                                                                                                    Bet:$ {row?.minimum_betting_amount}
                                                                                                                </div>
                                                                                                            </div></Button> :
                                                                                                        row?.status == '2' ?
                                                                                                            <Button onClick={() => { getStatus({ status: 2 }) }}>
                                                                                                                <div>
                                                                                                                    <div className='vr-color' >
                                                                                                                        Bet:$ {row?.minimum_betting_amount}
                                                                                                                    </div>
                                                                                                                </div></Button> :
                                                                                                            <></>
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                </TableCell>
                                                                                <TableCell component="th" scope="row">


                                                                                    <div className='tab-ava-main'>
                                                                                        <div className='ava-name'>
                                                                                            {row?.teamA[0]?.name}
                                                                                            <div className='ava-innername username1'>
                                                                                                {row?.teamA[0]?.nickname}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className='flex-img'>
                                                                                            <div>
                                                                                                <Avatar className='profile1' alt="Remy Sharp" src={row?.teamA[0]?.img} />
                                                                                            </div>
                                                                                            <div className='midimg'>
                                                                                                <img alt="Remy Sharp" src={vs} />
                                                                                            </div>
                                                                                            <div>
                                                                                                <Avatar className='profile2' alt="Remy Sharp" src={row?.teamA[1]?.img} />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className='ava-name'>
                                                                                            {row.teamA[1].name}
                                                                                            <div className='ava-innername username2'>
                                                                                                {row?.teamA[1]?.nickname}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='display-flex'>Admin Fee: {row?.admin_fee}%</div>

                                                                                    <div className='display-flex'>
                                                                                        Event Start At: {checkDates(row?.eventstart)}

                                                                                        {/* Event Start At: {new Date(row?.eventstart * 1000).toLocaleString()} */}
                                                                                    </div>
                                                                                    {/* {new Date(row?.eventstart).toLocaleString({ day: 'numeric', month: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true })} */}

                                                                                </TableCell>

                                                                                <TableCell component="th" scope="row">
                                                                                    <div>
                                                                                        <div className='btn-1-table'>
                                                                                            {
                                                                                                row?.status == '1' ?
                                                                                                    <Button
                                                                                                        onClick={() => { navigate('/bet1v1', { state: { row, value } }) }}
                                                                                                    ><div>
                                                                                                            <div className='vr-color'>
                                                                                                                Bet:$ {row?.minimum_betting_amount}
                                                                                                            </div>

                                                                                                        </div>
                                                                                                    </Button> :
                                                                                                    row?.status == '0' ?
                                                                                                        <Button onClick={() => { getStatus({ status: 0 }) }}>
                                                                                                            <div>
                                                                                                                <div className='vr-color' >
                                                                                                                    Bet:$ {row?.minimum_betting_amount}
                                                                                                                </div>
                                                                                                            </div></Button> :
                                                                                                        row?.status == '2' ?
                                                                                                            <Button onClick={() => { getStatus({ status: 2 }) }}>
                                                                                                                <div>
                                                                                                                    <div className='vr-color' >
                                                                                                                        Bet:$ {row?.minimum_betting_amount}
                                                                                                                    </div>
                                                                                                                </div></Button> :
                                                                                                            <></>
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        </TableBody>
                                                                    </Table>
                                                                </TableContainer>
                                                            </div>
                                                        ) :

                                                            (
                                                                row?.betting_type == 'djvsdj' ? (

                                                                    <div className='table-lob'>
                                                                        <TableContainer component={Paper}>
                                                                            <Table sx={{ minWidth: 650 }} aria-label="simple table">

                                                                                <TableBody className='tb-body-row'>
                                                                                    <TableRow

                                                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}

                                                                                    >
                                                                                        <TableCell component="th" scope="row">
                                                                                            <div className='preffered-title'>
                                                                                                <span> Bet Title: <span className='title-span'>{row?.title}</span></span>
                                                                                            </div></TableCell>
                                                                                        <TableCell component="th" scope="row">
                                                                                            <div className='preffered-title'>
                                                                                                <span> Bet Type:  <span className='title-span'>{row?.betting_type}</span></span>
                                                                                            </div>
                                                                                        </TableCell>
                                                                                        <TableCell component="th" scope="row">
                                                                                            <div className='preffered-title'>
                                                                                                <span> Bet Status: {
                                                                                                    row?.status == '0' ?
                                                                                                        <span className='title-span'>upcoming</span> :
                                                                                                        row?.status == '1' ?
                                                                                                            <span className='title-span'>Ongoing</span> :
                                                                                                            row?.status == '2' ?
                                                                                                                <span className='title-span'>Ended</span> :
                                                                                                                row?.status == '3' ?
                                                                                                                    <span className='title-span'>Blocked</span> :
                                                                                                                    <></>
                                                                                                }</span>
                                                                                            </div>

                                                                                        </TableCell>
                                                                                    </TableRow>
                                                                                    <TableRow

                                                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}

                                                                                    >
                                                                                        <TableCell component="th" scope="row">
                                                                                            {/* {
                                                                                                row?.betting_type == 'teamvsteam' ?
                                                                                                    <div className='preffered-title'>
                                                                                                        <span>
                                                                                                            {row?.teamAname}
                                                                                                            {ind + 1}
                                                                                                        </span></div> :
                                                                                                    <></>
                                                                                            } */}
                                                                                            <div>
                                                                                                <div className='btn-1-table'>
                                                                                                    {
                                                                                                        row?.status == '1' ?
                                                                                                            <Button onClick={() => { navigate('/bet1v1', { state: { row, value } }) }}><div>

                                                                                                                <div className='vr-color'>
                                                                                                                    Bet:$ {row?.minimum_betting_amount}
                                                                                                                </div>
                                                                                                            </div></Button> :
                                                                                                            row?.status == '0' ?
                                                                                                                <Button onClick={() => { getStatus({ status: 0 }) }}>
                                                                                                                    <div>
                                                                                                                        <div className='vr-color' >
                                                                                                                            Bet:$ {row?.minimum_betting_amount}
                                                                                                                        </div>
                                                                                                                    </div></Button> :
                                                                                                                row?.status == '2' ?
                                                                                                                    <Button onClick={() => { getStatus({ status: 2 }) }}>
                                                                                                                        <div>
                                                                                                                            <div className='vr-color' >
                                                                                                                                Bet:$ {row?.minimum_betting_amount}
                                                                                                                            </div>
                                                                                                                        </div></Button> :
                                                                                                                    <></>
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                        </TableCell>
                                                                                        <TableCell component="th" scope="row">


                                                                                            <div className='tab-ava-main'>
                                                                                                <div className='ava-name'>
                                                                                                    {/* {row?.betting_type == 'teamvsteam' ? row?.teamAname : row?.teamA[0]?.name} */}
                                                                                                    {row?.teamA[0]?.name}
                                                                                                    <div className='ava-innername username1'>
                                                                                                        {row?.teamA[0]?.nickname}
                                                                                                    </div>
                                                                                                    {/* {row?.betting_type == 'teamvsteam' ? <></> : <div className='ava-innername username1'>
                                                                                                        {row?.teamA[0]?.nickname}
                                                                                                    </div>} */}
                                                                                                </div>
                                                                                                <div className='flex-img'>
                                                                                                    <div>
                                                                                                        <Avatar className='profile1' alt="Remy Sharp" src={row?.teamA[0]?.img} />
                                                                                                        {/* {row?.betting_type == 'teamvsteam' ?
                                                                                                            <Avatar className='profile1' alt="Remy Sharp" src={row?.teamAimg} /> :
                                                                                                            <Avatar className='profile1' alt="Remy Sharp" src={row?.teamA[0]?.img} />
                                                                                                        } */}
                                                                                                    </div>
                                                                                                    <div className='midimg'>
                                                                                                        <img alt="Remy Sharp" src={vs} />
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        {/* {row?.betting_type == 'teamvsteam' ?
                                                                                                            <Avatar className='profile2' alt="Remy Sharp" src={row?.teamBimg} /> :
                                                                                                            <Avatar className='profile2' alt="Remy Sharp" src={row?.teamA[1]?.img} />
                                                                                                        } */}
                                                                                                        <Avatar className='profile2' alt="Remy Sharp" src={row?.teamA[1]?.img} />
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className='ava-name'>
                                                                                                    {row?.teamA[1]?.name}
                                                                                                    <div className='ava-innername username2'>
                                                                                                        {row?.teamA[1]?.nickname}
                                                                                                    </div>
                                                                                                    {/* {row?.betting_type == 'teamvsteam' ? row?.teamBname : row?.teamA[1]?.name} */}
                                                                                                    {/* {row?.betting_type == 'teamvsteam' ? <></> :
                                                                                                        <div className='ava-innername username2'>
                                                                                                            {row?.teamA[1]?.nickname}
                                                                                                        </div>} */}
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className='display-flex'>Admin Fee: {row?.admin_fee}%</div>
                                                                                            <div className='display-flex'>Event Start At: {checkDates(row?.eventstart)}
                                                                                                {/* {new Date(row?.eventstart).toLocaleString({ day: 'numeric', month: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true })} */}
                                                                                            </div>
                                                                                        </TableCell>

                                                                                        <TableCell component="th" scope="row">
                                                                                            {
                                                                                                row?.betting_type == 'teamvsteam' ?
                                                                                                    <div className='preffered-title'>
                                                                                                        <span>
                                                                                                            {row?.teamBname}
                                                                                                            {/* {ind + 1} */}
                                                                                                        </span></div> :
                                                                                                    <></>
                                                                                            }
                                                                                            <div>
                                                                                                <div className='btn-1-table'>
                                                                                                    {
                                                                                                        row?.status == '1' ?
                                                                                                            <Button onClick={() => { navigate('/bet1v1', { state: { row, value } }) }}><div>
                                                                                                                <div className='vr-color'>
                                                                                                                    Bet:$ {row?.minimum_betting_amount}
                                                                                                                </div>

                                                                                                            </div></Button> :
                                                                                                            row?.status == '0' ?
                                                                                                                <Button onClick={() => { getStatus({ status: 0 }) }}>
                                                                                                                    <div>
                                                                                                                        <div className='vr-color' >
                                                                                                                            Bet:$ {row?.minimum_betting_amount}
                                                                                                                        </div>
                                                                                                                    </div></Button> :
                                                                                                                row?.status == '2' ?
                                                                                                                    <Button onClick={() => { getStatus({ status: 2 }) }}>
                                                                                                                        <div>
                                                                                                                            <div className='vr-color' >
                                                                                                                                Bet:$ {row?.minimum_betting_amount}
                                                                                                                            </div>
                                                                                                                        </div></Button> :
                                                                                                                    <></>
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                        </TableCell>

                                                                                    </TableRow>
                                                                                </TableBody>
                                                                            </Table>
                                                                        </TableContainer>
                                                                    </div>

                                                                ) :

                                                                    row?.betting_type == 'teamvsteam' ?
                                                                        <div className='table-lob'>
                                                                            <TableContainer component={Paper}>
                                                                                <Table sx={{ minWidth: 650 }} aria-label="simple table">

                                                                                    <TableBody className='tb-body-row'>
                                                                                        <TableRow
                                                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                                        >
                                                                                            <TableCell component="th" scope="row">
                                                                                                <div className='preffered-title'>
                                                                                                    <span> Bet Title: <span className='title-span'>{row?.title}</span></span>
                                                                                                </div></TableCell>
                                                                                            <TableCell component="th" scope="row">
                                                                                                <div className='preffered-title'>
                                                                                                    <span> Bet Type:  <span className='title-span'>{row?.betting_type}</span></span>
                                                                                                </div>
                                                                                            </TableCell>
                                                                                            <TableCell component="th" scope="row" colSpan={10}>
                                                                                                <div className='preffered-title'>
                                                                                                    <span> Bet Status: {
                                                                                                        row?.status == '0' ?
                                                                                                            'Upcoming' :
                                                                                                            row?.status == '1' ?
                                                                                                                "Ongoing" :
                                                                                                                row?.status == '2' ?
                                                                                                                    "Ended" :
                                                                                                                    row?.status == '3' ?
                                                                                                                        "Blocked" :
                                                                                                                        <></>
                                                                                                    }</span>
                                                                                                </div>
                                                                                            </TableCell>
                                                                                        </TableRow>
                                                                                        <TableRow
                                                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}

                                                                                        >
                                                                                            {row.teams && row?.teams.map((data, index) => (
                                                                                                <>

                                                                                                    <TableCell component="th" scope="row">
                                                                                                        {
                                                                                                            data?.teamName?.map((teams) => (
                                                                                                                <div className='preffered-title'>
                                                                                                                    <span>
                                                                                                                        <div>
                                                                                                                            <Avatar className='profile1' alt="Remy Sharp" src={teams?.img} />
                                                                                                                        </div>
                                                                                                                        {teams?.name}
                                                                                                                        <>
                                                                                                                            {index < row?.teams?.length - 1 &&
                                                                                                                                <TableCell component="th" scope="row">
                                                                                                                                    <div className='midimg2'>
                                                                                                                                        <img alt="Remy Sharp" src={vs} />
                                                                                                                                    </div>
                                                                                                                                </TableCell>
                                                                                                                            }
                                                                                                                        </>

                                                                                                                        {/* {ind + 2} */}
                                                                                                                    </span></div>
                                                                                                            ))
                                                                                                        }
                                                                                                        <div>
                                                                                                            <div className='btn-1-table'>
                                                                                                                {
                                                                                                                    row?.status == '1' ?
                                                                                                                        <Button onClick={() => { navigate('/bet1v1', { state: { row, value } }) }}><div>
                                                                                                                            <div className='vr-color'>
                                                                                                                                Bet:$ {row?.minimum_betting_amount}
                                                                                                                            </div>

                                                                                                                        </div></Button> :
                                                                                                                        row?.status == '0' ?
                                                                                                                            <Button onClick={() => { getStatus({ status: 0 }) }}>
                                                                                                                                <div>
                                                                                                                                    <div className='vr-color' >
                                                                                                                                        Bet:$ {row?.minimum_betting_amount}
                                                                                                                                    </div>
                                                                                                                                </div></Button> :
                                                                                                                            row?.status == '2' ?
                                                                                                                                <Button onClick={() => { getStatus({ status: 2 }) }}>
                                                                                                                                    <div>
                                                                                                                                        <div className='vr-color' >
                                                                                                                                            Bet:$ {row?.minimum_betting_amount}
                                                                                                                                        </div>
                                                                                                                                    </div></Button> :
                                                                                                                                <></>
                                                                                                                }

                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </TableCell>
                                                                                                </>
                                                                                            ))}


                                                                                        </TableRow>

                                                                                        <TableRow
                                                                                        // className='tb-body-row'
                                                                                        >
                                                                                            <TableCell component="th" scope="row" colSpan={10}>


                                                                                                <div className='display-flex'>Admin Fee: {row?.admin_fee}%</div>
                                                                                                <div className='display-flex'>Event Start At: {checkDates(row?.eventstart)}
                                                                                                </div>

                                                                                            </TableCell>
                                                                                        </TableRow>


                                                                                    </TableBody>
                                                                                </Table>
                                                                            </TableContainer>
                                                                        </div>
                                                                        :
                                                                        (
                                                                            row?.betting_type == 'vote' ? (
                                                                                <div className='table-lob'>
                                                                                    {

                                                                                        <TableContainer component={Paper}>
                                                                                            <Table sx={{ minWidth: 650 }} aria-label="simple table" className='lobtable3'>

                                                                                                <TableBody>
                                                                                                    <TableRow

                                                                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}

                                                                                                    >
                                                                                                        <TableCell component="th" scope="row">
                                                                                                            <div className='preffered-title'>
                                                                                                                <span> Bet Title: <span className='title-span'>{row?.title}</span></span>
                                                                                                            </div></TableCell>
                                                                                                        <TableCell component="th" scope="row">
                                                                                                            <div className='preffered-title'>
                                                                                                                <span> Bet Type:  <span className='title-span'>{row?.betting_type}</span></span>
                                                                                                            </div>
                                                                                                        </TableCell>
                                                                                                        <TableCell component="th" scope="row">
                                                                                                            <div className='preffered-title'>
                                                                                                                <span> Bet Status: {
                                                                                                                    row?.status == '0' ?
                                                                                                                        <span className='title-span'>upcoming</span> :
                                                                                                                        row?.status == '1' ?
                                                                                                                            <span className='title-span'>Ongoing</span> :
                                                                                                                            row?.status == '2' ?
                                                                                                                                <span className='title-span'>Ended</span> :
                                                                                                                                row?.status == '3' ?
                                                                                                                                    <span className='title-span'>Blocked</span> :
                                                                                                                                    <></>
                                                                                                                }</span>
                                                                                                            </div>

                                                                                                        </TableCell>
                                                                                                    </TableRow>
                                                                                                    <TableRow
                                                                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                                                        className='tb-body-row'
                                                                                                    >
                                                                                                        {
                                                                                                            row?.teamA.map((data, ind) => (
                                                                                                                <TableCell component="th" scope="row">
                                                                                                                    <div className='tab-ava-main lobTable3'>
                                                                                                                        <div>
                                                                                                                            <Avatar alt="Remy Sharp" src={data.img} />
                                                                                                                        </div>
                                                                                                                        <div className='ava-name'>
                                                                                                                            {data.name}
                                                                                                                            <div className='ava-innername'>
                                                                                                                                {data.nickname}
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </TableCell>
                                                                                                            ))
                                                                                                        }
                                                                                                    </TableRow>
                                                                                                    <TableRow
                                                                                                    // onClick={() => { navigate('/vote') }}
                                                                                                    >
                                                                                                        <TableCell colSpan={4}>
                                                                                                            <div className='btn-1-table'>

                                                                                                                {
                                                                                                                    row?.status == '1' ?
                                                                                                                        <Button onClick={() => { navigate('/vote', { state: { row, value } }) }}><div>
                                                                                                                            <div className='vr-color'>
                                                                                                                                Bet:${row.minimum_betting_amount}
                                                                                                                            </div>
                                                                                                                        </div></Button>
                                                                                                                        :
                                                                                                                        row?.status == '0' ?
                                                                                                                            <Button onClick={() => { getStatus({ status: 0 }) }}>
                                                                                                                                <div>
                                                                                                                                    <div className='vr-color' >
                                                                                                                                        Bet:$ {row?.minimum_betting_amount}
                                                                                                                                    </div>
                                                                                                                                </div></Button> :
                                                                                                                            row?.status == '2' ?
                                                                                                                                <Button onClick={() => { getStatus({ status: 2 }) }}>
                                                                                                                                    <div>
                                                                                                                                        <div className='vr-color' >
                                                                                                                                            Bet:$ {row?.minimum_betting_amount}
                                                                                                                                        </div>
                                                                                                                                    </div></Button> :
                                                                                                                                <></>
                                                                                                                }

                                                                                                            </div>

                                                                                                            <div className='display-flex'>Admin Fee: {row?.admin_fee}%</div>
                                                                                                            <div className='display-flex'>Event Start At: {checkDates(row?.eventstart)}
                                                                                                                {/* {new Date(row?.eventstart).toLocaleString({ day: 'numeric', month: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true })} */}
                                                                                                            </div>
                                                                                                        </TableCell>
                                                                                                    </TableRow>

                                                                                                </TableBody>
                                                                                            </Table>
                                                                                        </TableContainer>

                                                                                    }

                                                                                </div>
                                                                            ) : (
                                                                                row?.betting_type == 'lineup' ? (
                                                                                    <div className='table-lob'>
                                                                                        {

                                                                                            <TableContainer component={Paper}>
                                                                                                <Table sx={{ minWidth: 650 }} aria-label="simple table" className='lobtable3'>

                                                                                                    <TableBody className='tb-body-row'>
                                                                                                        <TableRow

                                                                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}

                                                                                                        >
                                                                                                            <TableCell component="th" scope="row">
                                                                                                                <div className='preffered-title'>
                                                                                                                    <span> Bet Title: <span className='title-span'>{row?.title}</span></span>
                                                                                                                </div></TableCell>
                                                                                                            <TableCell component="th" scope="row">
                                                                                                                <div className='preffered-title'>
                                                                                                                    <span> Bet Type:  <span className='title-span'>{row?.betting_type}</span></span>
                                                                                                                </div>
                                                                                                            </TableCell>
                                                                                                            <TableCell component="th" scope="row">
                                                                                                                <div className='preffered-title'>
                                                                                                                    <span> Bet Status: {
                                                                                                                        row?.status == '0' ?
                                                                                                                            <span className='title-span'>upcoming</span> :
                                                                                                                            row?.status == '1' ?
                                                                                                                                <span className='title-span'>Ongoing</span> :
                                                                                                                                row?.status == '2' ?
                                                                                                                                    <span className='title-span'>Ended</span> :
                                                                                                                                    row?.status == '3' ?
                                                                                                                                        <span className='title-span'>Blocked</span> :
                                                                                                                                        <></>
                                                                                                                    }</span>
                                                                                                                </div>

                                                                                                            </TableCell>
                                                                                                        </TableRow>
                                                                                                        <TableRow
                                                                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                                                            className='tb-body-row'
                                                                                                        >
                                                                                                            {
                                                                                                                row?.teamA.map((data, ind) => (
                                                                                                                    <TableCell component="th" scope="row">
                                                                                                                        <div className='tab-ava-main lobTable3'>
                                                                                                                            <div>
                                                                                                                                <Avatar alt="Remy Sharp" src={data.img} />
                                                                                                                            </div>
                                                                                                                            <div className='ava-name'>
                                                                                                                                {data.name}
                                                                                                                                <div className='ava-innername'>
                                                                                                                                    {data.nickname}
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </TableCell>
                                                                                                                ))
                                                                                                            }
                                                                                                        </TableRow>
                                                                                                        <TableRow
                                                                                                        // onClick={() => { navigate('/vote') }}
                                                                                                        >
                                                                                                            <TableCell colSpan={4}>
                                                                                                                <div className='btn-1-table'>
                                                                                                                    {
                                                                                                                        row?.status == '1' ?
                                                                                                                            <Button onClick={() => { navigate('/lineup', { state: { row, value } }) }}><div>
                                                                                                                                <div className='vr-color'>
                                                                                                                                    Bet:${row.minimum_betting_amount}
                                                                                                                                </div>
                                                                                                                            </div></Button> :
                                                                                                                            row?.status == '0' ?
                                                                                                                                <Button onClick={() => { getStatus({ status: 0 }) }}>
                                                                                                                                    <div>
                                                                                                                                        <div className='vr-color' >
                                                                                                                                            Bet:$ {row?.minimum_betting_amount}
                                                                                                                                        </div>
                                                                                                                                    </div></Button> :
                                                                                                                                row?.status == '2' ?
                                                                                                                                    <Button onClick={() => { getStatus({ status: 2 }) }}>
                                                                                                                                        <div>
                                                                                                                                            <div className='vr-color' >
                                                                                                                                                Bet:$ {row?.minimum_betting_amount}
                                                                                                                                            </div>
                                                                                                                                        </div></Button> :
                                                                                                                                    <></>
                                                                                                                    }
                                                                                                                </div>

                                                                                                                <div className='display-flex'>Admin Fee: {row?.admin_fee}%</div>
                                                                                                                <div className='display-flex'>Event Start At: {checkDates(row?.eventstart)}
                                                                                                                    {/* {new Date(row?.eventstart).toLocaleString({ day: 'numeric', month: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true })} */}
                                                                                                                </div>
                                                                                                            </TableCell>
                                                                                                        </TableRow>

                                                                                                    </TableBody>
                                                                                                </Table>
                                                                                            </TableContainer>

                                                                                        }

                                                                                    </div>
                                                                                ) : (
                                                                                    <></>
                                                                                )
                                                                            )
                                                                        )
                                                            )
                                                        }
                                                    </>
                                                ))}
                                            </>
                                            :
                                            <><p className='no-data'>
                                                <img src={nodata} />
                                                <div>
                                                    No Data Found
                                                </div>
                                            </p></>
                                        }
                                    </CustomTabPanel>

                                    <CustomTabPanel value={value} index={1}>
                                        {betHistory?.length > 0 ?
                                            <>
                                                {betHistory?.map((row, ind) =>
                                                    <div className='table-lob'>
                                                        <TableContainer component={Paper}>
                                                            <Table sx={{ minWidth: 650 }} aria-label="simple table">

                                                                <TableBody>

                                                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                        className='tb-body-row'
                                                                    // onClick={() => { navigate('/bet1v1', { state: row }) }}
                                                                    >

                                                                        <TableCell component="th" scope="row">
                                                                            <div>
                                                                                <div className='btn-1-table'>
                                                                                    {
                                                                                        row?.status == '1' ?
                                                                                            <Button
                                                                                                onClick={() => { navigate('/bet1v1', { state: { row, value } }) }}
                                                                                            ><div>
                                                                                                    <div className='vr-color'>
                                                                                                        Bet:${row?.minimum_betting_amount}
                                                                                                    </div>
                                                                                                    {/* {row.dnum1} <span>+</span>{'2'} */}
                                                                                                </div></Button> :
                                                                                            row?.status == '0' ?
                                                                                                <Button onClick={() => { getStatus({ status: 0 }) }}>
                                                                                                    <div>
                                                                                                        <div className='vr-color' >
                                                                                                            Bet:$ {row?.minimum_betting_amount}
                                                                                                        </div>
                                                                                                    </div></Button> :
                                                                                                row?.status == '2' ?
                                                                                                    <Button onClick={() => { getStatus({ status: 2 }) }}>
                                                                                                        <div>
                                                                                                            <div className='vr-color' >
                                                                                                                Bet:$ {row?.minimum_betting_amount}
                                                                                                            </div>
                                                                                                        </div></Button> :
                                                                                                    <></>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </TableCell>
                                                                        <TableCell component="th" scope="row">
                                                                            <div className='preffered-title'>
                                                                                <span> Bet Status: <span className='title-span'>{
                                                                                    row?.status == '0' ?
                                                                                        'Upcoming' :
                                                                                        row?.status == '1' ?
                                                                                            "Ongoing" :
                                                                                            row?.status == '2' ?
                                                                                                "Ended" :
                                                                                                row?.status == '3' ?
                                                                                                    "Blocked" :
                                                                                                    <></>
                                                                                }</span></span>
                                                                            </div>
                                                                            <div className='preffered-title'>
                                                                                <span>{row?.title}</span></div>

                                                                            <div className='tab-ava-main'>

                                                                                <div className='ava-name'>
                                                                                    {row?.teamA[0]?.name}
                                                                                    <div className='ava-innername username1'>
                                                                                        {row?.teamA[0]?.nickname}
                                                                                    </div>
                                                                                </div>
                                                                                <div className='flex-img'>
                                                                                    <div>
                                                                                        <Avatar className='profile1' alt="Remy Sharp" src={row?.teamA[0]?.img} />
                                                                                    </div>
                                                                                    <div className='midimg'>
                                                                                        <img alt="Remy Sharp" src={vs} />
                                                                                    </div>
                                                                                    <div>
                                                                                        <Avatar className='profile2' alt="Remy Sharp" src={row?.teamA[1]?.img} />
                                                                                    </div>
                                                                                </div>
                                                                                <div className='ava-name'>
                                                                                    {row?.teamA[1]?.name}
                                                                                    <div className='ava-innername username2'>
                                                                                        {row?.teamA[1]?.nickname}
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div className='display-flex'>Admin Fee: {row.admin_fee}%</div>
                                                                            <div className='display-flex'>Event Start At: {checkDates(row?.eventstart)}
                                                                                {/* {new Date(row?.eventstart).toLocaleString({ day: 'numeric', month: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true })} */}
                                                                            </div>

                                                                        </TableCell>

                                                                        <TableCell component="th" scope="row">
                                                                            <div>
                                                                                <div className='btn-1-table'>
                                                                                    {
                                                                                        row?.status == '1' ?
                                                                                            <Button onClick={() => { navigate('/bet1v1', { state: { row, value } }) }}><div>
                                                                                                <div className='vr-color'>
                                                                                                    Bet:${row?.minimum_betting_amount}
                                                                                                </div>
                                                                                                {/* {row.dnum1} <span>+</span>{'2'} */}
                                                                                            </div></Button> :
                                                                                            row?.status == '0' ?
                                                                                                <Button onClick={() => { getStatus({ status: 0 }) }}>
                                                                                                    <div>
                                                                                                        <div className='vr-color' >
                                                                                                            Bet:$ {row?.minimum_betting_amount}
                                                                                                        </div>
                                                                                                    </div></Button> :
                                                                                                row?.status == '2' ?
                                                                                                    <Button onClick={() => { getStatus({ status: 2 }) }}>
                                                                                                        <div>
                                                                                                            <div className='vr-color' >
                                                                                                                Bet:$ {row?.minimum_betting_amount}
                                                                                                            </div>
                                                                                                        </div></Button> :
                                                                                                    <></>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </TableCell>
                                                                    </TableRow>



                                                                </TableBody>
                                                            </Table>
                                                        </TableContainer>
                                                    </div>
                                                )}
                                            </> :
                                            <>

                                                <p className='no-data'>
                                                    <img src={nodata} />
                                                    <div>
                                                        No Data Found
                                                    </div>
                                                </p>

                                            </>
                                        }



                                    </CustomTabPanel>

                                    <CustomTabPanel value={value} index={2}>
                                        {betHistory?.length > 0 ?
                                            <>
                                                {betHistory?.map((row, ind) => (

                                                    row?.betting_type == 'djvsdj' ? (

                                                        <div className='table-lob'>
                                                            <TableContainer component={Paper}>
                                                                <Table sx={{ minWidth: 650 }} aria-label="simple table">

                                                                    <TableBody className='tb-body-row'>
                                                                        <TableRow

                                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}

                                                                        >
                                                                            <TableCell component="th" scope="row">
                                                                                <div className='preffered-title'>
                                                                                    <span> Bet Title: <span className='title-span'>{row?.title}</span></span>
                                                                                </div></TableCell>
                                                                            <TableCell component="th" scope="row">
                                                                                <div className='preffered-title'>
                                                                                    <span> Bet Type:  <span className='title-span'>{row?.betting_type}</span></span>
                                                                                </div>
                                                                            </TableCell>
                                                                            <TableCell component="th" scope="row">
                                                                                <div className='preffered-title'>
                                                                                    <span> Bet Status: {
                                                                                        row?.status == '0' ?
                                                                                            <span className='title-span'>upcoming</span> :
                                                                                            row?.status == '1' ?
                                                                                                <span className='title-span'>Ongoing</span> :
                                                                                                row?.status == '2' ?
                                                                                                    <span className='title-span'>Ended</span> :
                                                                                                    row?.status == '3' ?
                                                                                                        <span className='title-span'>Blocked</span> :
                                                                                                        <></>
                                                                                    }</span>
                                                                                </div>

                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow

                                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}

                                                                        >
                                                                            <TableCell component="th" scope="row">
                                                                                {/* {
                                row?.betting_type == 'teamvsteam' ?
                                    <div className='preffered-title'>
                                        <span>
                                            {row?.teamAname}
                                            {ind + 1}
                                        </span></div> :
                                    <></>
                            } */}
                                                                                <div>
                                                                                    <div className='btn-1-table'>
                                                                                        {
                                                                                            row?.status == '1' ?
                                                                                                <Button onClick={() => { navigate('/bet1v1', { state: { row, value } }) }}><div>

                                                                                                    <div className='vr-color'>
                                                                                                        Bet:$ {row?.minimum_betting_amount}
                                                                                                    </div>
                                                                                                </div></Button> :
                                                                                                row?.status == '0' ?
                                                                                                    <Button onClick={() => { getStatus({ status: 0 }) }}>
                                                                                                        <div>
                                                                                                            <div className='vr-color' >
                                                                                                                Bet:$ {row?.minimum_betting_amount}
                                                                                                            </div>
                                                                                                        </div></Button> :
                                                                                                    row?.status == '2' ?
                                                                                                        <Button onClick={() => { getStatus({ status: 2 }) }}>
                                                                                                            <div>
                                                                                                                <div className='vr-color' >
                                                                                                                    Bet:$ {row?.minimum_betting_amount}
                                                                                                                </div>
                                                                                                            </div></Button> :
                                                                                                        <></>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </TableCell>
                                                                            <TableCell component="th" scope="row">


                                                                                <div className='tab-ava-main'>
                                                                                    <div className='ava-name'>
                                                                                        {/* {row?.betting_type == 'teamvsteam' ? row?.teamAname : row?.teamA[0]?.name} */}
                                                                                        {row?.teamA[0]?.name}
                                                                                        <div className='ava-innername username1'>
                                                                                            {row?.teamA[0]?.nickname}
                                                                                        </div>
                                                                                        {/* {row?.betting_type == 'teamvsteam' ? <></> : <div className='ava-innername username1'>
                                        {row?.teamA[0]?.nickname}
                                    </div>} */}
                                                                                    </div>
                                                                                    <div className='flex-img'>
                                                                                        <div>
                                                                                            <Avatar className='profile1' alt="Remy Sharp" src={row?.teamA[0]?.img} />
                                                                                            {/* {row?.betting_type == 'teamvsteam' ?
                                            <Avatar className='profile1' alt="Remy Sharp" src={row?.teamAimg} /> :
                                            <Avatar className='profile1' alt="Remy Sharp" src={row?.teamA[0]?.img} />
                                        } */}
                                                                                        </div>
                                                                                        <div className='midimg'>
                                                                                            <img alt="Remy Sharp" src={vs} />
                                                                                        </div>
                                                                                        <div>
                                                                                            {/* {row?.betting_type == 'teamvsteam' ?
                                            <Avatar className='profile2' alt="Remy Sharp" src={row?.teamBimg} /> :
                                            <Avatar className='profile2' alt="Remy Sharp" src={row?.teamA[1]?.img} />
                                        } */}
                                                                                            <Avatar className='profile2' alt="Remy Sharp" src={row?.teamA[1]?.img} />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='ava-name'>
                                                                                        {row?.teamA[1]?.name}
                                                                                        <div className='ava-innername username2'>
                                                                                            {row?.teamA[1]?.nickname}
                                                                                        </div>
                                                                                        {/* {row?.betting_type == 'teamvsteam' ? row?.teamBname : row?.teamA[1]?.name} */}
                                                                                        {/* {row?.betting_type == 'teamvsteam' ? <></> :
                                        <div className='ava-innername username2'>
                                            {row?.teamA[1]?.nickname}
                                        </div>} */}
                                                                                    </div>
                                                                                </div>
                                                                                <div className='display-flex'>Admin Fee: {row?.admin_fee}%</div>
                                                                                <div className='display-flex'>Event Start At: {checkDates(row?.eventstart)}
                                                                                    {/* {new Date(row?.eventstart).toLocaleString({ day: 'numeric', month: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true })} */}
                                                                                </div>
                                                                            </TableCell>

                                                                            <TableCell component="th" scope="row">
                                                                                {
                                                                                    row?.betting_type == 'teamvsteam' ?
                                                                                        <div className='preffered-title'>
                                                                                            <span>
                                                                                                {row?.teamBname}
                                                                                                {/* {ind + 1} */}
                                                                                            </span></div> :
                                                                                        <></>
                                                                                }
                                                                                <div>
                                                                                    <div className='btn-1-table'>
                                                                                        {
                                                                                            row?.status == '1' ?
                                                                                                <Button onClick={() => { navigate('/bet1v1', { state: { row, value } }) }}><div>
                                                                                                    <div className='vr-color'>
                                                                                                        Bet:$ {row?.minimum_betting_amount}
                                                                                                    </div>

                                                                                                </div></Button> :
                                                                                                row?.status == '0' ?
                                                                                                    <Button onClick={() => { getStatus({ status: 0 }) }}>
                                                                                                        <div>
                                                                                                            <div className='vr-color' >
                                                                                                                Bet:$ {row?.minimum_betting_amount}
                                                                                                            </div>
                                                                                                        </div></Button> :
                                                                                                    row?.status == '2' ?
                                                                                                        <Button onClick={() => { getStatus({ status: 2 }) }}>
                                                                                                            <div>
                                                                                                                <div className='vr-color' >
                                                                                                                    Bet:$ {row?.minimum_betting_amount}
                                                                                                                </div>
                                                                                                            </div></Button> :
                                                                                                        <></>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </TableCell>

                                                                        </TableRow>
                                                                    </TableBody>
                                                                </Table>
                                                            </TableContainer>
                                                        </div>

                                                    ) : (
                                                        row?.betting_type == 'teamvsteam' ?
                                                            < div className='table-lob' >
                                                                <TableContainer component={Paper}>
                                                                    <Table sx={{ minWidth: 650 }} aria-label="simple table">

                                                                        <TableBody className='tb-body-row'>
                                                                            <TableRow
                                                                            // className='tb-body-row'
                                                                            >
                                                                                <TableCell component="th" scope="row" colSpan={10}>
                                                                                    <div className='preffered-title'>
                                                                                        <span> Bet Status: <span className='title-span'> {
                                                                                            row?.status == '0' ?
                                                                                                'Upcoming' :
                                                                                                row?.status == '1' ?
                                                                                                    "Ongoing" :
                                                                                                    row?.status == '2' ?
                                                                                                        "Ended" :
                                                                                                        row?.status == '3' ?
                                                                                                            "Blocked" :
                                                                                                            <></>
                                                                                        }</span></span>
                                                                                    </div>
                                                                                    <div className='preffered-title'>
                                                                                        <span>{row?.title}</span>
                                                                                    </div>

                                                                                </TableCell>
                                                                            </TableRow>
                                                                            <TableRow
                                                                            // className='tb-body-row'
                                                                            // onClick={() => { navigate('/bet1v1', { state: row }) }}
                                                                            >
                                                                                <>
                                                                                    {row.teams && row?.teams.map((data, index) => (
                                                                                        <>

                                                                                            <TableCell component="th" scope="row">
                                                                                                {
                                                                                                    data?.teamName?.map((teams) => (
                                                                                                        <>
                                                                                                            <div className='preffered-title'>
                                                                                                                <span>
                                                                                                                    <div>
                                                                                                                        <Avatar className='profile1' alt="Remy Sharp" src={teams?.img} />
                                                                                                                    </div>
                                                                                                                    {teams?.name}

                                                                                                                    {/* {ind + 2} */}
                                                                                                                </span></div>

                                                                                                        </>
                                                                                                    ))
                                                                                                }

                                                                                                <div>
                                                                                                    <div className='btn-1-table'>
                                                                                                        {
                                                                                                            row?.status == '1' ?
                                                                                                                <Button onClick={() => { navigate('/bet1v1', { state: { row, value } }) }}><div>
                                                                                                                    <div className='vr-color'>
                                                                                                                        Bet:$ {row?.minimum_betting_amount}
                                                                                                                    </div>

                                                                                                                </div></Button> :
                                                                                                                row?.status == '0' ?
                                                                                                                    <Button onClick={() => { getStatus({ status: 0 }) }}>
                                                                                                                        <div>
                                                                                                                            <div className='vr-color' >
                                                                                                                                Bet:$ {row?.minimum_betting_amount}
                                                                                                                            </div>
                                                                                                                        </div></Button> :
                                                                                                                    row?.status == '2' ?
                                                                                                                        <Button onClick={() => { getStatus({ status: 2 }) }}>
                                                                                                                            <div>
                                                                                                                                <div className='vr-color' >
                                                                                                                                    Bet:$ {row?.minimum_betting_amount}
                                                                                                                                </div>
                                                                                                                            </div></Button> :
                                                                                                                        <></>
                                                                                                        }

                                                                                                    </div>
                                                                                                </div>
                                                                                            </TableCell>
                                                                                            <>
                                                                                                {index < row?.teams?.length - 1 &&
                                                                                                    <TableCell component="th" scope="row">
                                                                                                        <div className='midimg2'>
                                                                                                            <img alt="Remy Sharp" src={vs} />
                                                                                                        </div>
                                                                                                    </TableCell>
                                                                                                }
                                                                                            </>
                                                                                        </>
                                                                                    ))}

                                                                                </>
                                                                            </TableRow>
                                                                            <TableRow
                                                                            // className='tb-body-row'
                                                                            >
                                                                                <TableCell component="th" scope="row" colSpan={10}>


                                                                                    <div className='display-flex'>Admin Fee: {row?.admin_fee}%</div>
                                                                                    <div className='display-flex'>Event Start At: {checkDates(row?.eventstart)}
                                                                                        {/* {new Date(row?.eventstart).toLocaleString({ day: 'numeric', month: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true })} */}
                                                                                    </div>

                                                                                </TableCell>
                                                                            </TableRow>


                                                                        </TableBody>
                                                                    </Table>
                                                                </TableContainer>
                                                            </div> : <></>
                                                    )
                                                ))}
                                            </>
                                            :
                                            <>
                                                <p className='no-data'>
                                                    <img src={nodata} />
                                                    <div>
                                                        No Data Found
                                                    </div>
                                                </p>
                                            </>
                                        }
                                    </CustomTabPanel>
                                    <CustomTabPanel value={value} index={3}>
                                        {
                                            betHistory?.length > 0 ?
                                                <>
                                                    {betHistory?.map((row, ind) =>
                                                        <div className='table-lob'>
                                                            <TableContainer component={Paper}>
                                                                <Table sx={{ minWidth: 650 }} aria-label="simple table" className='lobtable3'>
                                                                    <TableBody>

                                                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                        >

                                                                            <TableCell component="th" scope="row" colSpan={4}>
                                                                                <div className='preffered-title'>
                                                                                    <span> Bet Status: {
                                                                                        row?.status == '0' ?
                                                                                            'Upcoming' :
                                                                                            row?.status == '1' ?
                                                                                                "Ongoing" :
                                                                                                row?.status == '2' ?
                                                                                                    "Ended" :
                                                                                                    row?.status == '3' ?
                                                                                                        "Blocked" :
                                                                                                        <></>
                                                                                    }</span>
                                                                                </div>
                                                                                <div className='preffered-title'>
                                                                                    <span>{row.title}</span>
                                                                                    {/* <span className='feespan'>Admin Fee: {row?.admin_fee}%</span> */}
                                                                                </div>

                                                                            </TableCell>
                                                                        </TableRow>

                                                                        <TableRow

                                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                            className='tb-body-row'

                                                                        >
                                                                            {
                                                                                row?.teamA.map((data, ind) => (
                                                                                    <>
                                                                                        <TableCell component="th" scope="row">
                                                                                            <div className='tab-ava-main lobTable3'>
                                                                                                <div>
                                                                                                    <Avatar alt="Remy Sharp" src={data?.img} />
                                                                                                </div>
                                                                                                <div className='ava-name'>
                                                                                                    {data?.name}
                                                                                                    <div className='ava-innername'>
                                                                                                        {data?.nickname}
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </TableCell>
                                                                                    </>
                                                                                ))
                                                                            }

                                                                        </TableRow>
                                                                        <TableRow
                                                                        // onClick={() => { navigate('/vote') }}
                                                                        >
                                                                            <TableCell colSpan={4}>
                                                                                <div className='btn-1-table'>
                                                                                    {
                                                                                        row?.status == '1' ?
                                                                                            <Button onClick={() => { navigate('/vote', { state: { row, value } }) }}><div>
                                                                                                <div className='vr-color'>
                                                                                                    Bet:${row.minimum_betting_amount}
                                                                                                </div>
                                                                                            </div></Button> :
                                                                                            row?.status == '0' ?
                                                                                                <Button onClick={() => { getStatus({ status: 0 }) }}>
                                                                                                    <div>
                                                                                                        <div className='vr-color' >
                                                                                                            Bet:$ {row?.minimum_betting_amount}
                                                                                                        </div>
                                                                                                    </div></Button> :
                                                                                                row?.status == '2' ?
                                                                                                    <Button onClick={() => { getStatus({ status: 2 }) }}>
                                                                                                        <div>
                                                                                                            <div className='vr-color' >
                                                                                                                Bet:$ {row?.minimum_betting_amount}
                                                                                                            </div>
                                                                                                        </div></Button> :
                                                                                                    <></>
                                                                                    }
                                                                                </div>

                                                                                <div className='display-flex'>Admin Fee: {row?.admin_fee}%</div>
                                                                                <div className='display-flex'>Event Start At: {checkDates(row?.eventstart)}
                                                                                    {/* {new Date(row?.eventstart).toLocaleString({ day: 'numeric', month: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true })} */}
                                                                                </div>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    </TableBody>
                                                                </Table>
                                                            </TableContainer>

                                                        </div>
                                                    )}
                                                </> :
                                                <>
                                                    <p className='no-data'>
                                                        <img src={nodata} />
                                                        <div>
                                                            No Data Found
                                                        </div>
                                                    </p>
                                                </>
                                        }
                                    </CustomTabPanel>

                                    <CustomTabPanel value={value} index={4}>
                                        {
                                            betHistory?.length > 0 ?
                                                <>
                                                    {betHistory?.map((row, ind) =>
                                                        <div className='table-lob'>
                                                            <TableContainer component={Paper}>
                                                                <Table sx={{ minWidth: 650 }} aria-label="simple table" className='lobtable3'>
                                                                    <TableBody>

                                                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                        >

                                                                            <TableCell component="th" scope="row" colSpan={4}>
                                                                                <div className='preffered-title'>
                                                                                    <span> Bet Status: {
                                                                                        row?.status == '0' ?
                                                                                            'Upcoming' :
                                                                                            row?.status == '1' ?
                                                                                                "Ongoing" :
                                                                                                row?.status == '2' ?
                                                                                                    "Ended" :
                                                                                                    row?.status == '3' ?
                                                                                                        "Blocked" :
                                                                                                        <></>
                                                                                    }</span>
                                                                                </div>
                                                                                <div className='preffered-title'>
                                                                                    <span>{row.title}</span>
                                                                                </div>
                                                                            </TableCell>
                                                                        </TableRow>

                                                                        <TableRow

                                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                            className='tb-body-row'
                                                                        // onClick={() => { navigate('/lineup') }}
                                                                        >
                                                                            {
                                                                                row?.teamA.map((data, ind) => (
                                                                                    <TableCell component="th" scope="row">
                                                                                        <div className='tab-ava-main lobTable3'>
                                                                                            <div>
                                                                                                <Avatar alt="Remy Sharp" src={data.img} />
                                                                                            </div>
                                                                                            <div className='ava-name'>
                                                                                                {data.name}
                                                                                                <div className='ava-innername'>
                                                                                                    {data.nickname}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </TableCell>
                                                                                ))
                                                                            }

                                                                        </TableRow>

                                                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                        // onClick={() => { navigate('/lineup') }}
                                                                        >
                                                                            <TableCell component="th" scope="row" colSpan={4}>

                                                                                <div className='btn-1-table line'>
                                                                                    {
                                                                                        row?.status == '1' ?
                                                                                            <Button onClick={() => { navigate('/lineup', { state: { row, value } }) }}><div>
                                                                                                <div className='vr-color'>
                                                                                                    Bet:${row.minimum_betting_amount}
                                                                                                </div>
                                                                                            </div></Button> :
                                                                                            row?.status == '0' ?
                                                                                                <Button onClick={() => { getStatus({ status: 0 }) }}>
                                                                                                    <div>
                                                                                                        <div className='vr-color' >
                                                                                                            Bet:$ {row?.minimum_betting_amount}
                                                                                                        </div>
                                                                                                    </div></Button> :
                                                                                                row?.status == '2' ?
                                                                                                    <Button onClick={() => { getStatus({ status: 2 }) }}>
                                                                                                        <div>
                                                                                                            <div className='vr-color' >
                                                                                                                Bet:$ {row?.minimum_betting_amount}
                                                                                                            </div>
                                                                                                        </div></Button> :
                                                                                                    <></>
                                                                                    }
                                                                                </div>

                                                                                <div className='display-flex'>Admin Fee:{row?.admin_fee}%</div>
                                                                                <div className='display-flex'>Event Start At: {checkDates(row?.eventstart)}
                                                                                    {/* {new Date(row?.eventstart).toLocaleString({ day: 'numeric', month: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true })} */}
                                                                                </div>
                                                                            </TableCell>
                                                                        </TableRow>

                                                                    </TableBody>
                                                                </Table>
                                                            </TableContainer>
                                                        </div>
                                                    )}
                                                </> :
                                                <>
                                                    <p className='no-data'>
                                                        <img src={nodata} />
                                                        <div>
                                                            No Data Found
                                                        </div>
                                                    </p>
                                                </>
                                        }

                                    </CustomTabPanel>
                                </Box>
                            </Grid>
                        </Grid>

                    </Grid>

                    {/* <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                        <RightSideBar />
                    </Grid> */}

                </Box>

            </div >
        </>
    )
}

export default Lobby
