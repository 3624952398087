import React, { useState, useRef, useEffect } from 'react'
import './Deposit.css'
import { Grid } from '@mui/material'
import TopHeader from '../Header/TopHeader'
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';
import btc from '../../images/bitcoin.png'
import qr from "../../images/qr.png"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import downs from "../../images/download.gif"
import Typography from "@mui/material/Typography";
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import { Link } from 'react-router-dom'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Menuclose from '../../images/Menuclose.png'
import { ToastContainer, toast } from 'react-toastify';
import Axios from '../../Axios';
import consts from '../../Constant';
import { useNavigate } from 'react-router-dom';
import ClipLoader from "react-spinners/ClipLoader";




function Deposit() {
    const [age, setAge] = React.useState(10);
    const token = window.localStorage.getItem('iphephile')
    const handleChange = (event) => {
        setAge(event.target.value);
    };

    const [age2, setAge2] = React.useState(10);

    const handleChange2 = (event) => {
        setAge2(event.target.value);
    };

    const [inputValue, setInputValue] = useState('GIEHIHFD54FWEFUHYW5F4CQWGD');
    const [copy, setCopy] = useState(false)

    const copyText = () => {
        navigator.clipboard.writeText(inputValue);
        setCopy(true)
    };
    const navigate = useNavigate()

    const [tab, setTab] = useState([
        { n1: '2022-05-26 12:48:13', n2: 'C9f9fef59243e9 b2111aceffb', n3: 'C9f9fef59243e9 b2111aceffb', n4: 'C9f9fef59243e9d32', n5: 'C9f9fef59243e9d32' },
        { n1: '2022-05-26 12:48:13', n2: 'C9f9fef59243e9 b2111aceffb', n3: 'C9f9fef59243e9 b2111aceffb', n4: 'C9f9fef59243e9d32', n5: 'C9f9fef59243e9d32' },
        { n1: '2022-05-26 12:48:13', n2: 'C9f9fef59243e9 b2111aceffb', n3: 'C9f9fef59243e9 b2111aceffb', n4: 'C9f9fef59243e9d32', n5: 'C9f9fef59243e9d32' },
    ])


    const [amounterr, setAmounterr] = useState("")
    const [amount, setAmount] = useState()
    // const amount = useRef(null)
    const [amountDeposit, setAmountDeposit] = useState()

    const [err, seterr] = useState("")
    const [depositHistory, setDepositHistory] = useState([])
    const [isload, setLoad] = useState(false)
    const [isloader, setLoader] = useState(false)

    const handleAmount = (no) => {

        setAmounterr("")
        setAmount(no)

    }

    const deposit = async () => {
        setLoad(true)

        try {
            const depo = await Axios.get(`/users/depositHistory`,
                {
                    headers: {
                        Authorization: window.localStorage.getItem('iphephile')
                    }
                })
            if (depo) {
                const hist = depo?.data?.result
                setDepositHistory(hist)
                setLoad(false)

            }
            else {
                setLoad(false)
                setDepositHistory([])

            }

        } catch (error) {
            console.log(error)
            setLoad(false)
        }
    }
    useEffect(() => {
        deposit()
    }, [token])

    const onSubmit = async () => {
        try {
            if (!amount) {
                setAmounterr("Please Enter Amount")
            }
            else {
                setLoader(true)
                const { data } = await Axios.post(`/auth/makePayment`, {
                    amount: amount
                }, {
                    headers: {
                        "Authorization": window.localStorage.getItem('iphephile')
                    }
                })
                // console.log(data, data?.result?.url, 'data');

                if (data.success == false && data.message == 'Admin Blocked You') {
                    setLoader(false)
                    toast.error(data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });

                    window.localStorage.removeItem("iphephile")
                    window.localStorage.removeItem("iphephileid")
                    setTimeout(() => {
                        navigate(`${consts.route}/`)
                    }, 2000);
                }
                else if (data.success == true) {
                    setLoader(false)
                    window.location.replace(data?.result?.url);

                }
            }
        } catch (error) {
            console.log(error, 'error')
            setLoader(false)
            toast.error(error?.response?.data?.message)
        }


    }


    const handleAmountChange = (e) => {
        const inputValue = e.target.value;
        if (inputValue < 0) {
            // If negative, set amount to empty string and show error
            setAmount('');
            setAmounterr("Invalid Format");
        } else {
            // If not negative, update the amount and clear error
            setAmount(inputValue);
            setAmounterr('');
        }
    };


    return (
        <>
            <ToastContainer />
            <div className='deposit'>
                <Grid container spacing={0} className='depo-cont'>
                    <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                        <TopHeader />
                    </Grid>
                    <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Link to="/lobby" className="backmenu">
                            <img src={Menuclose} alt="close" />
                        </Link>
                        <div className='deposit-comp'>
                            Deposit
                        </div>
                    </Grid>
                    <Grid xs={12} sm={8} md={8} lg={5} xl={5}>
                        <div className='depo-form'>

                            <div className='select-cry'>
                                Deposit
                            </div>
                            <div className='input-depo'>
                                <FormControl fullWidth>

                                    <TextField id="outlined-basic"

                                        onChange={handleAmountChange}
                                        name="amount"
                                        // type="number"
                                        onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                                        value={amount}
                                        // onKeyPress={preventMinus}
                                        onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                                event.preventDefault();
                                                setAmounterr("Enter Valid Number")
                                            }
                                        }}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                            endAdornment: <InputAdornment position="end">USD</InputAdornment>,
                                        }}

                                    />

                                </FormControl>



                                {/* <button onClick={copyText}>{copy ? 'Copied!' : 'Copy'}</button> */}
                            </div>

                            {amounterr ? <div className='errorDivi'>{amounterr}</div> : <></>}

                            <div className="want-deposite">
                                <Typography variant="h6" component="h6">
                                    How much do you want to deposit?
                                </Typography>

                                <div className="amount-payment-button-adds">
                                    <Button variant="outlined" onClick={() => { handleAmount(1000) }}>$ 1000</Button>
                                    <Button variant="outlined" onClick={() => { handleAmount(5000) }}>$ 5000</Button>
                                    <Button variant="outlined" onClick={() => { handleAmount(10000) }}>$ 10,000</Button>
                                    <Button variant="outlined" onClick={() => { handleAmount(20000) }}>$ 20,000</Button>
                                </div>

                            </div>

                            <div className="blue-btn">
                                {
                                    isloader ? <ClipLoader color={'#00008B'} /> :
                                        <Button onClick={() => { onSubmit() }} >Deposit</Button>
                                }
                            </div>


                            {/* <div className='qr-down-div'>
                            {down ? <img src={downs} style={{ width: "50px", height: "50px" }} /> : <button onClick={handleDownload}>DOWNLOAD</button>}
                        </div> */}

                        </div>
                    </Grid>
                    <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div className='deposit-comp responsive'>
                            Recent Deposit History
                        </div>
                    </Grid>

                    {isload ?
                        <>
                            <ClipLoader color={'#00008B'} />
                        </> :
                        <>
                            {depositHistory && depositHistory?.length > 0 ?
                                <>
                                    <Grid xs={12} sm={12} md={12} lg={10} xl={9}>
                                        <div className='depo-table'>
                                            <TableContainer component={Paper}>
                                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>Date & Time</TableCell>
                                                            <TableCell align="right">Payment Type</TableCell>
                                                            <TableCell align="right">Amount</TableCell>
                                                            <TableCell align="right">Currency</TableCell>
                                                            <TableCell align="right">Payment Id</TableCell>
                                                            <TableCell align="right">Status</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {depositHistory?.map((row, index) => (
                                                            <TableRow
                                                                key={row.n1}
                                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                            >
                                                                <TableCell component="th" scope="row">
                                                                    {new Date(row.createdAt).toLocaleString('en-US')}
                                                                </TableCell>
                                                                <TableCell component="th" scope="row">
                                                                    {row.others.payment_method_types}
                                                                </TableCell>
                                                                <TableCell component="th" scope="row">
                                                                    $ {row.amount}
                                                                </TableCell>
                                                                <TableCell component="th" scope="row">
                                                                    {row.others.currency.toUpperCase()}
                                                                </TableCell>
                                                                <TableCell component="th" scope="row">
                                                                    {row.payment_intent || '- -'}
                                                                </TableCell>
                                                                <TableCell component="th" scope="row">
                                                                    {
                                                                        row?.status == 0 ?
                                                                            <p style={{ color: "orange" }}>Pending</p> :
                                                                            row.status == 1 ?
                                                                                <p style={{ color: "green" }}>Approved</p> :
                                                                                row.status == 2 ?
                                                                                    <p style={{ color: "red" }}>Declined</p> :
                                                                                    <></>
                                                                    }
                                                                    {/* {row.payment_status} */}
                                                                </TableCell>
                                                                {/* <TableCell component="th" scope="row">
                                                <div className={row.completed ? 'completed' : 'incompleted'}>
                                                    <Button onClick={() => handleStatusChange(index)}>
                                                        {row.completed ? 'Completed' : 'InCompleted'}
                                                    </Button>
                                                </div>

                                            </TableCell> */}
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </div>

                                    </Grid>
                                </>
                                :
                                <>
                                    <tr>
                                        <td colSpan="6" className="record-txt norecord-found">No Records Found</td>
                                    </tr>
                                </>}
                        </>}
                </Grid>
            </div>
        </>
    )
}

export default Deposit
