import React, { useEffect, useState, useRef } from 'react'
import moment from 'moment/moment.js';
import io from "socket.io-client";
import Axios from "../../Axios.js"
import Box from '@mui/material/Box';
import ClipLoader from "react-spinners/ClipLoader";
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import TopHeader from '../Header/TopHeader';
import consts from '../../Constant'
import { Button } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import RefreshIcon from '@mui/icons-material/Refresh';
import SendIcon from '@mui/icons-material/Send';
import artist1 from '../../images/artist1.png'
import artist3 from '../../images/artist4.png'
import Avatar from '@mui/material/Avatar';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import Stack from '@mui/material/Stack';
import './Ticket.css'
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import { Description } from '@mui/icons-material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
// import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';


const Ticket = () => {






    const [display, setdisplay] = useState()

    const navigate = useNavigate()

    //search

    const [search, setSearch] = useState()

    const [message, setMessage] = useState([]);
    const [updated, setUpdated] = useState('');
    //let socket = io.connect(consts.SocketUrl)
    let socket = io.connect(consts.SocketUrl, { path: "/socket" });
    const [messageList, setmessageList] = useState([])
    const [msg, setMsg] = useState(false)
    const [names, setNames] = useState()
    const [ids, setIds] = useState()
    const [_id, setId] = useState()
    const [rooms, setRooms] = useState([])
    const [roomdetails, setRoomdetails] = useState({})
    const [room, setRoom] = useState()
    const [isloader, setLoader] = useState(false)
    const handleMsg = () => { setMsg(true) }
    const [branch, setbranch] = useState()
    const [brancherr, setbrancherr] = useState("")
    const tokens = localStorage.getItem("iphephile");
    const [anchorEl, setAnchorEl] = React.useState(null);
    const isMounted = useRef(false);
    const searching = useRef();
    const role = localStorage.getItem("role");

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;




    useEffect(() => {
        if (room != undefined) {
            if (!isMounted.current) {
                socket.emit('join', { room: room })


                socket.on("receive", (data) => {

                    setmessageList((list) => [...list, data])
                });
                isMounted.current = true;
            }
            else {
                console.log("Room")
            }
        }

    }, [room])

    useEffect(() => {

        socket.on("receive", (data) => {

            setmessageList((list) => [...list, data])
        });
    }, [])



    useEffect(() => {
        getMyRoom()
    }, [searching?.current?.value])



    const handleChangeSend = (event) => {
        setUpdated(event.target.value);

    };

    const handleEnterKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleClickSend();
        }
    };


    const handleClickSend = (e) => {
        // 👇 "message" stores input field value

        if (updated?.trim() !== '') {
            createMessage()
            // setMessage([...message, updated])
            setUpdated('')
        }
    };

    const handleDeleteTodo = (index) => {
        const newTodos = [...message];
        newTodos.splice(index, 1);
        setMessage(newTodos);
    };


    const [imageUrl, setImageUrl] = useState(null);


    const getMyRoom = async () => {
        try {
            const findData = {

                ticket: search
            }

            const datas = await Axios.post("/message/get_room", findData, {
                headers: {
                    Authorization: tokens,
                },
            })
                .then((res) => {

                    if (res?.data?.success) {

                        setRooms(res?.data?.result)
                        setIds(res?.data?.user_id)
                    }
                    else {
                        //toast.error(err?.response?.data?.message)
                        setRooms([])
                        setIds(res?.data?.user_id)

                    }
                }).catch((err) => {
                    // console.log(err,"err")

                })

        } catch (error) {
            // console.log(error,"error");
        }

    }

    const getRoom = async (data) => {
        try {
            var findData = {
                _id: data,

            }


            const datas = await Axios.post("/message/get_one_room", findData, {
                headers: {
                    Authorization: tokens,
                },
            })
                .then((res) => {

                    if (res?.data?.success) {
                        if (res?.data?.result !== "") {
                            setdisplay(true)
                            getMyRoom()
                            setRoomdetails(res?.data?.result)
                            setRoom(res?.data?.result?.room_id)
                            getMessage(res?.data?.result?.room_id)
                            setId(res?.data?.result?._id)
                            socket.emit("join", {
                                room: res?.data?.result?.room_id,
                            })
                            setNames(res?.data?.result?.Users?.user_name)
                            setIds(localStorage.getItem("iphephileid"))
                        }
                        else {
                            setdisplay(false)
                        }

                    }
                    else {
                        //toast.error(err?.response?.data?.message)
                        setRoomdetails({})


                    }
                }).catch((err) => {
                    // console.log(err,"err")

                })

        } catch (error) {
            // console.log(error,"error");
        }

    }

    const getMessage = async (data) => {
        try {
            var findData = {
                room_id: data,

            }


            const datas = await Axios.post("/message/get_message", findData, {
                headers: {
                    Authorization: tokens,
                },
            })
                .then((res) => {

                    if (res?.data?.success) {
                        handleMsg()
                        setmessageList(res?.data?.result)


                        socket.emit("join", {
                            room: data,
                        })

                    }
                    else {
                        //toast.error(err?.response?.data?.message)
                        setmessageList([])


                    }
                }).catch((err) => {
                    // console.log(err,"err")

                })

        } catch (error) {
            // console.log(error,"error");
        }

    }

    const createMessage = async () => {

        try {

            socket.emit("join", {
                room: room,
            })
            const time = `${Date.now()}`
            const sendItem = {
                from: ids,
                sender_name: names,
                room: room,
                time: time,
                message: updated
            }
            socket.emit("send", {
                from: ids,
                sender_name: names,
                room: room,
                time: time,
                message: updated,
            });
            const payload = {
                _id: _id,
                room_id: room,
                time: time,
                message: updated,
            }
            const { data } = await Axios.post(`/message/create_message`, payload, {
                headers: {

                    Authorization: tokens
                }
            })
            if (data?.success === true) {

                // getOneTicketMesg(room)
                getMessage(room)
                setUpdated('')


            }
            else {
                toast.error(data?.message)
            }
        } catch (error) {
            console.log("🚀 ~ file: Header.js:370 ~ getActivity ~ error:", error)
        }


    }

    // const reset = () => {
    //     setstatus("All")
    //     setName()

    // }
    const amounts = 100
    const createRoom = async () => {

        try {
            if (!branch) {
                setbrancherr("Enter the Description")
            }
            else {
                setLoader(true)
                const { data } = await Axios.post(`/message/create_room`, {
                    description: branch
                }, {
                    headers: {
                        Authorization: tokens,
                    },
                })
                if (data?.success === true) {

                    getRoom(data?.result?._id)
                    handleModelClose()
                    getMyRoom()


                }
                else {
                    toast.error(data?.message)
                    handleModelClose()

                }
            }

        } catch (error) {
            console.log("error:", error)
            handleModelClose()

        }


    }


    const [anchorEl2, setAnchorEl2] = useState(null);

    const handleplaylist = (event) => {
        setAnchorEl2(event.currentTarget);
    };

    const handleClose2 = () => {
        setAnchorEl2(null);
    };

    const open2 = Boolean(anchorEl2);
    const id2 = open2 ? 'simple-popover' : undefined;

    const [openModel, setOpenModel] = React.useState(false);
    const handleOpen = () => setOpenModel(true);
    const handleModelClose = () => {
        setbranch(); setbrancherr("");
        setLoader(false)

        setOpenModel(false)
    };


    const changeValue = () => {
        searching.current.value = null;
        setSearch()

    };

    // const handleClickSend = (e) => {

    //     if (updated?.trim() !== '') {
    //         setMessage([...message, updated])
    //         setUpdated('')
    //     }
    // };

    // const handleDeleteTodo = (index) => {
    //     const newTodos = [...message];
    //     newTodos.splice(index, 1);
    //     setMessage(newTodos);
    // };

    // const handleEnterKeyPress = (e) => {
    //     if (e.key === 'Enter') {
    //         createMessage();
    //     }
    // };




    return (
        <div className='landing-page'>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={0}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <TopHeader />
                    </Grid>
                </Grid>
                <div className='back-btn ticket' onClick={(() => { navigate(-1) })} >
                    <ArrowBackIcon />
                </div>

                <Grid container spacing={0} sx={{ justifyContent: 'center', marginTop: '100px' }}>
                    <Grid xs={11} sm={11} md={4} lg={4} xl={3} >

                        {/* <div className="library-head-div">
<div className="library-para">
Messages
</div>

</div> */}
                        <div className='msglist'>
                            <div className='msg-list-head'>
                                <div className='chats'>
                                    Chats
                                </div>

                            </div>
                            <div className='msg-list-input'>
                                {/* <SearchOutlinedIcon />  <input type='search' placeholder='Search Messenger' /> */}

                                <FormControl fullWidth sx={{ m: 0.5 }}>
                                    {/* <InputLabel htmlFor="outlined-adornment-amount">Amount</InputLabel> */}
                                    <OutlinedInput
                                        id="outlined-adornment-amount"
                                        startAdornment={<InputAdornment position="start"><SearchOutlinedIcon /></InputAdornment>}
                                        endAdornment={<InputAdornment position="end" onClick={() => { changeValue() }} style={{ 'cursor': 'pointer' }}><RefreshIcon /></InputAdornment>}
                                        // label="Amount"
                                        inputRef={searching}
                                        className='msg-input'
                                        placeholder='Search by Ticket Id or Description'
                                        onChange={((e) => { setSearch(e.target.value) })}
                                        value={search}
                                    />
                                </FormControl>

                            </div>
                            <div className='msg-list-area'>
                                {rooms?.map((row, ind) => {
                                    return (
                                        <div key={ind} className='msg-list-chat-main' onClick={(() => { getRoom(row?._id) })}>
                                            <div>
                                                <Avatar sx={{ width: 40, height: 40 }} alt="Remy Sharp" src={row?.img} />
                                            </div>
                                            <div className='msg-list-chat-name'>
                                                {row?.description}
                                                <div className='chat-desc' id='sentence'>
                                                    {row?.room_id} - {moment(new Date(row?.updatedAt)).fromNow()}

                                                </div>
                                            </div>
                                            {row?.Messagecount !== 0 ? <div className='notify-inner'>
                                                {row?.Messagecount}
                                            </div> : <></>}

                                        </div>
                                    )
                                })}

                            </div>
                            <div className="colab-text-input2">
                                <Button className='write-msg-btn' onClick={() => { handleOpen() }}>Create Ticket</Button>
                            </div>
                        </div>

                    </Grid>
                    {display == true ? <Grid xs={11} sm={11} md={7} lg={7} xl={7}>


                        <div className="colab-msg-box">
                            <div className="colab-avatar-cont vr">
                                <Stack direction="row" spacing={2}>
                                    <Avatar sx={{ width: 30, height: 30 }} alt="Remy Sharp" src={roomdetails?.Admin?.ProfilePicture} />
                                    <h5 className='msg-reply-h5'>
                                        {roomdetails?.Admin?.name}
                                        <p>
                                            {roomdetails?.room_id}
                                        </p>
                                    </h5>


                                </Stack>

                            </div>

                            <div className="colab-chat-area msg">


                                <div className="chat-area-inner-text vr">
                                    {/* <div>
                                        <Avatar sx={{ width: 30, height: 30 }} alt="Remy Sharp" src={perChat.img} />
                                    </div>
                                    <div className="collab-send-text-main">
                                        <div className="username">{roomdetails?.description}</div>
                                        <div className="txt1">{new Date(roomdetails?.createdAt).toLocaleString(undefined, { 'hour12': true })}</div>

                                    </div> */}
                                </div>
                                {messageList.map((todo, ind) => {
                                    return (

                                        <div className={String(String(todo?.from) == localStorage.getItem("iphephileid")) == "true" ? "chat-area-inner-text user vr" : "chat-area-inner-text vr"} key={ind}>
                                            <div>

                                                <Avatar sx={{ width: 30, height: 30 }} alt="Remy Sharp" src={todo?.Users?.profilePicture} />
                                            </div>

                                            <div className={String(String(todo?.from) == localStorage.getItem("iphephileid")) == "true" ? "collab-send-text-main2 user" : "collab-send-text-main"}>
                                                <div className="username">{String(String(todo?.from) == localStorage.getItem("iphephileid")) == "true" ? "You" : <>{todo?.sender_name}</>} </div>
                                                <div className="txt1">{todo?.message}</div>
                                                <div className="time">{new Date(Number(todo?.time)).toLocaleString(undefined, { 'hour12': true })}</div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>


                            <div className="colab-text-input">

                                <div>
                                    <input type="text" placeholder="Start typing..."
                                        value={updated}
                                        onChange={(e) => { handleChangeSend(e) }}


                                    />
                                </div>
                                <div className="colab-send">
                                    <SendIcon onClick={() => { handleClickSend() }} />
                                </div>

                            </div>
                        </div>
                    </Grid> : <></>}


                </Grid>
            </Box>

            <Modal
                open={openModel}
                onClose={handleModelClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className="withdraw-popup" style={{ "height": "400px !important" }}>
                    <div className="popup">
                        <CloseIcon onClick={handleModelClose} />
                    </div>

                    <div className="add-new">
                        <p className="new-act-head">Create Ticket</p>
                    </div>
                    <div className="accnt-detail add-account">

                        <p>Receiver</p>

                        <FormControl
                            sx={{ m: 1, width: "100%" }}
                            variant="outlined"
                            className="input-box"
                        >
                            <OutlinedInput
                                className="input-box-txt"
                                id="outlined-adornment-weight"
                                aria-describedby="outlined-weight-helper-text"
                                name="branch"
                                value={"Super Admin"}

                                inputProps={{
                                    "aria-label": "weight",
                                }}
                            />
                        </FormControl>
                        <p>Description</p>

                        <FormControl
                            sx={{ m: 1, width: "100%" }}
                            variant="outlined"
                            className="input-box"
                        >
                            <OutlinedInput
                                className="input-box-txt"
                                id="outlined-adornment-weight"
                                aria-describedby="outlined-weight-helper-text"
                                name="branch"
                                value={branch}
                                onChange={(e) => {
                                    setbranch(e.target.value);
                                    setbrancherr("")
                                }}
                                inputProps={{
                                    "aria-label": "weight",
                                }}
                            />
                        </FormControl>
                        {brancherr ? (
                            <div className="errorDivi">{brancherr}</div>
                        ) : (
                            <></>
                        )}


                        <div className="add-acct cnt-add" style={{ "marginTop": "20px" }}>
                            {isloader == false ? <Button onClick={() => { createRoom() }}>
                                Create Ticket
                            </Button> :
                                <Button>
                                    <ClipLoader />Processing ...

                                </Button>}
                        </div>
                    </div>
                </Box>
            </Modal>

        </div >
    )
}

export default Ticket
