import React, { useEffect } from 'react'
import { useState } from 'react'
import { makeStyles } from '@mui/styles';
import { useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import dayjs from 'dayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import './TopHeader.css'
import hlogo from "../../images/soundlogo.png"
import { NavLink } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import ava from "../../images/sound-avatar.png"
import { Link } from 'react-router-dom'
// import { Toaster, toast } from 'react-hot-toast'
import { ToastContainer, toast } from 'react-toastify';
import consts from '../../Constant'
import LogoutIcon from '@mui/icons-material/Logout';
import notification from '../../images/notification.svg';
import Axios from "../../Axios.js"
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Badge from '@mui/material/Badge';
import Spinner from 'react-bootstrap/Spinner';
import CircularProgress from '@mui/material/CircularProgress';
import ClipLoader from "react-spinners/ClipLoader";
import Backdrop from '@mui/material/Backdrop';
import verify from '../../images/verify.gif'
import verify2 from '../../images/icons8-verify-22.png'

const drawerWidth = 240;

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));


const useStyles = makeStyles({
    root: {
        background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
        border: 0,
        borderRadius: 3,
        boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
        color: 'white',
        height: 48,
        padding: '0 30px',
    },
    flexcls: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    dashboarbodycls: {
        background: 'transparent !important',
        borderRadius: '0px !important',
        boxShadow: 'none !important',
    },
    passwordeye: {
        borderRadius: '3px',
        background: 'rgba(255, 255, 255, 1)',
        marginLeft: '0px !important',
        marginRight: '0px !important',
        boxShadow: '4px 4px 15px 0px rgba(149, 206, 255, 0.74) inset'
    },
    profiletabhead: {
        borderBottom: '1px solid rgba(255, 255, 255, 0.10)',
    },
    popupboxcls: {
        borderRadius: '3px',
        background: 'rgba(255, 255, 255, 1)',
        background: 'rgba(5, 40, 90, 1) !important',
        width: '400px !important',
        '@media (max-width: 575.98px)': {
            width: '95% !important',
        },
    },
    loginpopupboxcls: {
        scrollbarWidth: 'none !important',
        overflowY: 'visible',
        borderRadius: '3px',
        border: 'none !important',
        background: 'rgba(5, 40, 90, 1) !important',
        width: '500px !important',
        '@media (max-width: 575.98px)': {
            width: '95% !important',
            height: 'auto !important',
        },
        '&::-webkit-scrollbar': {
            display: 'none !important'
        },
        '&::-webkit-scrollbar': {
            width: '0.5em !important'
        }

    },
    registerpopupboxcls: {
        height: '85% !important',
        scrollbarWidth: 'none !important',
        overflowY: 'visible',
        borderRadius: '3px',
        boxShadow: 'rgba(149, 206, 255, 0.74) !important',
        width: '500px !important',
        '@media (max-width: 991.98px)': {
            width: '95% !important',
            height: '95% !important',
        },
        '&::-webkit-scrollbar': {
            display: 'none !important'
        },
        '&::-webkit-scrollbar': {
            width: '0.5em !important'
        },
        '@media (min-width: 1440pxpx) & (max-width: 1999.98px)': {
            height: '70% !important',
        }

    },
    searchWrap: {
        transition: 'all 0.3s ease',
        opacity: 0,
        visibility: 'hidden',
        '&.active': {
            opacity: 1,
            visibility: 'visible',
        }
    }

});

const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#000',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#000' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'rgba(5, 40, 90, 1)',
    border: 'none',
    boxShadow: 24,
    p: 4,
    '@media (max-width: 991.98px)': {
        p: 2,
    }
};

const TopHeader = () => {
    // const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };
    const classes = useStyles();
    const navigate = useNavigate();
    const token = window.localStorage.getItem("iphephile")
    const inpassref = useRef(null);
    const fmail = useRef(null)
    const inputconfrimRef = useRef(null);
    const email = useRef(null);
    const name = useRef(null);
    const phone = useRef(null);
    const address = useRef(null);
    const city = useRef(null);
    const country = useRef(null);
    const lemail = useRef(null);
    const lpswd = useRef(null);
    const [dob, setDob] = useState(null)
    const [confirmPass, setConfirmPass] = useState(null)
    const [emailerr, setemailerr] = useState(null)
    const [usernameerr, setusernameerr] = useState(null)
    const [addresserr, setaddresserr] = useState(null)
    const [countryerr, setcountryerr] = useState(null)
    const [cityerr, setcityerr] = useState(null)
    const [doberr, setdoberr] = useState(null)
    const [phoneerr, setPhoneerr] = useState(null)
    const [passworderr, setpassworderr] = useState(null)
    const [cpassworderr, setcpassworderr] = useState(null)
    const [lemailerr, setLemailerr] = useState(null)
    const [lpswerr, setLPswerr] = useState(null)
    const [open, setOpen] = useState(false);
    const [opens, setOpens] = useState(false);
    const [openss, setOpenss] = useState(false);
    const [profileOpen, setProfileOpen] = useState(false);
    const [detailOpen, setDetailOpen] = useState(false);
    const [amount, setAmount] = useState()
    const [mailerr, setmailerr] = useState(null)
    const [profilepic, setProfilePic] = useState('')
    const [users, setusers] = useState(null)
    // console.log(users, 'users');
    const [img, setImg] = useState('')
    const [eye, setEye] = useState(false)
    const [notificationMessage, setNotificationMessage] = useState([])
    const [notificationCount, setNotificationCount] = useState(0)
    const [loader, setLoader] = useState(false)
    const [selectedDate, setSelectedDate] = useState(null);


    const handleDateChange = (e) => {

        // var oriDate = `${Number(e?.$M) + 1}/${e?.$D}/${e?.$y} ${e?.$H}:${e?.$m}`

        // console.log(new Date(oriDate).getFullYear(), new Date().getFullYear())
        // setSelectedDate(oriDate)
        // setDob(new Date(oriDate).getTime());

        // setdoberr(null)
        try {
            const date = new Date(e?.$y, e?.$M, e?.$D, e?.$H, e?.$m);
            var oriDate = `${Number(e?.$M) + 1}/${e?.$D}/${e?.$y} ${e?.$H}:${e?.$m}`

            // console.log(date.getFullYear(), new Date().getFullYear());

            setSelectedDate(new Date(oriDate));

            setDob(date.getTime());

            setdoberr(null);
        } catch (error) {
            console.error("Error occurred while handling date change:", error);

        }

    };



    const wrapperRef = useRef(null);
    const proRef = useRef(null);

    // useEffect(() => {
    //     const handleClickOutside = (event) => {
    //         if (proRef.current && !proRef.current.contains(event.target)) {
    //             // Check if the click target is inside the dropdown
    //             // console.log("outside", wrapperRef.current.contains(event.target))
    //             const dropdownElements = document.querySelectorAll('.user-profile-dropdwn profile-drop-bg *');
    //             let isInsideDropdown = false;
    //             dropdownElements.forEach(element => {
    //                 if (element.contains(event.target)) {
    //                     isInsideDropdown = true;
    //                 }
    //             });
    //             if (!isInsideDropdown) {
    //                 setProfileOpen(false);

    //             }

    //         }
    //     };

    //     document.addEventListener('mousedown', handleClickOutside);

    //     return () => {
    //         document.removeEventListener('mousedown', handleClickOutside);
    //     };
    // }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                // Check if the click target is inside the dropdown
                // console.log("outside", wrapperRef.current.contains(event.target))
                const dropdownElements = document.querySelectorAll('.user-profile-dropdwn *');
                let isInsideDropdown = false;
                dropdownElements.forEach(element => {
                    if (element.contains(event.target)) {
                        isInsideDropdown = true;
                    }
                });
                if (!isInsideDropdown) {
                    setDetailOpen(false);

                }

            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    // const wrapperRefMobile = useRef(null);


    // useEffect(() => {
    //     const handleClickOutside = (event) => {
    //         if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
    //             console.log("outside", wrapperRef.current.contains(event.target))
    //             console.log("dfdsf", event.target)


    //             setDetailOpen(false);
    //         }
    //     };

    //     document.addEventListener('mousedown', handleClickOutside);

    //     return () => {
    //         document.removeEventListener('mousedown', handleClickOutside);
    //     };

    // }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                // Check if the click target is inside the dropdown
                // console.log("outside", wrapperRef.current.contains(event.target))
                const dropdownElements = document.querySelectorAll('.user-profile-dropdwn *');
                let isInsideDropdown = false;
                dropdownElements.forEach(element => {
                    if (element.contains(event.target)) {
                        isInsideDropdown = true;
                    }
                });
                if (!isInsideDropdown) {
                    setDetailOpen(false);
                }
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


    //   useEffect(() => {
    //     const handleClickOutsidepro = (event) => {
    //       if (proRef.current && !proRef.current.contains(event.target)) {
    //         console.log("outside",proRef.current.contains(event.target))

    //         setProfileOpen(false);
    //       }
    //     };

    //      document.addEventListener('mousedown', handleClickOutsidepro);

    //     return () => {
    //       document.removeEventListener('mousedown', handleClickOutsidepro);
    //     };

    //   }, []);

    //   useEffect(() => {


    //     const handleClickOutsideMobile = (event) => {
    //       if (wrapperRefMobile.current && !wrapperRefMobile.current.contains(event.target)) {
    //         setProfileOpenMobile(false);
    //       }
    //     };

    //     document.addEventListener('mousedown', handleClickOutsideMobile);

    //     return () => {
    //       document.removeEventListener('mousedown', handleClickOutsideMobile);
    //     };
    //   }, []);



    const handleOpen = () => {
        setcpassworderr("")
        setpassworderr("")
        setPhoneerr("")
        setemailerr("")
        // setMail("")
        setOpen(true);
        setOpens(false);
        setOpenss(false)
        setMobileOpen((prevState) => !prevState);
    }
    const handleClose = () => {
        setLemailerr('')
        setLPswerr('')
        setOpen(false);
    }


    const handleOpens = () => {
        setOpens(true);
        setOpen(false)
    }
    const handleCloses = () => {
        setcpassworderr("")
        setpassworderr("")
        setPhoneerr("")
        setemailerr("")
        setOpens(false)
    };

    const handleOpenss = () => {
        setOpenss(true);
        setOpen(false)
    }
    const handleClosess = () => setOpenss(false);


    // const handleOpensss = () => {
    //     setOpensss(true);
    //     setOpenss(false)
    // }
    // const handleClosesss = () => setOpensss(false);

    // const handleOpenssss = () => {
    //     setOpenssss(true);
    //     setOpensss(false)
    // }
    // const handleClosessss = () => setOpenssss(false);
    // const handleopenpayment = () => {
    //     setmodalpay(true)
    //     setName(true)
    //     console.log("helloe", modalpay)

    // }

    // const [expanded, setExpanded] = React.useState(false);

    // const handleChange = (panel) => (event, isExpanded) => {
    //     setExpanded(isExpanded ? panel : false);
    // };
    const [showPassword, setShowPassword] = React.useState(false);
    // const [showPasswordCnf, setShowPasswordCnf] = React.useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    // const handleClickShowPasswordCnf = () => setShowPasswordCnf((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleProfileOpen = () => {
        setProfileOpen(!profileOpen)
        setDetailOpen(false)
    }

    const handleProfileDetail = () => {
        setDetailOpen(!detailOpen)
        setProfileOpen(false)

    }
    // const [value, setValue] = React.useState(0);

    // const profiletab = (event, newValue) => {
    //     setValue(newValue);
    // };
    // function verifynum() {
    //   setPhoneerr(null)
    //   let re = /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/;
    //   if (re.test(phone.current.value)) {
    //     setPhoneerr("")
    //   } else if (phone.current.value === "") {
    //     setPhoneerr("")
    //   } else {
    //     setPhoneerr("Invalid-Mobilenumber")
    //   }
    // }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault(); // Prevent default form submission behavior
        }
    }

    const lemailhandleChange = () => {

        const emailRegex = /^[A-Za-z0-9_!#$%&'*+\/=?`{|}~^.-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

        const inputValue = lemail.current.value;

        if (inputValue.trim() === "") {
            setLemailerr("")
        } else {
            setLemailerr("")
        }
    }

    const forgotEmailhandleChange = () => {

        const emailRegex = /^[A-Za-z0-9_!#$%&'*+\/=?`{|}~^.-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

        const inputValue = fmail.current.value;

        if (inputValue.trim() === "") {
            setmailerr("")
        } else if (!(emailRegex.test(inputValue))) {
            setmailerr("*Invalid Email")
        } else {
            setmailerr("")
        }
    }
    // const lpasswordchange = () => {
    //     const passwordregex = new RegExp(
    //         /(^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,16}$)+/,
    //         "gm"
    //     );
    //     const inputValue = lpswd.current.value;

    //     if (inputValue.trim() === "") {
    //         setLPswerr("")
    //     }
    //     else if (!(passwordregex.test(inputValue))) {
    //         setLPswerr("*Password must be a minimum 8 characters & Maximum 16 characters.Eg: Abc@1234")
    //     }
    //     else {
    //         setLPswerr("")
    //     }
    // }

    const verifynum = () => {
        let reg = /^\+?[0-9-]{1,17}$/;
        const inputValue = phone.current.value;

        if (inputValue.trim() === "") {
            setPhoneerr("");
        }
        // else if (!(reg.test(inputValue))) {
        //     setPhoneerr("*Only numbers, letters,plus signs, and hyphens are not allowed");
        // } 
        else if (inputValue.length > 17) {
            setPhoneerr("*Phone Number must be 17 digits or less");
        }
        else if (inputValue.length < 7) {
            setPhoneerr("*Phone Number must be 7 digits or more");
        }
        else {
            setPhoneerr("");
        }
    }
    // function verifyamount() {
    //   setamounterr(null)
    //   let re = /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/;
    //   console.log(re.test(paymentref.current.value))
    //   if (!re.test(paymentref.current.value)) {
    //     setamounterr("")
    //   } else if (paymentref.current.value === "") {
    //     setamounterr("")
    //   } else {
    //     setamounterr("Amount must be number")
    //   }
    // }

    function getPassword() {
        const passwordregex = new RegExp(
            /(^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,16}$)+/,
            "gm"
        );
        const inputValue = inpassref.current.value;

        if (inputValue.trim() === "") {
            setpassworderr("")
        } else if (!(passwordregex.test(inputValue))) {
            setpassworderr("*Password must be a minimum 8 characters & Maximum 16 characters.Eg: Abc@1234")
        } else {
            setpassworderr("")
        }
    }

    function confirmPassword() {
        if (inputconfrimRef.current.value !== inpassref.current.value) {
            setcpassworderr('Password do not match');
        }
        else if (inputconfrimRef === inpassref) {
            setcpassworderr('');
        } else {
            setcpassworderr('');
        }
    }

    function verifyemail() {
        setemailerr(null)
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (re.test(email.current.value)) {
            setemailerr("")
        } else if (email.current.value === "") {
            setemailerr("")
        } else {
            setemailerr("Invalid Email Format")
        }
    }


    const [password, setPassword] = React.useState(false);

    const handleClickPassword = () => setPassword((show) => !show);



    const logout = () => {
        // console.log("remove")
        window.localStorage.removeItem("iphephile")
        window.localStorage.removeItem("iphephileid")

        toast.success('Logged Out Successfully', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
        handleProfileDetail()


        setTimeout(() => {
            // window.location.reload();
            navigate(`${consts.route}/`)
        }, 1500);

        // console.log(`${consts.route}/`, "dsds")

    }

    const classess = useStyles();
    // const [isActive, setIsActive] = useState(false);

    // const handleInputClick = () => {
    //     setIsActive(true);
    // };

    // const handleCloseClick = () => {
    //     setIsActive(false);
    // };


    // current time

    const [date, setDate] = useState(new Date());

    useEffect(() => {
        const interval = setInterval(() => {
            setDate(new Date());
        }, 1000); // Update every second

        return () => clearInterval(interval);
    }, []);

    const formattedDate = date.toLocaleString('en-US', {
        month: 'numeric',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
    });


    // hide details

    const [isHidden, setIsHidden] = useState(false);

    const toggleDetails = () => {
        setIsHidden(!isHidden);
    };

    var element = document.getElementById("box");

    const register = async () => {
        try {
            // console.log("name", name.current.value)
            let reg = /^[0-9]+$/;
            let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

            const passwordregex = new RegExp(
                /(^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,16}$)+/,
                "gm"
            );

            if (!email.current.value) {
                setemailerr("*Email is Required")
                element.scrollIntoView({ behavior: "smooth", block: "start" });

            }
            else if (!(re.test(email.current.value))) {
                setemailerr("*Invalid Email")
                element.scrollIntoView({ behavior: "smooth", block: "start" });

            }
            else if (!name.current.value) {
                setusernameerr("*Name is Required")
                element.scrollIntoView({ behavior: "smooth", block: "start" });

            }
            else if (!phone.current.value) {
                setPhoneerr("*Phone No is Required")
                element.scrollIntoView({ behavior: "smooth", block: "start" });

            }
            else if (phone.current.value.length < 7) {
                setPhoneerr("*Phone Number should be 7 digits or more")
            }
            else if (phone.current.value.length > 17) {
                setPhoneerr("*Phone Number should be 17 digits or less")
            }
            else if (!(reg.test(phone.current.value))) {
                setPhoneerr("*Invalid Phone Number")
            }
            else if (!address.current.value) {
                setaddresserr("*Address is Required")
            }
            else if (!city.current.value) {
                setcityerr("*City is Required")
            }
            else if (!country.current.value) {
                setcountryerr("*Country is Required")
            }
            else if (!inpassref.current.value) {
                setpassworderr("*Password is Required")
            }
            else if (!(passwordregex.test(inpassref.current.value))) {
                setpassworderr("*Password must be a minimum 8 characters & Maximum 16 characters.Eg: Abc@1234")
            }
            else if (!inputconfrimRef.current.value) {
                setcpassworderr("*Confirm Password is Required")
            }
            else if (inpassref.current.value !== inputconfrimRef.current.value) {
                setcpassworderr("*Password and Confirm Password must be Equal")
            }
            else {
                // setIslogin(true)
                const registerdata = {
                    user_name: name.current.value,
                    email: email.current.value,
                    phone: phone.current.value,
                    address: address.current.value,
                    city: city.current.value,
                    country: country.current.value,
                    password: inpassref.current.value,
                    confirmpassword: inputconfrimRef.current.value
                }
                const { data } = await Axios.post('/auth/register', registerdata)
                // console.log(data, "import")

                if (data?.result) {
                    setOpens(false)
                    handleCloses()
                    setTimeout(() => {
                        // navigate('/lobby')
                        // setIslogin(false)

                        toast.success('Your Account has been Registered Successfully, Please Verify Your Email')
                    }, 500);

                } if (data?.success === false) {
                    setTimeout(() => {
                        navigate('/')
                        toast.error(data?.message)
                        // setIslogin(false)

                    }, 500);
                }
            }
        } catch (error) {
            // setIslogin(false)

            // console.log(error, "err")
            if (error?.response?.data?.message === "Email Already Exists") {
                setemailerr(error?.response?.data?.message)
                if (error?.response?.data?.message === "name invalid format") {
                    setusernameerr(error?.response?.data?.message)
                }
            } else {
                setpassworderr(error?.response?.data?.message)
            }
        }

    }

    const MessageOnclick = async (id, message) => {
        // console.log(id, message, 'data');
        try {
            const response = await Axios.post('/users/seenMessage', { id: id }, {
                headers: { "Authorization": token }
            })

            if (message == 'Deposit request declined' || message == 'Deposit request Approved') {
                // console.log('kjkjk');
                getNotification()
                navigate('/deposit')
            }
            else if (message == 'Withdraw request declined' || message == 'Withdraw request Approved') {
                // console.log('kjkjk');
                getNotification()
                navigate('/withdraw')
            }
        } catch (error) {
            console.log(error, 'err');
        }

    }


    const loginTo = async () => {

        try {
            if (lemail.current.value === "") {
                setLemailerr("Please Enter Email")
            } else if (lpswd.current.value === "") {
                setLPswerr("Please Enter Password")
            } else {
                setLoader(true)
                const { data } = await Axios.post(`/users/login`, {
                    email: lemail.current.value,
                    password: lpswd.current.value
                })
                // console.log(data, "datas")
                if (data.success === false) {
                    // window.localStorage.setItem('Brouhaha', data.result.token)
                    //window.localStorage.setItem('Brou', data.result.user?._id)
                    setLoader(false)
                    toast.error(data.message, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                } else if (data.success === true && data.result.user.f2A_Status === "false") {
                    setLoader(false)
                    window.localStorage.setItem('iphephile', data.result.token)
                    window.localStorage.setItem('iphephileid', data.result.user?._id)
                    toast.success(data.message, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    // handleProfileDetail()
                    setOpen(false);

                    setTimeout(() => {
                        navigate(`${consts.route}/`)
                    }, 2000)
                } else if (data.success === true && data.result.user.f2A_Status === "true") {
                    // window.localStorage.setItem('iphephile', data.result.token)
                    // window.localStorage.setItem('iphephileid', data.result.user?._id)
                    setLoader(false)
                    toast.success(data.message, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });

                    setTimeout(() => {
                        navigate(`${consts.route}/two-factor-auth`, { state: { status: data?.result } })
                    }, 2000)
                }
                // else  {
                //     window.localStorage.setItem('iphephile', data.result.token)
                //     window.localStorage.setItem('iphephileid', data.result.user?._id)
                //     toast.success(data.message, {
                //         position: "top-center",
                //         autoClose: 3000,
                //         hideProgressBar: false,
                //         closeOnClick: true,
                //         pauseOnHover: false,
                //         draggable: true,
                //         progress: undefined,
                //         theme: "light",
                //     });

                //     setTimeout(() => {
                //         navigate(`${consts.route}/two-factor-auth`)
                //         handleClose()
                //     }, 2000)
                // }
            }
        } catch ({ response }) {
            setLoader(false)

            if (response?.data?.message === 'Please Verify Email') {
                // console.log("if1")
                setLoader(false)
                setLemailerr(response?.data?.message)
            } else if (response?.data?.message === "Invalid Email") {
                // console.log("if2")
                setLoader(false)
                setLemailerr(response?.data?.message)
            } else if (response?.data?.message === "BLOCKED_USER") {
                setLoader(false)
                // console.log("if2")
                setLemailerr("Admin Blocked You")
            } else if (response?.data?.message === "Admin Blocked You") {
                setLoader(false)
                // console.log("if2")
                setLemailerr("Admin Blocked You")
            } else if (response?.data?.message === 'Wrong Password') {
                setLoader(false)
                // console.log("if3")
                setLPswerr(response?.data?.message)
            } else if (response?.data?.message === "Please Enter Valid Email") {
                setLoader(false)
                setLemailerr(response?.data?.message)
            }
        }

    }


    const checks2fa = async () => {
        try {
            const data = await Axios.get(`/auth/check2fa`, {
                headers: {
                    "Authorization": token
                }
            })
            // console.log(data, "datsss")
            if (data) {
                setAmount(parseFloat(data?.data?.result?.amount).toFixed(3))
                setusers(data?.data?.result)
                setProfilePic(data?.data?.result?.profilePicture)
            } else {
                // console.log('error11')
                // setAmount(parseFloat(data?.data?.result?.amount).toFixed(3))
                // setusers(data?.data?.result)
                // setProfilePic(data?.data?.result?.profilePicture)
            }

        } catch (error) {
            console.log("error", error)

        }
    }


    useEffect(() => {
        if (token != null) {
            checks2fa()
            getNotification()
        }
    }, [token])

    const forgot = async () => {

        try {
            if (fmail.current.value === "") {
                setmailerr("Please Enter Email")
            } else {
                setLoader(true)
                await Axios.post(`/auth/forgot`, {
                    email: fmail.current.value,
                }).then((response) => {

                    if (response?.data?.success == false) {
                        setLoader(false)
                        toast.error(response?.data?.message, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        })
                        window.localStorage.removeItem("iphephile")
                        window.localStorage.removeItem("iphephileid")
                        setTimeout(() => {
                            navigate(`${consts.route}/`)
                        }, 2000);
                    }
                    else {
                        navigate('/emailotp', { state: { email: fmail.current.value } })
                    }

                })
                    .catch((error) => {
                        // console.log(error.response.data, "messa")
                        if (error.response.data.message === "EMAIL_IS_NOT_VALID") {
                            setLoader(false)
                            toast.error('Invalid Email', {
                                position: "top-right",
                                autoClose: 3000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: false,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            });
                        } else if (error.response.data.message === "Invalid Email") {
                            setLoader(false)
                            toast.error('Invalid Email', {
                                position: "top-right",
                                autoClose: 3000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: false,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            });
                        }

                    });
                // if (data.success === true && data.result.user.f2A_Status === "false") {
                //   window.localStorage.setItem('Brouhaha', data.result.token)
                //   toast.success(data.message, {
                //     position: "top-right",
                //     autoClose: 3000,
                //     hideProgressBar: false,
                //     closeOnClick: true,
                //     pauseOnHover: false,
                //     draggable: true,
                //     progress: undefined,
                //     theme: "light",
                //   });

                //   setTimeout(() => {
                //     navigate(`${consts.route}/`)
                //   }, 2000)
                // } else {
                //   window.localStorage.setItem('Brouhaha', data.result.token)
                //   toast.success(data.message, {
                //     position: "top-right",
                //     autoClose: 3000,
                //     hideProgressBar: false,
                //     closeOnClick: true,
                //     pauseOnHover: false,
                //     draggable: true,
                //     progress: undefined,
                //     theme: "light",
                //   });

                //   setTimeout(() => {
                //     navigate(`${consts.route}/two-factor-auth`)
                //   }, 2000)
                // }
            }
        } catch ({ response }) {
            // console.log(response, "err")
            if (response?.data?.message === 'Please Verify Email') {
                setLoader(false)
                // console.log("if1")
                setmailerr(response?.data?.message)
            } else if (response?.data?.message === "Invalid Email") {
                setLoader(false)
                // console.log("if2")
                setmailerr(response?.data?.message)
            } else if (response?.data?.message === "Please Enter Valid Email") {
                setLoader(false)
                setmailerr(response?.data?.message)
            }
        }

    }

    const getNotification = async () => {
        try {
            const response = await Axios.post('/users/getWithdrawDecline', {}, {
                headers: { "Authorization": token }
            })
            // console.log(response.data.result, 'res');
            if (response.data.success == true) {
                setNotificationCount(response.data.result.length)
                setNotificationMessage(response.data.result)
            }
            else {
                setNotificationCount(0)
                setNotificationMessage([])
            }
        } catch (error) {
            console.log(error, 'err');
        }
    }



    // console.log(notificationMessage, 'notificationMessage');
    // const getUserByid = async () => {
    //     try {
    //         const data = await Axios.get('/users/getUserbyId', {
    //             headers: {
    //                 "Authorization": token
    //             }
    //         })
    //         if (data) {
    //             console.log(data, "detasss")
    //             setusers(data?.data?.result)
    //             setImg(data?.data?.result?.profilePicture)
    //         }
    //     } catch (error) {
    //         console.log(error, "error")
    //     }
    // }
    // useEffect(() => {
    //     getUserByid()
    // }, [])


    const [member, setMember] = useState(false)

    const [backdrop, setBackdrop] = useState(false);


    const handleBackdrop = () => {
        setBackdrop(true)
        const timer = setTimeout(() => {
            setBackdrop(false)
            setMember(true)
        }, 3000)

        return () => clearTimeout(timer);

    };

    const userMemberShip = async () => {
        const payload = {
            _id: users._id
        }
        try {
            const response = await Axios.post('/users/addMemberShip', payload, {
                headers: {
                    "Authorization": window.localStorage.getItem('iphephile')
                }
            })
            if (response?.data?.success == true) {
                handleBackdrop()
                checks2fa()
            }

        } catch (error) {
            console.log(error, 'membership err');
        }
    }

    const drawer = (


        <Box sx={{ textAlign: 'center' }}>
            <div className='top-h-stack-respo'>
                <NavLink to='/' className={({ isActive }) => (isActive ? 'active' : 'top-h-nav')}  >Home</NavLink>
                <NavLink to='/lobby' className={({ isActive }) => (isActive ? 'active' : 'top-h-nav')}  >Lobby</NavLink>
                <NavLink to='/news' className={({ isActive }) => (isActive ? 'active' : 'top-h-nav')} >News</NavLink>
                <NavLink to='/research' className={({ isActive }) => (isActive ? 'active' : 'top-h-nav')} >Research</NavLink>
                <NavLink to='/survey' className={({ isActive }) => (isActive ? 'active' : 'top-h-nav')} >Survey</NavLink>
                {/* <NavLink to='/tv' className={({ isActive }) => (isActive ? 'active' : 'top-h-nav')} >TV</NavLink> */}
                {/* <NavLink to='/members' className={({ isActive }) => (isActive ? 'active' : 'top-h-nav')} > Members</NavLink> */}
                {/* <Avatar alt="Remy Sharp" src={ava} /> */}
                {/* <Button className='joinnow'>Join Now</Button> */}

                <div className='login-join-main2'>
                    <div className='login-register-button1'><Button varient='contained' onClick={handleOpen}>Log in</Button></div>
                    <div className='login-register-button2'><Button onClick={handleOpens}>Join Now</Button></div>
                </div>
                {/* {modalpay &&
                    <Modal open={modalpay}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description">
                        <Box sx={style} className={classes.popupboxcls} >
                            <Grid container spacing={0}>

                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Item className={classes.dashboarbodycls}>
                                        <div className='popup ' >
                                            <CloseIcon onClick={() => setmodalpay(false)} />
                                        </div>
                                        <h6 className='heading-txt'>Payament</h6>

                                        <Grid container spacing={0} className='inner-popup'>
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <Item className={classes.dashboarbodycls}>
                                                    <div className='user-icon binance'><img src={binancecoin} alt='user'/></div>
                                                    <div className='formtxt'>
                                                        <TextField
                                                            id="outlined-multiline-flexible"
                                                            name='amount'
                                                            label="Amount"
                                                            type="number"

                                                            inputRef={paymentref}
                                                            multiline="multiline" className='text-field' />
                                                        {amounterr !== null ? <h3 className='h3'>{amounterr}</h3> : <></>}
                                                    </div>
                                                    <div className='text-binance formtxt'>
                                                        <Button className='binance-btn' >Payment</Button>
                                                    </div>
                                                </Item>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={2} xl={2}>
                                                <Item className={classes.dashboarbodycls}>
                                                </Item>
                                            </Grid>
                                        </Grid>
                                    </Item>
                                </Grid>
                            </Grid>
                        </Box>
                    </Modal>
                } */}

                {open &&
                    <Modal open={open}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description">
                        <Box sx={style} className={classes.loginpopupboxcls} >
                            <Grid container spacing={0}>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Item className={classes.dashboarbodycls}>
                                        <div className='popup ' >
                                            <CloseIcon onClick={handleClose} />
                                        </div>
                                        <h6 className='heading-txt'>Login to SSA Account</h6>
                                        <Grid container spacing={0} className='inner-popup'>
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <Item className={classes.dashboarbodycls}>
                                                    {/* <div className='user-icon binance'><img src={binancecoin} alt='user'/></div> */}
                                                    <div className='formtxt'>
                                                        <TextField
                                                            id="outlined-multiline-flexible"
                                                            // label="Email"
                                                            placeholder='Email'
                                                            inputRef={lemail}
                                                            onChange={() => lemailhandleChange()}
                                                            onKeyDown={(e) => handleKeyDown(e)}
                                                            multiline="multiline" className='text-field' />
                                                        {lemailerr !== null ? <h3 className='h3'>{lemailerr}</h3> : <></>}
                                                    </div>
                                                    <FormControl sx={{ m: 1, width: '100%' }} variant="outlined" className={classes.passwordeye}>

                                                        <OutlinedInput
                                                            id="outlined-adornment-password"
                                                            type={showPassword ? 'text' : 'password'}
                                                            // onChange={() => lpasswordchange()}
                                                            onChange={() => setLPswerr('')}
                                                            inputRef={lpswd}
                                                            endAdornment={
                                                                <InputAdornment position="end" className='eyeicon'>
                                                                    <IconButton
                                                                        aria-label="toggle password visibility"
                                                                        onClick={handleClickShowPassword}
                                                                        onMouseDown={handleMouseDownPassword}
                                                                        edge="end"
                                                                    >
                                                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            }
                                                            // label="Password"
                                                            placeholder='Password'

                                                        />
                                                    </FormControl>
                                                    {lpswerr !== null ? <h3 className='h3'>{lpswerr}</h3> : <></>}
                                                    <div className='text-binance formtxt'>
                                                        {
                                                            loader == true ?
                                                                <Button className='binance-btn'>
                                                                    {/* <CircularProgress animation="border" role="status" sx={{ color: '#fff' }} /> */}
                                                                    <ClipLoader color={'#00008B'} />
                                                                </Button> :
                                                                <Button className='binance-btn' onClick={loginTo}>Log in</Button>
                                                        }

                                                    </div>
                                                    <Spinner animation="border" role="status" />
                                                    <div className='forget'>
                                                        <Button onClick={handleOpenss}>Forgot Your password?</Button>
                                                    </div>
                                                    <div className='forget'>
                                                        <span>Don't have a account? </span><Button onClick={handleOpens}>Sign up now</Button>
                                                    </div>
                                                </Item>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={2} xl={2}>
                                                <Item className={classes.dashboarbodycls}>
                                                </Item>
                                            </Grid>
                                        </Grid>
                                    </Item>
                                </Grid>
                            </Grid>
                        </Box>
                    </Modal>
                }

                {opens &&
                    <Modal open={opens}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description" >
                        <Box sx={style} className={classes.registerpopupboxcls}>
                            <div className='popup ' >
                                <CloseIcon onClick={handleCloses} />
                            </div>

                            <Grid container spacing={0} >


                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                    <Item className={classes.dashboarbodycls} id="dashboarbodycls-id-block">
                                        <h6 className='heading-txt'>Create a SSA Account</h6>
                                        <Grid container spacing={0} className='inner-popup' id="box">
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <Item className={classes.dashboarbodycls}>

                                                    <div className='formtxt'>
                                                        <TextField
                                                            id="outlined-multiline-flexible"
                                                            // label="Email"
                                                            placeholder='Email'
                                                            multiline="multiline" className='text-field'
                                                            onKeyDown={(e) => handleKeyDown(e)}
                                                            inputRef={email}
                                                            onChange={verifyemail}
                                                        />
                                                        {/* <h3 className='h3' >{mail}</h3> */}
                                                        {emailerr !== null ? <h3 className='h3'>{emailerr}</h3> : <></>}
                                                    </div>

                                                    <div className='formtxt'>
                                                        <TextField
                                                            id="outlined-multiline-flexible"
                                                            // label="Name"
                                                            placeholder='Name'
                                                            multiline="multiline" className='text-field'
                                                            onKeyDown={(e) => handleKeyDown(e)}
                                                            inputRef={name}
                                                            onChange={() => { setusernameerr(null) }}
                                                        />
                                                        {usernameerr ? <h3 className='h3'>{usernameerr}</h3> : <></>}
                                                    </div>

                                                    <div className='formtxt'>
                                                        <TextField
                                                            id="outlined-multiline-flexible"
                                                            // label="Phone Number"
                                                            onKeyPress={(event) => {
                                                                if (!/[0-9]/.test(event.key)) {
                                                                    event.preventDefault();
                                                                }
                                                            }}
                                                            placeholder='Phone Number'
                                                            multiline="multiline" className='text-field'
                                                            onKeyDown={(e) => handleKeyDown(e)}
                                                            inputRef={phone}
                                                            onChange={verifynum}
                                                        />
                                                        {phoneerr ? <h3 className='h3'>{phoneerr}</h3> : <></>}
                                                    </div>


                                                    <div className='formtxt'>
                                                        <TextField
                                                            id="outlined-multiline-flexible"
                                                            // label="Phone Number"
                                                            placeholder='Address'
                                                            multiline="multiline" className='text-field'
                                                            onKeyDown={(e) => handleKeyDown(e)}
                                                            inputRef={address}
                                                            onChange={() => { setaddresserr(null) }}
                                                        />
                                                        {addresserr ? <h3 className='h3'>{addresserr}</h3> : <></>}
                                                    </div>

                                                    <div className='formtxt'>
                                                        <TextField
                                                            id="outlined-multiline-flexible"
                                                            // label="Phone Number"
                                                            placeholder='City'
                                                            multiline="multiline" className='text-field'
                                                            onKeyDown={(e) => handleKeyDown(e)}
                                                            inputRef={city}
                                                            onChange={() => { setcityerr(null) }}
                                                        />
                                                        {cityerr ? <h3 className='h3'>{cityerr}</h3> : <></>}
                                                    </div>

                                                    <div className='formtxt'>
                                                        <TextField
                                                            id="outlined-multiline-flexible"
                                                            // label="Phone Number"
                                                            placeholder='Country'
                                                            multiline="multiline" className='text-field'
                                                            onKeyDown={(e) => handleKeyDown(e)}
                                                            inputRef={country}
                                                            onChange={() => { setcountryerr(null) }}
                                                        />
                                                        {countryerr ? <h3 className='h3'>{countryerr}</h3> : <></>}
                                                    </div>

                                                    <FormControl sx={{ m: 1, width: '100%' }} variant="outlined" className={classes.passwordeye}>
                                                        <OutlinedInput
                                                            id="outlined-adornment-password"
                                                            type={showPassword ? 'text' : 'password'}
                                                            inputRef={inpassref}
                                                            onChange={getPassword}
                                                            placeholder='Password'
                                                            endAdornment={
                                                                <InputAdornment position="end" className='eyeicon'>
                                                                    <IconButton
                                                                        aria-label="toggle password visibility"
                                                                        onClick={handleClickShowPassword}
                                                                        onMouseDown={handleMouseDownPassword}
                                                                        edge="end"
                                                                    >
                                                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            }
                                                        />
                                                    </FormControl>
                                                    {passworderr !== null ? <h3 className='h3'>{passworderr}</h3> : <></>}

                                                    <FormControl sx={{ m: 1, width: '100%' }} variant="outlined" className={classes.passwordeye}>
                                                        <OutlinedInput
                                                            id="outlined-adornment-password"
                                                            type={password ? 'text' : 'password'}
                                                            inputRef={inputconfrimRef}
                                                            onChange={confirmPassword}
                                                            placeholder='Confirm Password'
                                                            endAdornment={
                                                                <InputAdornment position="end" className='eyeicon'>
                                                                    <IconButton
                                                                        aria-label="toggle password visibility"
                                                                        onClick={handleClickPassword}
                                                                        onMouseDown={handleMouseDownPassword}
                                                                        edge="end"
                                                                    >
                                                                        {password ? <Visibility /> : <VisibilityOff />}
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            }
                                                        />
                                                    </FormControl>
                                                    {confirmPass ? <h3 className='h3'> {confirmPass} </h3> : <></>}
                                                    {cpassworderr !== null ? <h3 className='h3'>{cpassworderr}</h3> : <></>}

                                                    <div className='text-binance formtxt'>
                                                        <Button className='binance-btn' onClick={() => { register() }}  >Create account</Button>
                                                    </div>
                                                    {/* 
                                                    <p>By creating an account you are agreeing to SSA  betting Terms of Use
                                                        and Privacy Policy and to be updated about SSA  betting products,
                                                        news, and promotions. Users must be 18+ (21+ in MA) to play Fantasy
                                                        and 21+ to place bets on Sportsbook.</p> */}
                                                    <div className='forget'>
                                                        <span>Already have a SSA  betting account?</span> <Button onClick={handleOpen}>Sign in</Button>
                                                    </div>
                                                </Item>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={2} xl={2}>
                                                <Item className={classes.dashboarbodycls}>
                                                </Item>
                                            </Grid>
                                        </Grid>
                                    </Item>
                                </Grid>
                            </Grid>
                        </Box>
                    </Modal>
                }


                {openss &&
                    <Modal open={openss}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description" >
                        <Box sx={style} className={classes.popupboxcls}>
                            <Grid container spacing={0}>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Item className={classes.dashboarbodycls}>
                                        <div className='popup' >
                                            <CloseIcon onClick={handleClosess} />
                                        </div>
                                        <h6 className='heading-txt'>Forgot Your password?</h6>
                                        <Grid container spacing={0} className='inner-popup'>
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <Item className={classes.dashboarbodycls}>
                                                    {/* <div className='user-icon binance'><img src={binancecoin} alt='user'/></div> */}
                                                    <div className='formtxt'>
                                                        <TextField
                                                            id="outlined-multiline-flexible"
                                                            // label="Email"
                                                            placeholder='Email'
                                                            onKeyDown={(e) => handleKeyDown(e)}
                                                            multiline="multiline" className='text-field'
                                                            inputRef={fmail}
                                                            // onChange={() => { setmailerr(null) }}
                                                            onChange={() => { forgotEmailhandleChange() }} />
                                                    </div>
                                                    {mailerr !== null ? <div style={{ textAlign: "center", color: "red" }} >{mailerr}</div> : <></>}

                                                    <div className='text-binance formtxt'>
                                                        {
                                                            loader ?
                                                                <Button className='binance-btn' >
                                                                    {/* <CircularProgress animation="border" role="status" sx={{ color: '#fff' }} /> */}
                                                                    <ClipLoader color={'#00008B'} />
                                                                </Button> :
                                                                <Button className='binance-btn' onClick={forgot}>Send Verification Code</Button>
                                                        }

                                                    </div>
                                                    <div className='forget'>
                                                        <span>I remainder my password </span><Button onClick={handleOpen}>Login now </Button>
                                                    </div>

                                                    {/* <div className='forget'>
                    <span>Don't have a FanDuel account? </span><Link to="" onClick={handleOpenss}> Sign up now</Link>
                  </div> */}

                                                </Item>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={2} xl={2}>
                                                <Item className={classes.dashboarbodycls}>
                                                </Item>
                                            </Grid>
                                        </Grid>
                                    </Item>
                                </Grid>
                            </Grid>
                        </Box>
                    </Modal>
                }


                {/* {opensss &&
                    <Modal open={opensss}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description" >
                        <Box sx={style} className={classes.popupboxcls}>
                            <Grid container spacing={0}>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Item className={classes.dashboarbodycls}>
                                        <div className='popup' >
                                            <CloseIcon onClick={handleClosesss} />
                                        </div>
                                        <h6 className='heading-txt'>Enter OTP code </h6>
                                        <p className='para'>bhar......25@gmail.com</p>
                                        <Grid container spacing={0} className='inner-popup'>
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <Item className={classes.dashboarbodycls}>
                                                    <div className='user-icon binance'><img src={binancecoin} alt='user'/></div>
                                                    <div className='formtxt'>
                                                        <TextField
                                                            id="outlined-multiline-flexible"
                                                            label="Code"
                                                            multiline="multiline" className='text-field' />
                                                    </div>
                                                    <div className='forget resend'>
                                                        <Link to="">Resend Code</Link>
                                                    </div>
                                                    <div className='text-binance formtxt'>
                                                        <Button className='binance-btn'>Verify Code</Button>
                                                    </div>
                                                    <div className='forget'>
                                                        <span>I remainder my password </span><Link to="" onClick={handleOpen}>Login now </Link>
                                                    </div>
                                                </Item>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={2} xl={2}>
                                                <Item className={classes.dashboarbodycls}>
                                                </Item>
                                            </Grid>
                                        </Grid>
                                    </Item>
                                </Grid>
                            </Grid>
                        </Box>
                    </Modal>
                }


                {openssss &&
                    <Modal open={openssss}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description" >
                        <Box sx={style} className={classes.popupboxcls}>
                            <Grid container spacing={0}>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Item className={classes.dashboarbodycls}>
                                        <div className='popup' >
                                            <CloseIcon onClick={handleClosessss} />
                                        </div>
                                        <h6 className='heading-txt'>2Factor Authentication </h6>
                                        <Grid container spacing={0} className='inner-popup'>
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <Item className={classes.dashboarbodycls}>
                                                    <div className='user-icon binance'><img src={binancecoin} alt='user'/></div>
                                                    <div className='formtxt'>
                                                        <TextField
                                                            id="outlined-multiline-flexible"
                                                            label="Security Code"
                                                            multiline="multiline" className='text-field' />
                                                    </div>

                                                    <div className='forget resend'>
                                                        <Link to="">Resend Code</Link>
                                                    </div>

                                                    <div className='text-binance formtxt'>
                                                        <Button className='binance-btn'>Enter your 6 Digit code</Button>
                                                    </div>


                                                    <div className='forget'>
                                                        <span>I remainder my password </span><Button onClick={handleOpen}>Login now </Button>
                                                    </div>

                                                    <div className='forget'>
                    <span>Don't have a FanDuel account? </span><Link to="" onClick={handleOpens}> Sign up now</Link>
                  </div>

                                                </Item>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={2} xl={2}>
                                                <Item className={classes.dashboarbodycls}>
                                                </Item>
                                            </Grid>
                                        </Grid>
                                    </Item>
                                </Grid>
                            </Grid>
                        </Box>
                    </Modal>
                } */}

            </div>
        </Box>
    );

    const DrawerList = (
        <div className='login-register-block'>
            <ul className='login-register-block-list'>
                {token ?
                    <>
                        <div>
                            <div className='user-after-login' ref={wrapperRef} >
                                {/* <img src={profile} /> */}

                                <Badge
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    }}
                                    // color="success"
                                    // badgeContent={member && <img width="18" height="18" src="https://img.icons8.com/fluency/48/verified-account--v1.png" alt="verified-account--v1" />} >
                                    badgeContent={users?.member_ship && <img width="22" height="22" src={verify2} alt="verified-account--v1" />} >

                                    <Avatar alt="Remy Sharp" src={profilepic ? profilepic : ava} _ className='cursor avatar-border' onClick={() => { handleProfileDetail(); checks2fa() }} sx={{ width: "50px", height: "50px" }} />
                                </Badge>

                            </div>
                            <div className='user-login-part' >
                                {detailOpen &&
                                    <div className='user-profile-dropdwn' >

                                        <div class='top-user-block'>
                                            <div className='avatar-pro'>
                                                <div className='profile-img'>
                                                    <Badge
                                                        anchorOrigin={{
                                                            vertical: 'bottom',
                                                            horizontal: 'right',
                                                        }}
                                                        // color="success"
                                                        // badgeContent={member && <img width="18" height="18" src="https://img.icons8.com/fluency/48/verified-account--v1.png" alt="verified-account--v1" />} >
                                                        badgeContent={users?.member_ship && <img width="22" height="22" src={verify2} alt="verified-account--v1" />} >

                                                        <Avatar alt="Remy Sharp" src={profilepic ? profilepic : ava} className='avatar-border' />
                                                    </Badge>
                                                </div>
                                                {/* {console.log(users, "profileData")} */}
                                                <div className='user-info'>
                                                    {users?.user_name ? users?.user_name : ""}
                                                </div>
                                            </div>
                                            <div>Last login:{formattedDate}</div>
                                        </div>

                                        <div className='mx-hgt'>
                                            <div className='mid-user-block'>
                                                <div className='USR-COIN'>
                                                    ${amount ? amount : '0.00'}
                                                    <div className='inr'>
                                                        PLAYABLE BALANCE
                                                    </div>
                                                </div>
                                                {/* <div className='USR-COIN'>
                                                    {eye ? '$ --' : '$ 0.00'}
                                                    <div className='inr'>
                                                        BONUS
                                                    </div>
                                                </div> */}
                                                {/* <div className='remove-icon' onClick={(() => { setEye(!eye) })}>{eye ? <RemoveRedEyeIcon /> : <VisibilityOffIcon />}</div> */}
                                            </div>


                                            {/* <div className="details-container">

                                                <div className={`details ${isHidden ? 'hidden' : ''}`}>

                                                    <div className='btm-user-btn'>
                                                        <div>
                                                            Sportsbook/Casino deposits
                                                        </div>
                                                        <div>
                                                            $ 0.00
                                                        </div>
                                                    </div>

                                                    <div className='btm-user-btn'>
                                                        <div>
                                                            Sportsbook bonuses
                                                        </div>
                                                        <div>
                                                            $ 0.00
                                                        </div>
                                                    </div>

                                                    <div className='btm-user-btn'>
                                                        <div>
                                                            Shared winnings
                                                        </div>
                                                        <div>
                                                            $ 0.00
                                                        </div>
                                                    </div>

                                                    <div className='btm-user-btn'>
                                                        <div>
                                                            Playable balance
                                                        </div>
                                                        <div>
                                                            ${amount}
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className='btm-user-btn' onClick={toggleDetails}>
                                                    {isHidden ? 'Show details' : 'Hide details'}
                                                </div>
                                            </div> */}
                                            <div className='prf-btn' >
                                                <div className='btn-1'>
                                                    <Button onClick={(() => { navigate('/deposit') })}>Add funds</Button>
                                                </div>
                                                <div className='btn-2'>
                                                    <Button onClick={(() => { navigate('/withdraw') })}>Withdraw funds</Button>
                                                </div>
                                            </div>
                                            <div className='more-inf'>
                                                <div className='more-inf-inner' ><Link to='/editprofile'> Edit Profile</Link>
                                                </div>
                                                {/* <div className='more-inf-inner'>
                                                    Account overview
                                                </div> */}
                                                <div className='more-inf-inner'><Link to='/twofac'>Two-Factor Authentication</Link></div>
                                                <div className='more-inf-inner'><Link to='/ticket'>Raise Ticket</Link></div>
                                                {/* <div className='more-inf-inner'>
                                                    Transaction history
                                                </div>
                                                <div className='more-inf-inner'>
                                                    Tax Information
                                                </div>
                                                <div className='more-inf-inner'>
                                                    Responsible gaming
                                                </div>
                                                <div className='more-inf-inner'>
                                                    Preferences
                                                </div>
                                                <div className='more-inf-inner'>
                                                    Help & Support
                                                </div> */}
                                            </div>
                                        </div>
                                        <div className='header-secound'>
                                            <ul className='gaming'>
                                                <li className='login-register-button'><Link onClick={logout}>Log Out <LogoutIcon /></Link></li>
                                                {
                                                    users?.member_ship == true ? <></> :
                                                        <li className='login-register-button'><Link onClick={() => { userMemberShip() }}>Become a member</Link></li>

                                                }
                                            </ul>
                                        </div>

                                        {/* <div className='bottom-user-block'>
                        <Box sx={{ width: '100%' }}>
                          <Box className={classes.profiletabhead}>
                            <Tabs value={value} onChange={profiletab} aria-label="basic tabs example" className='all-tab profile-tab'>
                              <Tab label="Account" className="tab-section" {...a11yProps(0)} />
                              <Tab label="2FA" className="tab-section" {...a11yProps(1)} />
                              <Tab label="My Offers" className="tab-section" {...a11yProps(2)} />
                              <Tab label="Alerts" className="tab-section" {...a11yProps(3)} />
                            </Tabs>
                          </Box>

                          <CustomTabPanel value={value} index={0}>

                            <Box sx={{ flexGrow: 1 }} className='profile-tab-content'>
                              <Grid container spacing={0} style={{ "flexWrap": "wrap" }}>

                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                  <div className="account-sec">

                                    <div className="popup-icons">
                                      <div className="icons"><img src={messageicon} alt="message-icon" /></div>
                                      <h4>Messages</h4>
                                    </div>
                                    <div className="popup-icons">
                                      <div className="icons"><img src={gamblingicon} alt="gambling-icon" /></div>
                                      <h4>Gambling</h4>
                                    </div>
                                    <div className="popup-icons">
                                      <div className="icons"><img src={accounticon} alt="MyActivity-icon" /></div>
                                      <h4>My Activity</h4>
                                    </div>
                                  </div>
                                </Grid>

                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                  <div className="account-sec">



                                    <div className="popup-icons">
                                      <div className="icons"><img src={gamblingicon} alt="History-icon" /></div>
                                      <h4>History</h4>
                                    </div>
                                    <div className="popup-icons">
                                      <div className="icons"><img src={breakicon} alt="break-icon" /></div>
                                      <h4>Take a Break</h4>
                                    </div>
                                  </div>
                                </Grid>



                              </Grid>
                            </Box>

                          </CustomTabPanel>

                          <CustomTabPanel value={value} index={1}>

                            <div className='two-fa-outer'>
                              <div class="two-fa-status">
                                <div>
                                  <h3>2FA Status</h3>
                                  <span class="disabled-block">
                                    <span class="disabled-dot"></span>Disabled</span>
                                </div>
                                <div class="enable-button">

                                  <button type="button" class="btn btn-success" fdprocessedid="1dk1v">Enable</button> :
                                  <button type="button" class="btn btn-success" fdprocessedid="1dk1v"> Disable</button>
                                </div>
                              </div>


                              {ena === true ?
                                <div className="qrSection">
                                  Security Code:
                                  <div className='Security-Codes'>
                                    <span>{secret}</span>

                                  </div>
                                  <div className='Security-Code qr-code'>
                                    <span>QR Code:</span>
                                    <div className='qr-code-code'>
                                      <QRCodeSVG value={qrCode} />
                                    </div>
                                    <div className='verif-2fa-digit'>
                                      <div className='verif-2fa-digit-inner'>
                                        <input type="text" ref={secrets} />
                                        <Button className="verifyButton">Verify</Button>
                                      </div>
                                      {emailerr !== null ? <div style={{ textAlign: "center", color: "red" }} >{emailerr}</div> : <></>}
                                    </div>
                                  </div>
                                </div> :
                                <div>
                                </div>
                              }
                            </div>
                          </CustomTabPanel>

                          <CustomTabPanel value={value} index={2}>
                            <div class="offers">
                              <h4>Open Account Offer - get up to RS. 4000 in Bet Credits</h4>
                              <p>We’ll give you 15% of your qualifying deposit in Bet Credits (up to RS. 4000
                                ) when you meet the qualifying settled bet requirement on your qualifying
                                deposit (capped at RS. 26666.67 ).</p>
                              <p>Claim By 26 Jun 17:03. Min deposit RS. 400 . Min odds, bet and payment method
                                restrictions apply. Returns exclude Bet Credits stake.<span>Time limits and T&amp;C’s apply</span>
                              </p>
                            </div>
                          </CustomTabPanel>

                          <CustomTabPanel value={value} index={3}>
                            <div className='radio-btn'>
                              <FormControlLabel
                                control={<IOSSwitch sx={{ m: 1 }} defaultChecked />}
                                label="Winning Bets" className='inner-radio'
                              />
                            </div>

                            <div className='radio-btn'>
                              <FormControlLabel
                                control={<IOSSwitch sx={{ m: 1 }} defaultChecked />}
                                label="Auto Cash Out" className='inner-radio'
                              />
                            </div>

                            <div className='radio-btn'>
                              <FormControlLabel
                                control={<IOSSwitch sx={{ m: 1 }} defaultChecked />}
                                label="Free Bets" className='inner-radio'
                              />
                            </div>
                          </CustomTabPanel>

                          <div className="popup-link">
                            <div className='header-secound last'>
                              <ul className='gaming'>
                                <li>Responsible Gaming</li>
                                <li>Help</li>
                              </ul>
                            </div>

                            <div className='header-secound'>
                              <ul className='gaming'>
                                <li className='login-register-button'><Link to="" onClick={logout}>Log Out <LogoutIcon /></Link></li>
                              </ul>
                            </div>
                          </div>

                        </Box>
                      </div> */}

                                    </div>
                                }
                            </div>
                        </div>
                        <div>
                            <li className='user-after-login' ref={proRef} onClick={() => { handleProfileOpen() }} >
                                <Badge badgeContent={notificationCount} color="primary"><img src={notification} className='cursor' /></Badge>
                            </li>
                            <div className='user-login-part'>
                                {profileOpen &&
                                    <div className='user-profile-dropdwn profile-drop-bg'>
                                        <div className='notification-pop-up'>
                                            <div className='notify-center'>
                                                <div className='notify-head'>
                                                    <p>Notification Center</p>
                                                </div>
                                                {/* <div className='after-login'>

                                                    <ul>
                                                        <li className='user-after-login'><RefreshIcon /></li>
                                                    </ul>
                                                </div> */}
                                            </div>
                                            {
                                                notificationMessage.length == 0 ?
                                                    <div className='notiication-details'>
                                                        <p>You haven’t received any new notifications</p>
                                                    </div> :
                                                    <>
                                                        {
                                                            notificationMessage.map((data) => (
                                                                <div class="notification-box" onClick={() => { MessageOnclick(data?._id, data?.message) }}>
                                                                    <li className="process">
                                                                        <span className="process-title"
                                                                        // onClick={() => { MessageOnclick(data._id, data.message) }}
                                                                        >{data?.message}</span >
                                                                        {
                                                                            data?.reason ? <span className="process-started-at">Reason-{data.reason}</span> : <></>
                                                                        }

                                                                    </li>
                                                                </div>
                                                                // <div className='notiication-details'>
                                                                //     <p>{data.message}</p>
                                                                // </div>
                                                            ))
                                                        }
                                                    </>
                                            }

                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        {/* <li className='login-register-button'><Link>Add funds</Link></li> */}
                        <li className='login-register-button funds' onClick={(() => { navigate('/deposit') })} ><Link>Add funds</Link></li>
                    </>
                    :
                    <div className='login-join-main'>
                        <li className='login-register-button'><Link onClick={() => { handleOpen() }}>Log in</Link></li>
                        <li className='login-register-button'><Link onClick={() => { handleOpens() }}>Join Now</Link></li>
                    </div>}
            </ul>


        </div >
    )

    // const container = window !== undefined ? () => window().document.body : undefined;

    return (

        <>
            {/* <Toaster
                position="top-center"
                reverseOrder={false}
            /> */}
            {/* <ToastContainer /> */}
            <div className='top-header'>
                <Box sx={{ display: 'flex' }}>
                    <CssBaseline />
                    <AppBar component="nav" className='nav-top nav-top-position-relative'>
                        <Toolbar sx={{ justifyContent: 'space-between' }}>
                            <div

                                sx={{ flexGrow: 1, display: { xs: 'none', md: 'block' } }}
                                className='topheadlogo'

                            >
                                <Link to="/"><img src={hlogo} alt='hlogo' /></Link>
                            </div>
                            <div className='navbarmenu-two'>
                                <IconButton
                                    color="inherit"
                                    aria-label="open drawer"
                                    edge="start"
                                    onClick={handleDrawerToggle}
                                    sx={{ mr: 2, display: { md: 'none', sm: 'block' } }}
                                >
                                    <MenuIcon />
                                </IconButton>

                                <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                                    {drawer}


                                </Box>

                                <Box sx={{ display: 'flex' }}>
                                    {DrawerList}
                                </Box>
                            </div>

                        </Toolbar>
                    </AppBar>
                    <nav className='nav-top-head'>
                        <Drawer
                            // container={container}
                            variant="temporary"
                            open={mobileOpen}
                            onClose={handleDrawerToggle}
                            ModalProps={{
                                keepMounted: true, // Better open performance on mobile.
                            }}
                            sx={{
                                display: { md: 'none', sm: 'block' },
                                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                            }}
                        >
                            {/* <div className='close-menu' onClose={handleDrawerToggle}><HighlightOffIcon /></div> */}
                            <div className='topheadlogo'><Link><img src={hlogo} alt='hlogo' /></Link> </div>
                            {drawer}





                        </Drawer>
                    </nav>

                </Box>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, display: 'flex', flexDirection: 'column', fontSize: '18px', fontWeight: '500' }}
                    open={backdrop}
                // onClick={handleClose}
                >
                    <img src={verify} alt='img' />
                    <div>
                        Verified!
                    </div>
                </Backdrop>
            </div >
        </>
    )
}

export default TopHeader
