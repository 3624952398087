import React, { useState } from 'react'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Header from '../Components/Header/Header'
import TopHeader from '../Components/Header/TopHeader'
import LeftSideBar from '../Components/SideBar/LeftSideBar';
import RightSideBar from '../Components/SideBar/RightSideBar';
import nosweat from '../images/nosweat.png'
import { Link, useLocation } from 'react-router-dom'







const Layout = ({ children, data }) => {

    const respo = useLocation()

    // console.log(respo, 'response');


    const gridDisplay = respo.pathname === '/survey'

    return (
        <div className='lobby-page'>

            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={0}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <TopHeader />
                        <Header />
                    </Grid>
                </Grid>
                <Grid container spacing={0}  >
                    {/*className='body-container' */}
                    <Grid item xs={12} sm={12} md={12} lg={2} xl={2} className='sidebarcontanier'>
                        <LeftSideBar />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={gridDisplay ? 10 : 7} xl={gridDisplay ? 10 : 7}>
                        {children}
                    </Grid>

                    {gridDisplay ? null :
                        <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                            <RightSideBar data={data} />
                        </Grid>}
                </Grid>
            </Box>

        </div>
    )
}

export default Layout
