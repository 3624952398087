import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TopHeader from "../Header/TopHeader";
import Footer from "../Footer/Footer";
import logo from "../../images/ssh-logo.png";
import "../Tv/Tv.css";
import { Button } from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.min.css";
import "owl.carousel/dist/assets/owl.theme.default.min.css";
import livetv1 from "../../images/live-tv-1.png";
import livetv2 from "../../images/live-tv-1.png";
import livetv3 from "../../images/live-tv-1.png";
import Stack from '@mui/material/Stack';
import play from "../../images/bg-play.png";
import Typography from '@mui/material/Typography';

const Tv = () => {

   //Owl Carousel Settings
   const options = {
    margin: 30,
    items: 3,
    responsiveClass: true,
    nav: true,
    autoplay: true,
    autoplayTimeout: 4000,
    dots: false,
    loop:false,
    smartSpeed: 2000,
    responsive: {
        0: {
            items: 1,
        },
        400: {
            items: 1,
        },
        700: {
            items: 2,
        },
        1200: {
            items: 3,
        },
    },
};

  return (
    <div className="tv-page">
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={0}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TopHeader />
          </Grid>
        </Grid>
        <div className="tv-banner">
          <Grid container spacing={0} className="body-container" >
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className="tv-banner-left-content">
                <div className="ssh-logo">
                  <img
                    src={logo}
                    alt="ssh-logo"
                    className="banner-ssh-logo"
                    width={130}
                    height={50}
                  />
                </div>
                <h2 className="banner-content">
                  Thru The Ringer - Over- and Under-Reactions, a Riverboat
                  Question, & KOC's NBA Player Power Rankings
                </h2>
                <div className="watch-now-button">
                  <Button
                    className="watch-now-btn"
                    variant="contained"
                    startIcon={<PlayArrowIcon />}
                  >
                    Watch Now
                  </Button>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
        <Grid container spacing={0} className="live-gaming-scroll body-container">
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <h2>Live TV on FanDuel TV+</h2>
         
          <OwlCarousel className="owl-theme owl-carousel nft" {...options}>
            <div className="live-tv-carousel-card item livetv1">
                <Box className="live-show-con">
                <div className="live-highlight">Live</div>
                  <Stack direction="row" justifyContent="space-between" alignItems="center" gap="29px">
                  <p>The Bettor Sports Network for live racing, sports, wagering insights, and analysis from top personalities
                    </p>
                    <div className="live-gaming">
                      <img src={play}  alt="Tv-live-show"/>
                    </div>
                  </Stack>
                </Box>
             
            </div>

            <div className="live-tv-carousel-card item livetv2">
                <Box className="live-show-con">
                <div className="live-highlight">Live</div>
                  <Stack direction="row" justifyContent="space-between" alignItems="center" gap="29px">
                  <p>The Bettor Sports Network for live racing, sports, wagering insights, and analysis from top personalities
                    </p>
                    <div className="live-gaming">
                      <img src={play}  alt="Tv-live-show"/>
                    </div>
                  </Stack>
                </Box>
             
            </div>

            <div className="live-tv-carousel-card item livetv3">
                <Box className="live-show-con">
                <div className="live-highlight">Live</div>
                  <Stack direction="row" justifyContent="space-between" alignItems="center" gap="29px">
                  <p>The Bettor Sports Network for live racing, sports, wagering insights, and analysis from top personalities
                    </p>
                    <div className="live-gaming">
                      <img src={play}  alt="Tv-live-show"/>
                    </div>
                  </Stack>
                </Box>
             
            </div>

            <div className="live-tv-carousel-card item livetv1">
                <Box className="live-show-con">
                <div className="live-highlight">Live</div>
                  <Stack direction="row" justifyContent="space-between" alignItems="center" gap="29px">
                  <p>The Bettor Sports Network for live racing, sports, wagering insights, and analysis from top personalities
                    </p>
                    <div className="live-gaming">
                      <img src={play}  alt="Tv-live-show"/>
                    </div>
                  </Stack>
                </Box>
             
            </div>

            <div className="live-tv-carousel-card item livetv2">
                <Box className="live-show-con">
                <div className="live-highlight">Live</div>
                  <Stack direction="row" justifyContent="space-between" alignItems="center" gap="29px">
                  <p>The Bettor Sports Network for live racing, sports, wagering insights, and analysis from top personalities
                    </p>
                    <div className="live-gaming">
                      <img src={play}  alt="Tv-live-show"/>
                    </div>
                  </Stack>
                </Box>
             
            </div>

            <div className="live-tv-carousel-card item livetv3">
                <Box className="live-show-con">
                <div className="live-highlight">Live</div>
                  <Stack direction="row" justifyContent="space-between" alignItems="center" gap="29px">
                  <p>The Bettor Sports Network for live racing, sports, wagering insights, and analysis from top personalities
                    </p>
                    <div className="live-gaming">
                      <img src={play}  alt="Tv-live-show"/>
                    </div>
                  </Stack>
                </Box>
             
            </div>


        </OwlCarousel>
          </Grid>
        </Grid>
     
        <Grid
          container
          spacing={0}
          className="linear-block"
          sx={{ margin: '50px 0px' }}
        >
          <Grid xs={12} sm={12} md={6} lg={7} xl={7}>
            <div className="linear-block-content">
              <p>
                The Ringer on sound system arena celebrates the biggest moments
                across the sound landscape and equips the audience with the
                tools they need to make the smartest wagers.
              </p>
            </div>
          </Grid>
          <Grid xs={12} sm={12} md={6} lg={5} xl={5}>
            <div className="ssh-logo">
              <img
                src={logo}
                alt="ssh-logo"
                className="banner-ssh-logo"
              />
            </div>
          </Grid>
        </Grid>

        <Grid container spacing={0} className="live-gaming-scroll body-container">
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <h2>Up & Adams</h2>
         
          <OwlCarousel className="owl-theme owl-carousel nft" {...options}>
            <div className="live-tv-carousel-card item livetv1">
                <Box className="live-show-con">
                <div className="live-highlight">Live</div>
                  <Stack direction="row" justifyContent="space-between" alignItems="center" gap="29px">
                  <p>The Bettor Sports Network for live racing, sports, wagering insights, and analysis from top personalities
                    </p>
                    <div className="live-gaming">
                      <img src={play}  alt="Tv-live-show"/>
                    </div>
                  </Stack>
                </Box>
             
            </div>

            <div className="live-tv-carousel-card item livetv2">
                <Box className="live-show-con">
                <div className="live-highlight">Live</div>
                  <Stack direction="row" justifyContent="space-between" alignItems="center" gap="29px">
                  <p>The Bettor Sports Network for live racing, sports, wagering insights, and analysis from top personalities
                    </p>
                    <div className="live-gaming">
                      <img src={play}  alt="Tv-live-show"/>
                    </div>
                  </Stack>
                </Box>
             
            </div>

            <div className="live-tv-carousel-card item livetv3">
                <Box className="live-show-con">
                <div className="live-highlight">Live</div>
                  <Stack direction="row" justifyContent="space-between" alignItems="center" gap="29px">
                  <p>The Bettor Sports Network for live racing, sports, wagering insights, and analysis from top personalities
                    </p>
                    <div className="live-gaming">
                      <img src={play}  alt="Tv-live-show"/>
                    </div>
                  </Stack>
                </Box>
             
            </div>

            <div className="live-tv-carousel-card item livetv1">
                <Box className="live-show-con">
                <div className="live-highlight">Live</div>
                  <Stack direction="row" justifyContent="space-between" alignItems="center" gap="29px">
                  <p>The Bettor Sports Network for live racing, sports, wagering insights, and analysis from top personalities
                    </p>
                    <div className="live-gaming">
                      <img src={play}  alt="Tv-live-show"/>
                    </div>
                  </Stack>
                </Box>
             
            </div>

            <div className="live-tv-carousel-card item livetv2">
                <Box className="live-show-con">
                <div className="live-highlight">Live</div>
                  <Stack direction="row" justifyContent="space-between" alignItems="center" gap="29px">
                  <p>The Bettor Sports Network for live racing, sports, wagering insights, and analysis from top personalities
                    </p>
                    <div className="live-gaming">
                      <img src={play}  alt="Tv-live-show"/>
                    </div>
                  </Stack>
                </Box>
             
            </div>

            <div className="live-tv-carousel-card item livetv3">
                <Box className="live-show-con">
                <div className="live-highlight">Live</div>
                  <Stack direction="row" justifyContent="space-between" alignItems="center" gap="29px">
                  <p>The Bettor Sports Network for live racing, sports, wagering insights, and analysis from top personalities
                    </p>
                    <div className="live-gaming">
                      <img src={play}  alt="Tv-live-show"/>
                    </div>
                  </Stack>
                </Box>
             
            </div>


        </OwlCarousel>
          </Grid>
        </Grid>


        <Grid container spacing={0}>
          <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
            <Footer />
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default Tv;
