import React, { useState, useEffect } from "react";
import "./Deposit.css";
import { Grid } from "@mui/material";
import TopHeader from "../Header/TopHeader";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { Button } from "@mui/material";
import btc from "../../images/bitcoin.png";
import qr from "../../images/qr.png";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import downs from "../../images/download.gif";
import Typography from "@mui/material/Typography";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";

// shalomy
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import Checkbox from '@mui/material/Checkbox';
import { useNavigate } from 'react-router';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import EastIcon from '@mui/icons-material/East';
import { Link } from 'react-router-dom'
import { styled } from "@mui/material/styles";
import ContactsIcon from '@mui/icons-material/Contacts';
import Axios from "../../Axios";
// import { Toaster, toast } from 'react-hot-toast'
import { ToastContainer, toast } from 'react-toastify';
import ClipLoader from "react-spinners/ClipLoader";

import consts from '../../Constant';


function Withdraw() {
  const [age, setAge] = React.useState(10);
  const handleChange = (event) => {
    setAge(event.target.value);
  };


  const token = localStorage.getItem("iphephile")

  const [age2, setAge2] = React.useState(10);

  const handleChange2 = (event) => {
    setAge2(event.target.value);
  };
  const [withdrwadetails, setwithdrwadetails] = React.useState({})

  const [inputValue, setInputValue] = useState("GIEHIHFD54FWEFUHYW5F4CQWGD");
  const [copy, setCopy] = useState(false);

  const copyText = () => {
    navigator.clipboard.writeText(inputValue);
    setCopy(true);
  };

  const [tab, setTab] = useState([
    {
      n1: "2022-05-26 12:48:13",
      n2: "C9f9fef59243e9 b2111aceffb",
      n3: "C9f9fef59243e9 b2111aceffb",
      n4: "C9f9fef59243e9d32",
      n5: "C9f9fef59243e9d32",
    },
    {
      n1: "2022-05-26 12:48:13",
      n2: "C9f9fef59243e9 b2111aceffb",
      n3: "C9f9fef59243e9 b2111aceffb",
      n4: "C9f9fef59243e9d32",
      n5: "C9f9fef59243e9d32",
    },
    {
      n1: "2022-05-26 12:48:13",
      n2: "C9f9fef59243e9 b2111aceffb",
      n3: "C9f9fef59243e9 b2111aceffb",
      n4: "C9f9fef59243e9d32",
      n5: "C9f9fef59243e9d32",
    },
  ]);


  const handleStatusChange = (index) => {
    const updatedTab = tab.map((row, i) => {
      if (i === index) {
        return { ...row, completed: !row.completed };
      }
      return row;
    });
    setTab(updatedTab);
  };

  const [down, setDown] = useState();

  const handleDownload = () => {
    setDown(true);
    setTimeout(() => {
      setDown(false);
    }, 7000);
  };

  const [amounterr, setAmounterr] = useState("");
  const [amount, setAmount] = useState();

  const handleAmount = (no) => {
    setAmounterr("");
    setAmount(no);
  };
  useEffect(() => {

    if (token) {
      getUserWallet()
    }


    else {
      // console.log("Run")
    }

  }, []);

  //   shalomy

  // add an account
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const navigate = useNavigate()
  const [name, setName] = useState()
  const [number, setnumber] = useState()
  const [check, setcheck] = useState(true)
  const [branch, setbranch] = useState()
  const [cnumber, setcnumber] = useState()
  const [ifsc, setifsc] = useState()
  const [bank_name, setbank_name] = useState()
  const [accoundetails, setAccountDetails] = useState([])
  const [id, setId] = useState()
  const [nameerr, setNameerr] = useState("")
  const [upi_iderr, setupi_iderr] = useState("")
  const [numbererr, setnumbererr] = useState("")
  const [cnumbererr, setcnumbererr] = useState("")
  const [ifscerr, setifscerr] = useState("")
  const [bank_nameerr, setbank_nameerr] = useState("")
  const [checkerr, setcheckerr] = useState("")
  const [brancherr, setbrancherr] = useState("")
  const [withdrawHistory, setWithdrawHistory] = useState('')
  const [isload, setLoad] = useState(false)
  const [isloader, setLoader] = useState(false)
  const [bankdetailserr, setbankdetailserr] = useState("")



  const handleClose = () => {
    setOpen(false)
    setifscerr("")
    setnumbererr("")
    setcnumbererr("")
    setbranch("")
    setbrancherr("")
    setNameerr("")
    setbank_nameerr("")
    setifsc()
    setnumber()
    setcnumber()
    setName()
    setbank_name()
  };

  const handleErrorChange = () => {
    setifscerr("")
    setnumbererr("")
    setcnumbererr("")
    setNameerr("")
    setbrancherr("")
    setbank_nameerr("")
  }

  const handleErrorNumberChange = (e) => {
    const is_same = String(e.target.value !== number)

    if (e.target.value !== number) {
      setcnumbererr("Account Number must be Equal")

    }
    else {

      setnumbererr("")
      setcnumbererr("")

    }
  }

  const handleErrorIfscChange = (e) => {
    const reg = /^[A-Za-z]{4}[a-zA-Z0-9]{7}$/;
    const ifsc = e.target.value
    setifsc(ifsc)
    if (ifsc === '') {
      setifscerr("");
    } else if (!(reg.test(ifsc))) {
      setifscerr("*Invalid IFSC code")
    } else {
      setifscerr("");
    }
  }

  const handleChecking = () => {
    setcheckerr("")

  }

  const preventMinus = (e) => {
    if (e.key === '-' || e.key === '+') {
      e.preventDefault();
    }
  };

  const handleChangeNext = () => {
    if (amount === undefined || amount === "") {
      setLoader(false)
      setAmounterr("Enter Amount")
    }
    else if (withdrwadetails.Bank_Name === undefined) {
      setLoader(false)
      setbankdetailserr("Choose Any one Account")
    }

  };

  // add an account

  // choose account

  const [opens, setOpens] = React.useState(false);
  const handleOpens = () => setOpens(true);
  const handleCloses = () => setOpens(false);

  const StyledTable = styled(Table)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
      textAlign: "center",
      border: "1px solid white"
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      textAlign: "center",
    },
  }));

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
      textAlign: "center",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      textAlign: "center",
      color: "black !important",
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&': {
      backgroundColor: 'transparent !important',
      color: "black !important",
      textAlign: "center",
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  const getUserWallet = async () => {
    try {

      await Axios.get("/users/get_user_withdraw_wallet", {
        headers: {
          Authorization: token,
        },
      }).then((res) => {
        if (res?.data?.success) {
          setId(res?.data?.result?._id)
          setAccountDetails(res?.data?.result?.Account)
        }
        else {
          setAccountDetails([])
        }
      }).catch((err) => {
        console.log(err)
        setAccountDetails([])


      })



    } catch (error) {
      console.log(error)
    }
  }

  const addAccount = async () => {
    try {
      if (amount === undefined || amount === "") {
        setLoader(false)
        setAmounterr("Enter Amount")
      }
      else if (accoundetails.length <= 0) {
        setLoader(false)
        setbankdetailserr("No Withdraw Account is available, Please Add Withdraw Account")
      }
      else if (withdrwadetails.Bank_Name === undefined) {
        setLoader(false)
        setbankdetailserr("Choose Any one Account")
      }
      else {
        setLoader(true)
        const details = {
          name: withdrwadetails.Account_Name,
          account_number: Number(withdrwadetails.Account),
          ifsc: withdrwadetails.IFSC_Code,
          bank_name: withdrwadetails.Bank_Name,
          amount: amount,
          withdraw_type: "0"
        }

        await Axios.post("/users/create_withdraw_request", details, {
          headers: {
            Authorization: token,
          },
        }).then((res) => {
          if (res?.data?.success == false && res?.data?.message == "Admin Blocked You") {
            setLoader(false)
            toast.error(res?.data?.message)
            window.localStorage.removeItem("iphephile")
            window.localStorage.removeItem("iphephileid")
            setTimeout(() => {
              navigate(`${consts.route}/`)
            }, 2000);
          }
          else if (res?.data?.success == true) {
            setLoader(false)
            toast.success(res?.data?.message)
            //console.log(res)
            setTimeout(() => {
              navigate('/')
            }, 1500);

            // setwithdrwadetails({})

          }
          else {
            if (res?.data?.message === "insufficient Balance") {
              setLoader(false)
              setAmounterr(res?.data?.message)
              //  setValue(0)
              //  handleReset()
            }
            else {
              setLoader(false)
              toast.error(res?.data?.message)
              setwithdrwadetails({})

              // setValue(0)
              // handleReset()
            }



          }
        }).catch((err) => {
          console.log(err)
          setLoader(false)

          toast.error(err?.response?.data?.message)
          setwithdrwadetails({})

        })

      }

    } catch (error) {
      console.log(error)
      setLoader(false)

      toast.error(error?.response?.data?.message)
      setwithdrwadetails({})
    }
  }


  const addAccounts = async () => {
    try {
      if (!check) {
        setcheckerr("Agree the terms and Policy")

      }
      if (ifsc === undefined || ifsc === "") {
        setifscerr("IFSC Code is Required")
      } else if (number === undefined || number === "") {
        setnumbererr("Account Number is Required")
      } else if (cnumber === undefined || cnumber === "") {
        setcnumbererr("Confirm Account Number is Required")
      } else if (cnumber !== number) {
        setcnumbererr("Account Number must be Equal")
      }
      else if (name === undefined || name === "") {
        setNameerr("Account Name is Required")
      }
      else if (bank_name === undefined || bank_name === "") {
        setbank_nameerr("Bank Name is Required")
      } else if (branch === undefined || branch === "") {
        setbrancherr("Branch Name is Required")
      }
      else if (!check) {
        setcheckerr("Agree the terms and Policy")

      }

      else {
        // console.log(cnumber)
        const details = {
          account: Number(number.replace(/\s/g, "")),
          confirm_account: Number(number.replace(/\s/g, "")),
          ifsc: ifsc,
          account_name: name,
          bank_name: bank_name,
          branch_name: branch,


        }

        const withdraw = {
          Account: Number(number.replace(/\s/g, "")),
          Account_Name: name,
          Bank_Name: bank_name,
          Confirm_Account: Number(cnumber.replace(/\s/g, "")),
          IFSC_Code: ifsc,
          branch_name: branch,
        }
        setwithdrwadetails(withdraw)
        await Axios.post("/users/create_withdraw_wallet", details, {
          headers: {
            Authorization: token,
          },
        }).then((res) => {
          if (res?.data?.success) {
            handleClose()
            getUserWallet()
            toast.success(res?.data?.message)

          }
          else {
            toast.error(res?.data?.message)

            setwithdrwadetails({})

          }
        }).catch((err) => {
          setwithdrwadetails({})

        })


      }
    } catch (error) {
      setwithdrwadetails({})
    }
  }

  const chooseDetails = (detail) => {
    setwithdrwadetails(detail)
    setOpens(false)
  }
  const withdraw = async () => {
    setLoad(true)

    try {
      const depo = await Axios.get(`/users/WithdarwHistory`,
        {
          headers: {
            Authorization: window.localStorage.getItem('iphephile')
          }
        })
      if (depo) {
        const hist = depo?.data?.result

        setWithdrawHistory(hist)
        setLoad(false)

        // setHistory(data?.result)
      }

    } catch (error) {
      console.log(error)
      setLoad(false)
    }
  }

  useEffect(() => {
    withdraw()
  }, [token])

  const handleAmountChange = (e) => {
    const inputValue = e.target.value;
    // Check if the input is a negative number
    if (inputValue < 0) {
      // If negative, set amount to empty string and show error
      setAmount('');
      setAmounterr("Invalid Format");
    } else {
      // If not negative, update the amount and clear error
      setAmount(inputValue);
      setAmounterr('');
    }
  };

  const deleteWithdrawWallet = async (index) => {
    // console.log(index, id, 'payload');
    const payload = {
      id: id,
      index: index
    }
    try {
      const deleteWallet = await Axios.post('/users/deleteWithdrawWallet', payload,
        {
          headers: {
            Authorization: window.localStorage.getItem('iphephile')
          }
        })
      if (deleteWallet?.data?.success == true) {
        toast.success(deleteWallet?.data?.message)
        getUserWallet()
      }
      else {
        toast.error(deleteWallet?.data?.message)
      }
    } catch (error) {
      console.log('error');
    }
  }

  return (
    <>
      {/* <Toaster
        position="top-center"
        reverseOrder={false}
      /> */}
      <ToastContainer />
      <div className="Withdraw">

        <Grid container spacing={0} className="depo-cont">
          <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
            <TopHeader />
          </Grid>
          <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
            <Link to="/lobby" className="backmenu">
              <ArrowBackIcon />
            </Link>
            <div className="deposit-comp">Withdraw</div>
          </Grid>
          <Grid xs={11} sm={10} md={8} lg={5} xl={5}>
            <div className="depo-form">
              <div className="change-password-texts">
                <Typography variant="h4" component="h4">
                  Select Account
                </Typography>
                <Typography variant="p" component="p">
                  Please select account and enter the amount to make withdraw
                </Typography>
              </div>

              <div className="add-acct blue-btn">
                <Button onClick={handleOpen}>
                  {" "}
                  <GroupAddIcon /> Add an account
                </Button>
              </div>

              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box className="withdraw-popup">
                  <div className="popup">
                    <CloseIcon onClick={handleClose} />
                  </div>

                  <div className="add-new">
                    <p className="new-act-head">Add new account</p>
                  </div>
                  <div className="accnt-detail add-account">
                    <p>IFSC Code</p>

                    <FormControl
                      sx={{ m: 1, width: "100%" }}
                      variant="outlined"
                      className="input-box"
                    >
                      <OutlinedInput
                        className="input-box-txt"
                        id="outlined-adornment-weight"
                        aria-describedby="outlined-weight-helper-text"
                        name="ifsc"
                        value={ifsc}
                        onChange={(e) => {
                          // setifsc(e.target.value);
                          // setifscerr("")
                          handleErrorIfscChange(e);
                        }}
                        inputProps={{
                          "aria-label": "weight",
                        }}
                      />
                    </FormControl>
                    {ifscerr ? <div className="errorDivi">{ifscerr}</div> : <></>}

                    <p>Account Number</p>
                    <FormControl
                      sx={{ m: 1, width: "100%" }}
                      variant="outlined"
                      className="input-box"
                    >
                      <OutlinedInput
                        className="input-box-txt"
                        id="outlined-adornment-password"
                        name="number"
                        value={number}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                            setnumbererr("Enter Valid Number")
                          }
                        }}
                        onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                        onChange={(e) => {
                          setnumber(e.target.value);
                          handleErrorChange();
                        }}
                      // onKeyPress={preventMinus}
                      />
                    </FormControl>
                    {numbererr ? (
                      <div className="errorDivi">{numbererr}</div>
                    ) : (
                      <></>
                    )}

                    <p>Confirm Account Number</p>
                    <FormControl
                      sx={{ m: 1, width: "100%" }}
                      variant="outlined"
                      className="input-box"
                    >
                      <OutlinedInput
                        className="input-box-txt"
                        id="outlined-adornment-password"
                        name="cnumber"
                        value={cnumber}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                            setcnumbererr("Enter Valid Number")
                          }
                        }}
                        onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                        // type="Number"
                        onChange={(e) => {
                          setcnumber(e.target.value);
                          handleErrorNumberChange(e);
                        }}
                      />
                    </FormControl>
                    {cnumbererr ? (
                      <div className="errorDivi">{cnumbererr}</div>
                    ) : (
                      <></>
                    )}

                    <p>Account Name</p>

                    <FormControl
                      sx={{ m: 1, width: "100%" }}
                      variant="outlined"
                      className="input-box"
                    >
                      <OutlinedInput
                        className="input-box-txt"
                        id="outlined-adornment-weight"
                        aria-describedby="outlined-weight-helper-text"
                        name="name"
                        value={name}
                        onChange={(e) => {
                          setName(e.target.value);
                          handleErrorChange();
                        }}
                        onKeyPress={(event) => {
                          if (!/[a-zA-Z]/.test(event.key)) {
                            event.preventDefault();
                            setNameerr("Enter Valid Name")
                          }
                        }}
                        inputProps={{
                          "aria-label": "weight",
                        }}
                      />
                    </FormControl>
                    {nameerr ? <div className="errorDivi">{nameerr}</div> : <></>}

                    <p>Bank Name</p>

                    <FormControl
                      sx={{ m: 1, width: "100%" }}
                      variant="outlined"
                      className="input-box"
                    >
                      <OutlinedInput
                        className="input-box-txt"
                        id="outlined-adornment-weight"
                        aria-describedby="outlined-weight-helper-text"
                        name="bank_name"
                        value={bank_name}
                        onChange={(e) => {
                          setbank_name(e.target.value);
                          handleErrorChange();
                        }}
                        onKeyPress={(event) => {
                          if (!/[a-zA-Z]/.test(event.key)) {
                            event.preventDefault();
                            setbank_nameerr("Enter Valid Bank Name")
                          }
                        }}
                        inputProps={{
                          "aria-label": "weight",
                        }}
                      />
                    </FormControl>
                    {bank_nameerr ? (
                      <div className="errorDivi">{bank_nameerr}</div>
                    ) : (
                      <></>
                    )}

                    <p>Branch</p>

                    <FormControl
                      sx={{ m: 1, width: "100%" }}
                      variant="outlined"
                      className="input-box"
                    >
                      <OutlinedInput
                        className="input-box-txt"
                        id="outlined-adornment-weight"
                        aria-describedby="outlined-weight-helper-text"
                        name="branch"
                        value={branch}
                        onChange={(e) => {
                          setbranch(e.target.value);
                          handleErrorChange();
                        }}
                        onKeyPress={(event) => {
                          if (!/[a-zA-Z]/.test(event.key)) {
                            event.preventDefault();
                            setbrancherr("Enter Valid Branch Name")
                          }
                        }}
                        inputProps={{
                          "aria-label": "weight",
                        }}
                      />
                    </FormControl>
                    {brancherr ? (
                      <div className="errorDivi">{brancherr}</div>
                    ) : (
                      <></>
                    )}

                    <div className="check-agree">
                      <div className="check-box">
                        <Checkbox
                          defaultChecked={check}
                          onChange={() => {
                            setcheck(!check);
                            handleChecking();
                          }}
                          checked={check}
                        />
                      </div>
                      <p>
                        I have read and agree with the{" "}
                        <span>terms of payment</span> and{" "}
                        <span>withdrawal policy.</span>
                      </p>
                    </div>
                    {checkerr ? (
                      <div className="errorDivi">{checkerr}</div>
                    ) : (
                      <></>
                    )}
                    <div className="add-acct cnt-add">
                      <Button onClick={() => { addAccounts() }}>
                        Continue To Add
                      </Button>
                    </div>
                  </div>
                </Box>
              </Modal>

              <div className='add-actn'>
                <div className='another-account'>
                  <div className='choose-another'>
                    {
                      accoundetails.length > 0 ?
                        <Button onClick={handleOpens}><ContactsIcon />Choose Account</Button>
                        :
                        <></>
                    }

                    <Modal
                      open={opens}
                      onClose={handleCloses}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <Box className="withdraw-popup choose-acctn" >
                        <div className='popup' >
                          <CloseIcon onClick={handleCloses} />
                        </div>

                        <div className='add-new'>

                          <p className='new-act-head'>Choose Account</p>
                        </div>
                        <div className='accnt-detail add-actn-btn'>

                          <TableContainer>
                            <StyledTable sx={{ minWidth: 300 }} aria-label="customized table" className='Tables'>
                              <TableHead>
                                <TableRow>

                                  <StyledTableCell align="right">BANK</StyledTableCell>
                                  <StyledTableCell align="right">ACCOUNT</StyledTableCell>
                                  <StyledTableCell align="right">CHOOSE</StyledTableCell>
                                  <StyledTableCell align="right">DELETE</StyledTableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody className='body-cnt'>
                                {accoundetails?.map((row, index) => (
                                  <StyledTableRow key={index}>
                                    <StyledTableCell component="th" scope="row">
                                      {row?.Bank_Name}
                                    </StyledTableCell>
                                    <StyledTableCell component="th" scope="row">
                                      {row?.Account}
                                    </StyledTableCell>
                                    <StyledTableCell align="right">

                                      <Button onClick={() => chooseDetails(row)} > Choose</Button>
                                    </StyledTableCell>
                                    <StyledTableCell align="right">

                                      <Button onClick={() => { deleteWithdrawWallet(index) }}> Delete</Button>
                                    </StyledTableCell>

                                  </StyledTableRow>
                                ))}

                              </TableBody>
                            </StyledTable>
                          </TableContainer>
                          {/* </> : <><Button onClick={handleOpen} className='button-add'> <ControlPointDuplicateIcon /> Add an account</Button></> } */}


                        </div>
                      </Box>
                    </Modal>


                  </div>
                </div>

              </div>

              {withdrwadetails?.Bank_Name !== undefined ?
                <div className='successdivi'>You Choose {withdrwadetails?.Bank_Name} Bank Account</div> :
                <>{bankdetailserr ? <div className='errorDivi'>{bankdetailserr}</div> : <></>}</>}

              <div className="input-depo">
                <FormControl fullWidth>
                  <TextField
                    id="outlined-basic"
                    className="txt-fil"
                    // onChange={(e) => {
                    //   setAmount(e.target.value);
                    //   setAmounterr("");
                    // }}
                    onChange={handleAmountChange}
                    name="amount"
                    // type="Number"
                    value={amount}
                    // onKeyPress={preventMinus}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                        setAmounterr("Enter Valid Number")
                      }
                    }}
                    onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      endAdornment: <InputAdornment position="end">USD</InputAdornment>,
                    }}

                  />
                </FormControl>

              </div>

              {amounterr ? <div className="errorDivi">{amounterr}</div> : <></>}

              <div className="want-deposite">
                <Typography variant="h6" component="h6">
                  Amount
                </Typography>

                <div className="amount-payment-button-adds">
                  <Button
                    variant="outlined"
                    onClick={() => {
                      handleAmount(1000);
                    }}
                  >
                    +1000
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      handleAmount(5000);
                    }}
                  >
                    +5000
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      handleAmount(10000);
                    }}
                  >
                    +10,000
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      handleAmount(20000);
                    }}
                  >
                    +20,000
                  </Button>
                </div>
              </div>

              <div className="amount-next-button-adds">
                {
                  isloader ?
                    <Button variant="outlined" className="nextbtn"><ClipLoader color={'#00008B'} /></Button>
                    :
                    <Button variant="outlined" className="nextbtn" onClick={() => { addAccount(); }}>Submit <EastIcon /></Button>
                }

              </div>
            </div>
          </Grid>
          <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className="deposit-comp">Recent Withdraw History</div>
          </Grid>

          {isload ?
            <>
              <ClipLoader color={'#00008B'} />
            </> :
            <>
              {withdrawHistory && withdrawHistory?.length > 0 ?
                <>
                  <Grid xs={12} sm={12} md={12} lg={10} xl={9}>
                    <div className="depo-table">
                      <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell>Date & Time</TableCell>
                              <TableCell align="right">Name</TableCell>
                              <TableCell align="right">Bank Name</TableCell>
                              <TableCell align="right">Amount</TableCell>
                              <TableCell align="right">IFSC code</TableCell>
                              <TableCell align="right">Status</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {withdrawHistory?.map((row, index) => (
                              <TableRow
                                key={row.n1}
                                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                              >
                                <TableCell component="th" scope="row">
                                  {new Date(row.createdAt).toLocaleString('en-US')}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {row.name}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {row.bank_name}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {row.amount}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {row.ifsc}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {row.status == 0 ?
                                    <p style={{ color: "orange" }}>Pending</p>
                                    : row.status == 1 ?
                                      <p style={{ color: "green" }}>Approved</p> :
                                      row?.status == 2 ?
                                        <> <p style={{ color: "red" }}>Decline</p>
                                        </> :
                                        <></>
                                  }
                                </TableCell>
                                {/* <TableCell component="th" scope="row">
                       <div
                         className={
                           row.is_approve ? "Isapproved" : "pending"
                         }
                       >
                         <Button onClick={() => handleStatusChange(index)}>
                           {row.completed ? "Completed" : "InCompleted"}
                         </Button>
                       </div>
                     </TableCell> */}
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  </Grid>
                </>
                :
                <>
                  <tr>
                    <td colSpan="6" className="record-txt norecord-found">No Records Found</td>
                  </tr>
                </>}

            </>}




        </Grid>
      </div>
    </>
  );
}

export default Withdraw;
