import React, { useState } from "react";
import ue1 from "../../images/event-img-1.png";
import ue2 from "../../images/event-img-2.png";
import ue3 from "../../images/event-img-3.png";
import ue4 from "../../images/event-img-4.png";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import "../Event/Event.css";
import { Box} from "@mui/material";
import Grid from "@mui/material/Grid";
import Footer from "../Footer/Footer";
import TopHeader from "../Header/TopHeader";
import img2 from "../../images/event-bg-2.png";


const Event = () => {
  const [profile, setProfile] = useState([
    {
      image: `${ue1}`,
      content: "12/03/2024 : WarOver  Loream Ipsum dud9 doller riscz diazo",
    },
    {
      image: `${ue2}`,
      content: "12/03/2024 : WarOver  Loream Ipsum dud9 doller riscz diazo",
    },
    {
      image: `${ue3}`,
      content: "12/03/2024 : WarOver  Loream Ipsum dud9 doller riscz diazo",
    },
    {
      image: `${ue4}`,
      content: "12/03/2024 : WarOver  Loream Ipsum dud9 doller riscz diazo",
    },
    {
      image: `${ue1}`,
      content: "12/03/2024 : WarOver  Loream Ipsum dud9 doller riscz diazo",
    },
    {
      image: `${ue2}`,
      content: "12/03/2024 : WarOver  Loream Ipsum dud9 doller riscz diazo",
    },
    {
      image: `${ue3}`,
      content: "12/03/2024 : WarOver  Loream Ipsum dud9 doller riscz diazo",
    },
    {
      image: `${ue4}`,
      content: "12/03/2024 : WarOver  Loream Ipsum dud9 doller riscz diazo",
    },
    {
      image: `${ue1}`,
      content: "12/03/2024 : WarOver  Loream Ipsum dud9 doller riscz diazo",
    },
    {
      image: `${ue2}`,
      content: "12/03/2024 : WarOver  Loream Ipsum dud9 doller riscz diazo",
    },
    {
      image: `${ue3}`,
      content: "12/03/2024 : WarOver  Loream Ipsum dud9 doller riscz diazo",
    },
    {
      image: `${ue4}`,
      content: "12/03/2024 : WarOver  Loream Ipsum dud9 doller riscz diazo",
    },
    {
      image: `${ue1}`,
      content: "12/03/2024 : WarOver  Loream Ipsum dud9 doller riscz diazo",
    },
    {
      image: `${ue2}`,
      content: "12/03/2024 : WarOver  Loream Ipsum dud9 doller riscz diazo",
    },
    {
      image: `${ue3}`,
      content: "12/03/2024 : WarOver  Loream Ipsum dud9 doller riscz diazo",
    },
    {
      image: `${ue4}`,
      content: "12/03/2024 : WarOver  Loream Ipsum dud9 doller riscz diazo",
    },
  ]);
  return (
    <div>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={0}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TopHeader />
          </Grid>
        </Grid>
        <Grid
          container
          spacing={0}
          className="body-container"
          sx={{ marginTop: "50px" }}
        >
          {profile.map((obj) => {
            return (
              <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
                <Card
                  className="upcoming-event"
                >
                    <CardMedia className="upcoming-event-img">
                      <img src={obj.image} alt="upcoming-event-img" />
                    </CardMedia>
                    <CardContent className="upcoming-event-content">
                      <h4>{obj.content}</h4>
                    </CardContent>
               
                </Card>
              </Grid>
            );
          })}
        </Grid>
        <Grid container spacing={0}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Footer />
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default Event;
